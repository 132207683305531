import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from '@mui/material';
import CustomButton from '../forms/CustomButton';
import CustomLoadingButton from '../forms/CustomLoadingButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const ReusableDialog = ({
  open,
  handleClose,
  title,
  children,
  isForm = false,
  onFormSubmit,
  isValid,
  actionButtons,
  isLoading,
  subTitle,
  maxWidth,
  ...props
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            borderRadius: '10px',
          },
        },
        overflow: 'visible',
      }}
      fullWidth
      maxWidth={maxWidth}
    >
      <DialogTitle component={'div'}>
        <>
          <Typography variant="customTitle">{title}</Typography>
          {subTitle && <Typography variant="subtitle2">{subTitle}</Typography>}
        </>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {actionButtons?.backAction && (
          <CustomButton
            type="button"
            sx={{
              border: '1px solid black',
              marginRight: 'auto',
              ml: 1,
              minWidth: '100px',
            }}
            onClick={actionButtons?.backAction}
          >
            {actionButtons?.backButtonText}
          </CustomButton>
        )}
        {actionButtons?.deleteAction && (
          <CustomButton
            type="button"
            sx={{
              border: '1px solid black',
              marginRight: 'auto',
            }}
            startIcon={<DeleteOutlineOutlinedIcon />}
            onClick={actionButtons?.deleteAction}
          >
            Delete
          </CustomButton>
        )}
        {actionButtons?.cancelButtonText && (
          <CustomButton
            variant="outlined"
            onClick={handleClose}
            sx={{ minWidth: '120px' }}
          >
            {actionButtons?.cancelButtonText}
          </CustomButton>
        )}

        {isForm && (
          <CustomLoadingButton
            loading={isLoading}
            variant="contained"
            type="submit"
            disabled={!isValid}
            onClick={onFormSubmit}
            sx={{ ...props?.sx, minWidth: '120px' }}
          >
            {actionButtons?.submitButtonText}
          </CustomLoadingButton>
        )}
        {!isForm && <Button onClick={handleClose}>Close</Button>}
      </DialogActions>
    </Dialog>
  );
};

export default ReusableDialog;
