const roles = {
  ROLE0VMS: 'role0#acs',
  ROLE1VMS: 'role1#acs',
  ROLE2VMS: 'role2#acs',
  ROLE3VMS: 'role3#acs',
  ROLE4VMS: 'role4#acs',
  ROLE5VMS: 'role5#acs',
};

export default roles;
