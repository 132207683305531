export const buttons = [
  {
    value: 'Inputs',
    label: 'Inputs',
  },
  {
    value: 'Outputs',
    label: 'Outputs',
  },
  {
    value: 'Readers',
    label: 'Readers',
  },
];
export const contactTypes = [
  {
    value: 'Normally Open',
    label: 'Normally Open',
  },
  {
    value: 'Normally Close',
    label: 'Normally Close',
  },
];

export const baudRate = [
  {
    value: 9600,
    label: '9600',
  },
  {
    value: 115200,
    label: '115,200',
  },
  {
    value: 19200,
    label: '19,200',
  },
  {
    value: 38400,
    label: '38,400',
  },
];

export const readerModeTypes = [
  {
    value: 'Wiegand',
    label: 'Wiegand',
  },
  {
    value: 'ODSP',
    label: 'ODSP',
  },
];

export const IOStateType = {
  ACTIVE: 'ACTIVE',
  AVAILABLE: 'AVAILABLE',
  TROUBLE: 'TROUBLE',
  SHUNT: 'SHUNT'
}

export const IOType = {
  INPUT: 'INPUT',
  OUTPUT: 'OUTPUT',
  READER: 'READER'
}

export const processIoConfig = (ioConfig, type) => {
  if (!ioConfig?.[type]?.length) return [];
  return ioConfig[type]
    // .map((item) => ({
    //   ...item,
    //   customIndex: Number(item.ioId.split(type)[1]),
    // }))
    .sort((a, b) => a.ioPort - b.ioPort);
};
