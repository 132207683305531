import React, { useState } from 'react';
import ResponsiveStack from '../../../components/common/SiderBarDrawer/ResponsiveStack';
import { Box, Paper, TableContainer, Typography } from '@mui/material';
import CustomButton from '../../../components/forms/CustomButton';
import CustomSearch from '../../../components/common/CustomSearch';
import { useCardHolderGroupStore } from '../../../store/cardHolderGroupStore';
import AddPolicies from './AddPolicies';
import AddCardHolders from './AddCardHolders';
import CardHolderListTable from './CardHolderListTable';

const CardHolders = ({ setFieldValue }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const { getAttachedCardHolders } = useCardHolderGroupStore();
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  return (
    <ResponsiveStack direction="column" spacing={2} smallScreenSpacing={6}>
      <ResponsiveStack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ height: '44.5px' }}
        spacing={2}
      >
        <ResponsiveStack direction="column" spacing={0.5}>
          <Typography variant="customTitle">Cardholders</Typography>
          <Typography variant="customSubTitle">
            Assign cardholders who share common properties and privileges
          </Typography>
        </ResponsiveStack>
      </ResponsiveStack>
      <ResponsiveStack alignItems="center" justifyContent="space-between">
        <CustomSearch
          sx={{ width: '350px' }}
          placeHolder="Search By Cardholder Name"
          {...{
            handleSearchChange,
            searchQuery,
            setSearchQuery,
          }}
        />
        <CustomButton variant="contained" onClick={() => setShowModal(true)}>
          Assign Cardholders
        </CustomButton>
      </ResponsiveStack>
      <ResponsiveStack alignItems="center">
        <TableContainer
          component={Paper}
          sx={{ overflowX: 'auto', pl: 2, pr: 2, borderRadius: '20px' }}
        >
          {!getAttachedCardHolders() || getAttachedCardHolders().length <= 0 ? (
            <Box
              height="100vh"
              alignItems="center"
              justifyContent="center"
              display="flex"
            >
              <ResponsiveStack direction="column" spacing={0}>
                <Typography variant="">
                  You haven’t added any Cardholders yet
                </Typography>
                <CustomButton
                  variant="text"
                  sx={{ textDecoration: 'underline' }}
                  onClick={() => setShowModal(true)}
                  disableRipple
                >
                  <Typography variant="customSubTitleBold">
                    Assign Cardholders
                  </Typography>
                </CustomButton>
              </ResponsiveStack>
            </Box>
          ) : (
            <CardHolderListTable />
          )}
        </TableContainer>
      </ResponsiveStack>
      {showModal && (
        <AddCardHolders
          {...{ showModal, hideModal, setFieldValue }}
        ></AddCardHolders>
      )}
    </ResponsiveStack>
  );
};

export default CardHolders;
