import { Box } from '@mui/material';
import React from 'react';
import { Header } from '../components/common';
import SideBarDrawer from '../components/common/SiderBarDrawer/SideBarDrawer';
import { Outlet } from 'react-router-dom';
import { useLoggedInUserData } from '../store/LoggedInAccountStore';

const PrivateLayout = ({ activeMenu }) => {
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData,
  );
  return (
    <Box width="100%">
      <Header />
      <Box sx={{ display: 'flex', width: '100%' }}>
        {getLoggedInUserData()?.accountStatus !== 'INACTIVE' && (
          <SideBarDrawer activeMenu={activeMenu} />
        )}
        <Box width="100%" mr={3} pt={2}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default PrivateLayout;
