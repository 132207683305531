import { lazy } from 'react';
import withSuspense from '../withSuspense';
const CardHolderProfile = withSuspense(
  lazy(() => import('../../pages/cardholders/CardHolderProfile'))
);
const ManageCardholders = withSuspense(
  lazy(() => import('../../pages/cardholders/ManageCardholders'))
);

export const cardHolderRoutes = [
  { path: 'manage.html', element: <ManageCardholders /> },
  { path: '', element: <ManageCardholders /> },
  {
    path: 'profile.html',
    element: <CardHolderProfile />,
  },
];
