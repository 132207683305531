import { apiMessages } from '../../helpers';
import { useSnackbar } from '../context/SnackbarProvider';

export const useGlobalApi = () => {
  const showSnackbar = useSnackbar();

  /**
   * Function to handle asyn operations with conditional message handling
   * @params operation, msg, args
   * operation and msg params mandatory, if no msg then pass null
   */
  const executeAsyncOperation = async (operation, msg, ...args) => {
    try {
      const result = await operation(...args);
      if (!result) return;
      if (result?.data?.meta?.code === 200 || result?.meta?.code === 200) {
        msg && showSnackbar(msg, 'success');
      } else if (result?.data?.meta?.code !== 200) {
        showSnackbar(
          result?.data?.meta?.desc ?? apiMessages.SOMETHING_WENT_WRONG,
          'error',
        );
        throw new Error(result?.data?.meta?.desc ?? apiMessages.SOMETHING_WENT_WRONG);
      } else {
        msg && showSnackbar(msg, 'success');
      }
      return result;
    } catch (error) {
      msg && showSnackbar(error.message, 'error');
      return null;
    }
  };

  return { executeAsyncOperation };
};
