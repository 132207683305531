import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import {
  AvatarList,
  BreadcrumbList,
  CommonBlock,
  Header,
  LicensesList,
  ListBlock,
  PrimaryButton,
  ProgressBar,
  ScrollbarWrapper,
  SiteModal,
} from '../../components/common/';
import PageWrapper from '../../pages/PageWrapper';
import { constants, Utils, roles } from '../../helpers/';
import { EditOrganization } from '../../pages/organizations/';
import { useSelector, useDispatch } from 'react-redux';
import {
  orgUserData,
  setAllDevicesData,
  setLocationsData,
  setSelectedOrganization,
  getAllDevicesData,
} from '../../store/reducers/AccountReducer';

import useIncidentsStore from '../../store/IncidentsStore';
import useLicensesStore from '../../store/LicensesStore';
import { useCustomerOrgUsers } from '../../store/CustomerOrgUsersStore';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { useOrganizations } from '../../store/OrganizationsStore';
import { usePoliciesStore } from '../../store/policiesStore';
import { setCDNInfo } from '../../store/reducers/StreamingReducer';
import { ReactComponent as Exclamation } from '../../assets/images/icons/Exclamation.svg';
import { ReactComponent as ErrorIcon } from '../../assets/images/icons/errorIcon.svg';
import { ReactComponent as Tick } from '../../assets/images/icons/Tick.svg';
import { RiErrorWarningLine } from 'react-icons/ri';
import LocationsMap from './LocationsMap';
import { HiUserCircle } from 'react-icons/hi2';
import { mqttUnsubscribe } from '../../utils/connection/mqttConnection';
import { getPlatformInfo } from '../../store/reducers/StreamingReducer';
import { getAccountId } from '../../store/reducers/AccountReducer';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import './ManageOrganizations.scss';
import RemoveOrganization from './RemoveOrganization';

function OrganizationDashboard(props) {
  const [showEditOrgModal, setShowEditOrgModal] = useState(false);
  const [showRemoveOrgModal, setShowRemoveOrgModal] = useState(false);
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');

  const dispatch = useDispatch();

  //153894 get user data from reducer
  const { allDevices, userdata, orgUsers } = useSelector(
    (state) => state.accounts
  );
  const [onlinestatus, setOnlinestatus] = useState(0);
  const [oflinestatus, setOflinestatus] = useState(0);
  const [userId, setUserid] = useState();
  const [role, setRole] = useState([]);
  const [role2, setRole2] = useState([]);
  const [unclaimed, setUnclaimed] = useState(0);
  const [expiredLicenses, setExpiredLicenses] = useState(0);
  const [expiringLicenses, setExpiringLicenses] = useState(0);
  const [availableLicenses, setAvailableLicenses] = useState(0);
  const [usedLicenses, setUsedLicenses] = useState(0);
  const [offlinenotPendingclaim, setOfflinenotPendingclaim] = useState(0);
  const [editUser, setEditUser] = useState({});
  const [custPartnerOrgData, setCustPartnerOrgData] = useState({});
  // TODO : use later after demo2
  const [showRemovePartnerModal, setShowRemovePartnerModal] = useState(false);
  const breadList = [
    {
      url: '/customers/manage.html',
      title:
        loggedInUser.role !== roles.ROLE2VMS
          ? constants.MANAGE_ORG_PAGE_TITLE
          : constants.MANAGE_ORG_PAGE_ORG_TITLE,
    },
    {
      url: `/customers/dashboard.html?orgId=${orgId}`,
      title: editUser?.orgName,
    },
  ];

  //=== Store get/set actions
  const { setIncidents, getIncidents } = useIncidentsStore();
  const { setLicenses, getLicenses } = useLicensesStore();
  const setCustomerOrgUsers = useCustomerOrgUsers(
    (state) => state.setCustomerOrgUsers
  );
  const getCustomerOrgUsersData = useCustomerOrgUsers(
    (state) => state.getCustomerOrgUsersData
  );
  const getCustomerOrgLocations = useCustomerOrgLocations(
    (state) => state.getCustomerOrgLocations
  );
  const customerOrgData = useOrganizations((state) => state.customerOrgData);
  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData
  );
  const getOrganizations = useOrganizations((state) => state.getOrganizations);
  const loggedInUserRole = useOrganizations((state) => state.loggedInUserRole);
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies
  );
  const getLoggedInUserPolicies = usePoliciesStore(
    (state) => state.getLoggedInUserPolicies
  );
  const deviceListData = useSelector(getAllDevicesData);
  const platformDetails = useSelector(getPlatformInfo);
  const accountId = useSelector(getAccountId);
  const subscribeTopic =
    platformDetails?.mqtt?.topic_details?.subscribe?.settings;
  const getLoggedInUserUUID = useLoggedInUserData(
    (state) => state.getLoggedInUserUUID
  );
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData
  );
  const loggedInUser = getLoggedInUserData();

  useEffect(() => {
    if (Array.isArray(getCustomerOrgData())) {
      const userIndex = getCustomerOrgData().find(
        (user) => user.orgId === orgId
      );
      dispatch(setSelectedOrganization(userIndex));
      setEditUser({
        ...userIndex,
      });
      setOnlinestatus(
        userIndex?.devices?.filter(
          (devicObj) => devicObj.connectionStatus === 'online'
        )
      );
      setOflinestatus(
        userIndex?.devices?.filter(
          (devicObj) => devicObj.connectionStatus === 'offline'
        )
      );
      setUnclaimed(
        userIndex?.devices?.filter(
          (devicObjj) => devicObjj.deviceStatus === 'PENDING_CLAIM'
        )
      );
      setOfflinenotPendingclaim(
        userIndex?.devices?.filter(
          (devicObj) => devicObj.deviceStatus !== 'PENDING_CLAIM'
        )
      );
      setUserid(userIndex?.orgId === orgId);
    }
  }, [getCustomerOrgData()]);

  useEffect(() => {
    getDevices();
    getLocations();
    getPartnerOrg();
    fetchData();

    if (Array.isArray(getCustomerOrgData())) {
      const userIndex = getCustomerOrgData().find(
        (user) => user.orgId === orgId
      );
      dispatch(setSelectedOrganization(userIndex));
      setEditUser({
        ...userIndex,
      });
      setOnlinestatus(
        userIndex?.devices?.filter(
          (devicObj) => devicObj.connectionStatus === 'online'
        )
      );
      setOflinestatus(
        userIndex?.devices?.filter(
          (devicObj) => devicObj.connectionStatus === 'offline'
        )
      );
      setUnclaimed(
        userIndex?.devices?.filter(
          (devicObjj) => devicObjj.deviceStatus === 'PENDING_CLAIM'
        )
      );
      setOfflinenotPendingclaim(
        userIndex?.devices?.filter(
          (devicObj) => devicObj.deviceStatus !== 'PENDING_CLAIM'
        )
      );
      setUserid(userIndex?.orgId === orgId);
    }

    if (getCustomerOrgUsersData()?.length > 0) {
      const roleCondition1 = getCustomerOrgUsersData()?.filter(
        (user) =>
          (user.role === roles.ROLE2VMS || user.role === roles.ROLE3VMS) &&
          loggedInUser?.accountId !== user?.accountId
      );
      setRole(roleCondition1);
      const roleCondition2 = getCustomerOrgUsersData()?.filter(
        (user) => user.role === roles.ROLE1VMS
      );

      setRole2(roleCondition2);
    }
  }, []);

  const fetchData = async () => {
    try {
      // TODO: determine if a loader is necessary
      // setShowLoader(true);

      // Retrieve available incidents
      let incidentsLoadSuccess = await setIncidents(
        `/user/orgs/${orgId}/incidents`
      );

      if (incidentsLoadSuccess?.status !== 'success') {
        // TODO: implement this later
        // setUserMsg(incidentsLoadSuccess.msg);
        // setShowToast(true);
      }

      // Retrieve available licenses
      let licensesLoadSuccess = await setLicenses(
        `/user/orgs/${orgId}/licenses`
      );

      if (licensesLoadSuccess?.status === 'success') {
        // Get all paid licenses that are available
        let custLicenses = getLicenses(false, true);

        setAvailableLicenses(
          custLicenses.filter(
            (license) =>
              license.licenseStatus === 'UNASSIGNED' ||
              license.licenseStatus === 'ACTIVE_UNASSIGNED'
          ).length
        );
        // Get all licenses that have expired
        setExpiredLicenses(
          custLicenses.filter(
            (license) =>
              license.expiryDate &&
              Utils.getDateDifferenceInDays(
                license.expiryDate,
                moment().valueOf()
              ) <= 0
          ).length
        );
        // Get all licenses that are expiring soon
        setExpiringLicenses(
          custLicenses.filter(
            (license) =>
              license.licenseStatus !== 'UNASSIGNED' &&
              license.expiryDate &&
              Utils.getDateDifferenceInDays(
                license.expiryDate,
                moment().valueOf()
              ) <= license.expiringSoonInDays &&
              Utils.getDateDifferenceInDays(
                license.expiryDate,
                moment().valueOf()
              ) >= 0
          ).length
        );
        // Get all licenses  that are active
        setUsedLicenses(
          custLicenses.filter(
            (license) =>
              license.licenseStatus === 'ACTIVE' ||
              license.licenseStatus === 'ASSIGNED_ACTIVATION_PENDING'
          ).length
        );
      } else {
        // TODO: implement this later
        // setUserMsg(licensesLoadSuccess.msg);
        // setShowToast(true);
      }
    } catch (error) {
      // TODO: implement this later
      // setUserMsg(error);
      // setShowToast(true);
    } finally {
      // TODO: implement this later
      // setShowLoader(false);
    }
  };

  const onChangeEdit = (values) => {
    setEditUser({ ...editUser, ...values });
  };

  const getDevices = () => {
    axios
      .get(`device/orgs/${orgId}/devices`, Utils.requestHeader())
      .then((response) => {
        let resultData = response.data;
        if (resultData) {
          let responseMeta = resultData.meta;
          let responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            getCapabiltiesFromDeviceList(responseData);
            const devicePropertyErrorUnSubscribe = {
              topic: `d/notify/${accountId}/${getLoggedInUserUUID()}`,
            };
            mqttUnsubscribe(devicePropertyErrorUnSubscribe);
          }
        }
      });
  };

  const getCapabiltiesFromDeviceList = async (deviceData) => {
    let deviceList = [...deviceData];
    let newDeviceList = deviceList?.map(async (device, index) => {
      const deviceUnSubscription = {
        topic: Utils.replaceStringValues(
          subscribeTopic[0],
          '${deviceId}',
          `${device?.deviceId}`
        ),
      };
      mqttUnsubscribe(deviceUnSubscription);
      const deviceInfo = deviceListData?.find(
        (deviceStore) => deviceStore?.deviceId === device?.deviceId
      );
      let isCapabilityCall =
        deviceListData?.length === 0 ||
        deviceInfo?.capDetails === undefined ||
        deviceInfo?.capability?.version !== device?.capability?.version;
      if (device?.capability && isCapabilityCall) {
        let response = await fetch(device.capability.url);
        if (response.status === 200) {
          let responseJson = await response.json();
          return { ...device, capDetails: responseJson };
        } else {
          return { ...device };
        }
      } else if (
        device?.capability &&
        deviceInfo?.capability?.version === device?.capability?.version
      ) {
        return { ...device, capDetails: deviceInfo?.capDetails };
      } else {
        return { ...device };
      }
    });
    let deviceCapability = await Promise.all(newDeviceList);
    dispatch(setAllDevicesData(deviceCapability));
  };

  const getLocations = () => {
    axios
      .get(`partner/orgs/${orgId}/locations`, Utils.requestHeader())
      .then((response) => {
        let resultData = response.data;
        if (resultData) {
          let responseMeta = resultData.meta;
          let responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            const data = [...responseData];
            const defaultLocation = data?.filter(
              (location) => location.isDefault === 'true'
            );
            const finalData = data.sort(function (x, y) {
              return x === defaultLocation[0]
                ? -1
                : y === defaultLocation[0]
                ? 1
                : 0;
            });
            dispatch(setLocationsData(finalData));
          }
        }
      });
  };

  const getPartnerOrg = () => {
    axios
      .get(`partner/orgs/${orgId}/partnerOrg`, Utils.requestHeader())
      .then((response) => {
        let resultData = response.data;
        if (resultData) {
          let responseMeta = resultData.meta;
          let responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            setCustPartnerOrgData(responseData);
          }
        }
      });
  };

  //=== Get organizations data
  const fetchOrganizations = async () => {
    await getOrganizations(`/partner/account/self`);
  };

  // Date format
  const newdate = moment(Utils.getDate(editUser?.createdDate / 1000)).format(
    'MMMM Do, YYYY'
  );

  const unclaimedpercentage = (
    (unclaimed?.length / editUser?.devices?.length) *
    100
  ).toFixed(2);
  const offlinePercentage = (
    (Utils.getOfflineCameras(allDevices) / editUser?.devices?.length) *
    100
  ).toFixed(2);
  const onlinestatusPercentage = (
    (onlinestatus?.length / editUser?.devices?.length) *
    100
  ).toFixed(2);
  const zeroDevices = editUser?.devices?.length / 100;
  const locationAddress = `${editUser?.addressLine1}, ${editUser?.city}, ${editUser?.city}, ${editUser?.country}`;
  let defaultLocation = editUser?.locations?.filter(
    (location) => location.isDefault.toString() === 'true'
  );
  let locationCoordinates = {
    lat: 0,
    lng: 0,
  };
  if (defaultLocation?.length > 0) {
    locationCoordinates.lat = defaultLocation[0]?.lat;
    locationCoordinates.lng = defaultLocation[0]?.lng;
  }

  const truncate = (string, length, delimiter) => {
    delimiter = delimiter || '...';
    return string.length > length
      ? string.substr(0, length) + delimiter
      : string;
  };

  const dashboardLocation = truncate(locationAddress, 65);

  const fetchUser = async () => {
    try {
      await axios
        .get(`partner/orgs/${orgId}/accounts`, Utils.requestHeader())
        .then((response) => {
          let resultData = response?.data?.data;
          setCustomerOrgUsers(resultData);
          dispatch(orgUserData(resultData));
        });
    } catch (error) {
      // TODO : to print the error will update this later
      console.error(error);
    }
  };

  const fetchLocations = async () => {
    try {
      await getCustomerOrgLocations(`partner/orgs/${orgId}/locations`);
    } catch (error) {
      // TODO : to print the error will update this later
      console.error(error);
    }
  };

  useEffect(() => {
    if (orgId) {
      fetchUser();
      fetchLocations();
    }
  }, [orgId]);

  useEffect(() => {
    if (Array.isArray(getCustomerOrgUsersData())) {
      const roleCondition1 = getCustomerOrgUsersData()?.filter(
        (user) =>
          (user.role === roles.ROLE2VMS || user.role === roles.ROLE3VMS) &&
          loggedInUser?.accountId !== user?.accountId
      );
      setRole(roleCondition1);
      const roleCondition2 = getCustomerOrgUsersData()?.filter(
        (user) => user.role === roles.ROLE1VMS
      );
      setRole2(roleCondition2);
    }
  }, [getCustomerOrgUsersData()]);

  return (
    <div className="App org-detail-page">
      <Header breadcrumbData={breadList} />

      <PageWrapper>
        <div className="page-header">
          <Container>
            <Row>
              <Col className="text-start">
                <BreadcrumbList list={breadList} />
              </Col>
            </Row>
            <Row>
              <Col
                md={6}
                lg={6}
                xl={6}
                xs={12}
                className="page-title text-start"
              >
                {editUser?.orgName}

                <p className="page-title-note">Customer Since {newdate}</p>
              </Col>
              <Col
                md={6}
                lg={6}
                xl={6}
                xs={12}
                className="text-end page-header-buttons"
              >
                {getCustomerOrgPolicies()?.update_cust_org && (
                  <PrimaryButton
                    className="btn btn-primary-outline"
                    type="button"
                    width="auto"
                    borderWidth="1.5px"
                    hoverBorderWidth="1.5px"
                    color={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--primary_40')}
                    hoverColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--brand_white')}
                    backgroundColor="transparent"
                    onClick={() => {
                      setShowEditOrgModal(true);
                    }}
                  >
                    {constants.ORG_DASHBOARD_EDIT_BUTTON_TITLE}
                  </PrimaryButton>
                )}
              </Col>
            </Row>
          </Container>
        </div>

        {/* Main content start*/}
        <section className="main-content">
          <Container className="h-100 container-sm">
            <Row>
              <Col
                md={6}
                lg={6}
                xl={6}
                xs={12}
                className="detail-page-location-block"
              >
                <ListBlock
                  height="168px"
                  contentWrapperClass="text-start"
                  label={constants.ORG_DASHBOARD_LOCATIONS_SECTION_TITLE}
                  navigateUrl={
                    getCustomerOrgPolicies()?.view_location &&
                    `/locations/manage.html?orgId=${orgId}`
                  }
                  navigateText={
                    getCustomerOrgPolicies()?.view_location &&
                    constants.ORG_DASHBOARD_LOCATIONS_SECTION_NAVIGATE_TEXT
                  }
                >
                  <Row>
                    <Col xs="12" lg="6">
                      <LocationsMap
                        locationPositions={{
                          lat: locationCoordinates?.lat,
                          lng: locationCoordinates?.lng,
                        }}
                      />
                    </Col>
                    <Col xs="12" lg="6" className="align-self-center">
                      <span className="mainaddress">
                        {constants.ORG_DASHBOARD_LOCATIONS_SECTION_HEADING}
                      </span>
                      <div className="location-address">
                        {dashboardLocation}
                      </div>
                    </Col>
                  </Row>
                </ListBlock>
              </Col>
              <Col
                md={6}
                lg={6}
                xl={6}
                xs={12}
                className="detail-page-licenses-block"
              >
                <ListBlock
                  height="168px"
                  contentWrapperClass="text-start"
                  label={constants.ORG_DASHBOARD_LICENSES_SECTION_TITLE}
                  navigateUrl={`/licenses/listing.html?orgId=${orgId}&orgName=${editUser?.orgName}`}
                  navigateText={
                    constants.ORG_DASHBOARD_LICENSES_SECTION_NAVIGATE_TEXT
                  }
                >
                  <LicensesList
                    label={constants.ORG_DASHBOARD_LICENSES_EXPIRED_TITLE}
                    numberText={expiredLicenses}
                    icon={
                      <RiErrorWarningLine size={18} className="expire-icon" />
                    }
                    color={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--error_64')}
                    className="expired-licenses"
                  />
                  <LicensesList
                    label={constants.ORG_DASHBOARD_LICENSES_EXPIRING_SOON_TITLE}
                    numberText={expiringLicenses}
                  />
                  <LicensesList
                    label={constants.ORG_DASHBOARD_LICENSES_AVAILABLE_TITLE}
                    numberText={availableLicenses}
                  />
                  <LicensesList
                    label={constants.ORG_DASHBOARD_LICENSES_USED_TITLE}
                    numberText={usedLicenses}
                  />
                </ListBlock>
              </Col>
            </Row>

            {editUser?.devices?.length === 0 ? (
              <Row className="detail-page-devices-block">
                <Col>
                  <ListBlock
                    height="92px"
                    contentWrapperClass="text-start"
                    label={constants.ORG_DASHBOARD_DEVICES_SECTION_TITLE}
                    navigateUrl={
                      getCustomerOrgPolicies()?.view_device &&
                      `/devices/listing.html?orgId=${orgId}`
                    }
                    navigateText={
                      getCustomerOrgPolicies()?.view_device &&
                      constants.ORG_DASHBOARD_DEVICES_SECTION_NAVIGATE_TEXT
                    }
                  >
                    <Row>
                      <Col xs="12" lg="4">
                        <CommonBlock
                          blockType="error"
                          backgroundColor="transparent"
                          contentWrapperClass="text-start"
                          icon={
                            <ProgressBar
                              width="60px"
                              trailStroke={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--greyscale_80')}
                              background={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--greyscale_80')}
                              percentage={zeroDevices}
                            />
                          }
                          label={
                            constants.ORG_DASHBOARD_ONLINE_DEVICES_SECTION_TITLE
                          }
                          value={`${editUser?.devices?.length}`}
                        />
                      </Col>
                    </Row>
                  </ListBlock>
                </Col>
              </Row>
            ) : onlinestatus?.length === editUser?.devices?.length ? (
              <Row className="detail-page-devices-block">
                <Col>
                  <ListBlock
                    height="92px"
                    backgroundColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--success_96')}
                    contentWrapperClass="text-start"
                    label={constants.ORG_DASHBOARD_DEVICES_SECTION_TITLE}
                    navigateUrl={
                      getCustomerOrgPolicies()?.view_device &&
                      `/devices/listing.html?orgId=${orgId}`
                    }
                    navigateText={
                      getCustomerOrgPolicies()?.view_device &&
                      constants.ORG_DASHBOARD_DEVICES_SECTION_NAVIGATE_TEXT
                    }
                  >
                    <Row>
                      <Col xs="12" lg="4">
                        <CommonBlock
                          blockType="error"
                          backgroundColor="transparent"
                          contentWrapperClass="text-start"
                          icon={
                            <ProgressBar
                              width="60px"
                              trailStroke={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--success_80')}
                              background={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--success_48')}
                              percentage={onlinestatusPercentage}
                              icon={<Tick />}
                            />
                          }
                          label={
                            constants.ORG_DASHBOARD_ONLINE_DEVICES_SECTION_TITLE
                          }
                          value={`${onlinestatus?.length}/${editUser?.devices?.length}`}
                        />
                      </Col>
                    </Row>
                  </ListBlock>
                </Col>
              </Row>
            ) : onlinestatus?.length > 0 ? (
              <Row className="detail-page-devices-block">
                <Col md={6} lg={6} xl={6} xs={12}>
                  <ListBlock
                    height="92px"
                    contentWrapperClass="text-start"
                    label={constants.ORG_DASHBOARD_DEVICES_SECTION_TITLE}
                    backgroundColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--success_96')}
                  >
                    <Row>
                      <Col xs="12" lg="7">
                        <CommonBlock
                          blockType="error"
                          backgroundColor="transparent"
                          contentWrapperClass="text-start"
                          icon={
                            <ProgressBar
                              width="60px"
                              height
                              trailStroke={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--success_80')}
                              background={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--success_48')}
                              percentage={onlinestatusPercentage}
                              icon={<Tick />}
                            />
                          }
                          label={
                            constants.ORG_DASHBOARD_ONLINE_DEVICES_SECTION_TITLE
                          }
                          value={`${onlinestatus?.length}/${editUser?.devices?.length}`}
                        />
                      </Col>
                    </Row>
                  </ListBlock>
                </Col>

                {Utils.getOfflineCameras(allDevices) > 0 ? (
                  <Col md={6} lg={6} xl={6} xs={12}>
                    <ListBlock
                      height="92px"
                      contentWrapperClass="text-start"
                      label={constants.ORG_DASHBOARD_DEVICES_SECTION_TITLE}
                      backgroundColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--error_block_background_color')}
                      navigateUrl={
                        getCustomerOrgPolicies()?.view_device &&
                        `/devices/listing.html?orgId=${orgId}`
                      }
                      navigateText={
                        getCustomerOrgPolicies()?.view_device &&
                        constants.ORG_DASHBOARD_DEVICES_SECTION_NAVIGATE_TEXT
                      }
                    >
                      <Row>
                        <Col xs="12" lg="7">
                          <CommonBlock
                            blockType="error"
                            backgroundColor={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue(
                              '--error_block_background_color'
                            )}
                            contentWrapperClass="text-start"
                            icon={
                              <ProgressBar
                                width="60px"
                                height
                                trailStroke={getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue(
                                  '--device_error_trail_color'
                                )}
                                background={getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue('--error_112')}
                                percentage={offlinePercentage}
                                icon={<ErrorIcon />}
                              />
                            }
                            label={
                              constants.ORG_DASHBOARD_DEVICES_OFFLINE_CAMERAS_SECTION_TITLE
                            }
                            value={`${Utils.getOfflineCameras(allDevices)}/${
                              editUser?.devices?.length
                            }`}
                          />
                        </Col>
                        <Col xs="12" lg="5" className="trouble-block">
                          <CommonBlock
                            blockType="error"
                            backgroundColor={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue(
                              '--error_block_background_color'
                            )}
                            contentWrapperClass="text-end trouble"
                            label={
                              constants.ORG_DASHBOARD_DEVICES_OFFLINE_CAMERAS_TROUBLESHOOT_SECTION_TITLE
                            }
                          />
                        </Col>
                      </Row>
                    </ListBlock>
                  </Col>
                ) : (
                  unclaimed?.length > 0 && (
                    <Col
                      md={6}
                      lg={6}
                      xl={6}
                      xs={12}
                      className="unclaimed-lock"
                    >
                      <ListBlock
                        height="92px"
                        contentWrapperClass="text-start"
                        backgroundColor={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--warning_96')}
                        navigateUrl={
                          getCustomerOrgPolicies()?.view_device &&
                          `/devices/listing.html?orgId=${orgId}`
                        }
                        navigateText={
                          getCustomerOrgPolicies()?.view_device &&
                          constants.ORG_DASHBOARD_DEVICES_SECTION_NAVIGATE_TEXT
                        }
                      >
                        <Row>
                          <Col xs="12" lg="7">
                            <CommonBlock
                              blockType="error"
                              backgroundColor={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--warning_96')}
                              contentWrapperClass="text-start"
                              icon={
                                <ProgressBar
                                  width="60px"
                                  trailStroke={getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue('--warning_80')}
                                  background={getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue('--warning_48')}
                                  percentage={unclaimedpercentage}
                                  icon={<Exclamation />}
                                />
                              }
                              label={
                                constants.ORG_DASHBOARD_UNCLAIMED_DEVICES_SECTION_TITLE
                              }
                              value={`${unclaimed?.length}`}
                            />
                          </Col>
                        </Row>
                      </ListBlock>
                    </Col>
                  )
                )}
              </Row>
            ) : (
              <Row className="detail-page-devices-block">
                <Col md={6} lg={6} xl={6} xs={12}>
                  <ListBlock
                    height="92px"
                    contentWrapperClass="text-start"
                    label={constants.ORG_DASHBOARD_DEVICES_SECTION_TITLE}
                    backgroundColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--error_block_background_color')}
                  >
                    <Row>
                      <Col xs="12" lg="7">
                        <CommonBlock
                          blockType="error"
                          backgroundColor={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--error_block_background_color')}
                          contentWrapperClass="text-start"
                          icon={
                            <ProgressBar
                              width="60px"
                              height
                              trailStroke={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--device_error_trail_color')}
                              background={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--error_112')}
                              percentage={offlinePercentage}
                              icon={<ErrorIcon />}
                            />
                          }
                          label={
                            constants.ORG_DASHBOARD_DEVICES_OFFLINE_CAMERAS_SECTION_TITLE
                          }
                          value={`${Utils.getOfflineCameras(allDevices)}/${
                            editUser?.devices?.length
                          }`}
                        />
                      </Col>
                      <Col xs="12" lg="5" className="trouble-block">
                        <CommonBlock
                          blockType="error"
                          backgroundColor={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--error_block_background_color')}
                          contentWrapperClass="text-end trouble"
                          label={
                            constants.ORG_DASHBOARD_DEVICES_OFFLINE_CAMERAS_TROUBLESHOOT_SECTION_TITLE
                          }
                        />
                      </Col>
                    </Row>
                  </ListBlock>
                </Col>

                <Col md={6} lg={6} xl={6} xs={12} className="unclaimed-lock">
                  <ListBlock
                    height="92px"
                    contentWrapperClass="text-start"
                    backgroundColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--warning_96')}
                    navigateUrl={
                      getCustomerOrgPolicies()?.view_device &&
                      `/devices/listing.html?orgId=${orgId}`
                    }
                    navigateText={
                      getCustomerOrgPolicies()?.view_device &&
                      constants.ORG_DASHBOARD_DEVICES_SECTION_NAVIGATE_TEXT
                    }
                  >
                    <Row>
                      <Col xs="12" lg="7">
                        <CommonBlock
                          blockType="error"
                          backgroundColor={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--warning_96')}
                          contentWrapperClass="text-start"
                          icon={
                            <ProgressBar
                              width="60px"
                              trailStroke={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--warning_80')}
                              background={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--warning_48')}
                              percentage={unclaimedpercentage}
                              icon={<Exclamation />}
                            />
                          }
                          label={
                            constants.ORG_DASHBOARD_UNCLAIMED_DEVICES_SECTION_TITLE
                          }
                          value={`${unclaimed?.length}`}
                        />
                      </Col>
                    </Row>
                  </ListBlock>
                </Col>
              </Row>
            )}

            <Row>
              {role?.length ? (
                <Col
                  md={6}
                  lg={6}
                  xl={6}
                  xs={12}
                  className="detail-page-users-block"
                >
                  <ListBlock
                    height="168px"
                    contentWrapperClass="text-start"
                    label={constants.ORG_DASHBOARD_USER_SECTION_TITLE}
                    navigateUrl={
                      getCustomerOrgPolicies()?.view_account &&
                      `/users/manage.html?orgId=${editUser?.orgId}`
                    }
                    navigateText={
                      getCustomerOrgPolicies()?.view_account &&
                      constants.ORG_DASHBOARD_USER_SECTION_NAVIGATE_TEXT
                    }
                  >
                    <ScrollbarWrapper height="130px">
                      {role?.slice(0, 3).map((user) => {
                        let fNametext = user.firstName;
                        let fLetter = fNametext.charAt(0);
                        let lNametext = user.lastName;
                        let lNameLetter = lNametext.charAt(0);
                        let profileImg = user?.image?.url;
                        let letter = user?.name
                          .match(/(\b\S)?/g)
                          .join('')
                          .match(/(^\S|\S$)?/g)
                          .join('')
                          .toUpperCase();

                        return (
                          <div key={user.accountId}>
                            {loggedInUserRole === roles.ROLE4VMS ||
                            loggedInUserRole === roles.ROLE5VMS ? (
                              <AvatarList
                                avatarValueType={profileImg ? 'icon' : 'text'}
                                avatarValue={profileImg ? profileImg : letter}
                                title={`${user.firstName} ${user.lastName}`}
                              />
                            ) : (
                              <AvatarList
                                avatarValueType={profileImg ? 'icon' : 'text'}
                                avatarValue={profileImg ? profileImg : letter}
                                title={`${user.firstName} ${user.lastName}`}
                                avatarBackgroundColor={getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue('--greyscale_88')}
                                avatarColor={getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue('--greyscale_40')}
                              />
                            )}
                          </div>
                        );
                      })}
                    </ScrollbarWrapper>
                  </ListBlock>
                </Col>
              ) : (
                <Col
                  md={6}
                  lg={6}
                  xl={6}
                  xs={12}
                  className="detail-page-users-block noUser-block"
                >
                  <ListBlock
                    height="168px"
                    contentWrapperClass="text-start"
                    label={constants.ORG_DASHBOARD_USER_SECTION_TITLE}
                    navigateUrl={
                      getCustomerOrgPolicies()?.view_account &&
                      `/users/manage.html?orgId=${editUser?.orgId}`
                    }
                    navigateText={
                      getCustomerOrgPolicies()?.view_account &&
                      constants.ORG_DASHBOARD_USER_SECTION_NAVIGATE_TEXT
                    }
                  >
                    <Row>
                      <Col className="userIcon">
                        <HiUserCircle
                          size={48}
                          fill={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--greyscale_80')}
                        />
                        <div className="noUser">
                          {constants.ORG_DASHBOARD_USER_NOT_FOUND}
                        </div>
                      </Col>
                    </Row>
                  </ListBlock>
                </Col>
              )}

              {loggedInUserRole === roles.ROLE4VMS ||
              loggedInUserRole === roles.ROLE5VMS ? (
                role2?.length ? (
                  <Col
                    md={6}
                    lg={6}
                    xl={6}
                    xs={12}
                    className="detail-page-techs-block"
                  >
                    <ListBlock
                      height="168px"
                      contentWrapperClass="text-start"
                      label={constants.ORG_DASHBOARD_TECHNICIANS_SECTION_TITLE}
                      navigateUrl={
                        getLoggedInUserPolicies()?.enable_installer_account &&
                        `/managetechnicians.html?orgId=${editUser?.orgId}`
                      }
                      navigateText={
                        getLoggedInUserPolicies()?.enable_installer_account &&
                        constants.ORG_DASHBOARD_TECHNICIANS_NAVIGATE_TEXT
                      }
                    >
                      <ScrollbarWrapper height="130px">
                        {role2?.slice(0, 3).map((user) => {
                          let profileImg = user?.image?.url;
                          let letter = user?.name
                            .match(/(\b\S)?/g)
                            .join('')
                            .match(/(^\S|\S$)?/g)
                            .join('')
                            .toUpperCase();
                          return (
                            <div key={user.accountId}>
                              <AvatarList
                                avatarValueType={profileImg ? 'icon' : 'text'}
                                avatarValue={profileImg ? profileImg : letter}
                                title={`${user.firstName} ${user.lastName}`}
                              />
                            </div>
                          );
                        })}
                      </ScrollbarWrapper>
                    </ListBlock>
                  </Col>
                ) : (
                  <Col
                    md={6}
                    lg={6}
                    xl={6}
                    xs={12}
                    className="detail-page-techs-block noUser-block"
                  >
                    <ListBlock
                      height="168px"
                      contentWrapperClass="text-start"
                      label={constants.ORG_DASHBOARD_TECHNICIANS_SECTION_TITLE}
                      navigateUrl={
                        getLoggedInUserPolicies()?.enable_installer_account &&
                        `/managetechnicians.html?orgId=${editUser?.orgId}`
                      }
                      navigateText={
                        getLoggedInUserPolicies()?.enable_installer_account &&
                        constants.ORG_DASHBOARD_TECHNICIANS_NAVIGATE_TEXT
                      }
                    >
                      <Row>
                        <Col className="userIcon">
                          <HiUserCircle
                            size={48}
                            fill={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--greyscale_80')}
                          />
                          <div className="noUser">
                            {constants.ORG_DASHBOARD_TECHNICIANS_NOT_FOUND}
                          </div>
                        </Col>
                      </Row>
                    </ListBlock>
                  </Col>
                )
              ) : custPartnerOrgData &&
                getCustomerOrgPolicies()?.view_org_partnerorg ? (
                <Col
                  md={6}
                  lg={6}
                  xl={6}
                  xs={12}
                  className="detail-page-partner-techs-block"
                >
                  <ListBlock
                    height="168px"
                    contentWrapperClass="text-start"
                    label={constants.ORG_DASHBOARD_PARTNER_SECTION_TITLE}
                    navigateType="click"
                    navigateUrl={() => setShowRemovePartnerModal(true)}
                    navigateText={
                      constants.ORG_DASHBOARD_PARTNER_REMOVE_NAVIGATE_TEXT
                    }
                  >
                    <Row>
                      <Col>
                        <div
                          className={`org-detail-partner-list ${
                            !custPartnerOrgData?.orgName && 'd-none'
                          }`}
                        >
                          <div className="org-detail-partner-title">
                            {constants.ORG_DASHBOARD_PARTNER_ORG_NAME_LBAEL}
                          </div>
                          <div className="org-detail-partner-content">
                            {custPartnerOrgData?.orgName}
                          </div>
                        </div>
                        <div
                          className={`org-detail-partner-list ${
                            !custPartnerOrgData?.addressLine1 && 'd-none'
                          }`}
                        >
                          <div className="org-detail-partner-title">
                            {constants.ORG_DASHBOARD_PARTNER_ADDRESS_LBAEL}
                          </div>
                          <div className="org-detail-partner-content">
                            {custPartnerOrgData?.addressLine1}
                          </div>
                        </div>
                        <div
                          className={`org-detail-partner-list ${
                            !custPartnerOrgData?.phoneNumber && 'd-none'
                          }`}
                        >
                          <div className="org-detail-partner-title">
                            {constants.ORG_DASHBOARD_PARTNER_PHONE_NUMBER_LBAEL}
                          </div>
                          <div className="org-detail-partner-content">
                            {custPartnerOrgData?.phoneNumber}
                          </div>
                        </div>
                        <div
                          className={`org-detail-partner-list ${
                            !custPartnerOrgData?.email && 'd-none'
                          }`}
                        >
                          <div className="org-detail-partner-title">
                            {constants.ORG_DASHBOARD_PARTNER_EMAIL_LBAEL}
                          </div>
                          <div className="org-detail-partner-content">
                            {custPartnerOrgData?.email}
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="org-detail-partner-tech-title">
                          {constants.ORG_DASHBOARD_TECHNICIANS_SECTION_TITLE}
                        </div>
                        <ScrollbarWrapper height="130px">
                          {role2?.slice(0, 3).map((user) => {
                            let profileImg = user?.image?.url;
                            let letter = user?.name
                              .match(/(\b\S)?/g)
                              .join('')
                              .match(/(^\S|\S$)?/g)
                              .join('')
                              .toUpperCase();
                            return (
                              <div key={user.accountId}>
                                <AvatarList
                                  avatarValueType={profileImg ? 'icon' : 'text'}
                                  avatarValue={profileImg ? profileImg : letter}
                                  title={`${user.firstName} ${user.lastName}`}
                                />
                              </div>
                            );
                          })}
                        </ScrollbarWrapper>
                      </Col>
                    </Row>
                  </ListBlock>
                </Col>
              ) : (
                getCustomerOrgPolicies()?.view_org_partnerorg(
                  <Col
                    md={6}
                    lg={6}
                    xl={6}
                    xs={12}
                    className="detail-page-partner-techs-block noUser-block"
                  >
                    <ListBlock
                      height="168px"
                      contentWrapperClass="text-start"
                      label={constants.ORG_DASHBOARD_PARTNER_SECTION_TITLE}
                      navigateUrl={`/managetechnicians.html?orgId=${editUser?.orgId}`}
                      navigateText={
                        constants.ORG_DASHBOARD_PARTNER_INVITE_NAVIGATE_TEXT
                      }
                    >
                      <Row>
                        <Col className="userIcon">
                          <HiUserCircle
                            size={48}
                            fill={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--greyscale_80')}
                          />
                          <div className="noUser">
                            {constants.ORG_DASHBOARD_PARTNER_NOT_FOUND}
                          </div>
                        </Col>
                      </Row>
                    </ListBlock>
                  </Col>
                )
              )}
            </Row>
          </Container>
        </section>
        {/* Main content end*/}
      </PageWrapper>

      <SiteModal
        modalTitle={constants.EDIT_ORG_PAGE_TITLE}
        showModal={showEditOrgModal}
        hideModal={() => setShowEditOrgModal(false)}
      >
        <EditOrganization
          editUser={editUser}
          timezone={custPartnerOrgData?.timezone}
          onChangeEdit={onChangeEdit}
          reloadData={() => fetchOrganizations()}
          hideModal={() => setShowEditOrgModal(false)}
          showRemoveOrgModal={() => setShowRemoveOrgModal(true)}
          hideRemoveOrgModal={() => setShowRemoveOrgModal(false)}
        />
      </SiteModal>

      <SiteModal
        modalTitle={`Remove ${editUser.orgName}`}
        showModal={showRemoveOrgModal}
        hideModal={() => setShowRemoveOrgModal(false)}
        classes="remove-org-modal"
      >
        <RemoveOrganization
          editUser={editUser}
          timezone={custPartnerOrgData?.timezone}
          hideRemoveOrgModal={() => setShowRemoveOrgModal(false)}
        />
      </SiteModal>

      {/* TODO : use later */}
      {/* <SiteModal
        modalTitle={`Are you sure you want to remove ${custPartnerOrgData?.orgName}?`}
        showModal={showRemovePartnerModal}
        hideModal={() => setShowRemovePartnerModal(false)}
      >
        All technicians will be removed. Your partner will no longer be able to
        support your Customer.
      </SiteModal> */}
    </div>
  );
}

export default OrganizationDashboard;
