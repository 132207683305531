import { useState, useEffect } from 'react';
import { useGlobalApi } from '../../services/hooks/useGlobalApi';
import { getOrgPolicies } from '../../services/api/accesspolicies';
import useRoutingApiPaths from '../useRoutingApiPaths';

const useGetOrgPolicies = () => {
  const { executeAsyncOperation } = useGlobalApi();
  const path = useRoutingApiPaths();

  const fetchOrgPolicies = async () => {
    const res = await executeAsyncOperation(
      getOrgPolicies,
      null,
      path.GET_ORG_POLICIES,
    );

    return res?.data?.data;
  };

  return { fetchOrgPolicies };
};

export default useGetOrgPolicies;
