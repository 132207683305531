import { useState } from 'react';
import { useGlobalApi } from '../../services/hooks/useGlobalApi';
import useRoutingApiPaths from '../useRoutingApiPaths';
import { useCardholderStore } from '../../store/cardHolderStore';
import {
  fetchSignedUrl,
  createAccountHolder,
} from '../../services/api/cardholders';
import { apiMessages } from '../../helpers';

const useGetCardHolder = () => {
  const [data, setData] = useState(null);
  const { executeAsyncOperation } = useGlobalApi();
  const path = useRoutingApiPaths();
  const {
    setCardHolder,
    setCardholders,
    updateCardHolder,
    setActivatingTodayCardHolders,
    setExpiredTodayCardHolders,
    setInactiveCardHolders,
    setMetaData,
    createCardHolder,
  } = useCardholderStore();

  const fetchCardHolders = async (query = null) => {
    const res = await executeAsyncOperation(
      setCardholders,
      null,
      `${path.CARDHOLDER.getCardHolders}?${query || ''}`,
    );
    return res?.data;
  };

  const fetchCardHolder = async (cardHolderId) => {
    const res = await executeAsyncOperation(
      setCardHolder,
      null,
      `${path.CARDHOLDER_API}/${cardHolderId}`,
    );
    setData(res?.data?.data);
  };

  const activatingSoonCardhHolders = async (startOfDay, endOfDay) => {
    const res = await executeAsyncOperation(
      setActivatingTodayCardHolders,
      null,
      path.CARDHOLDER.getActivatingTodayCardHolders(startOfDay, endOfDay),
    );
    return res?.data;
  };

  const expiringTodayCardhHolders = async (startDate, endDate) => {
    const res = await executeAsyncOperation(
      setExpiredTodayCardHolders,
      null,
      path.CARDHOLDER.getExpiringTodayCardHolders(startDate, endDate),
    );
    return res?.data;
  };

  const inactiveCardHolders = async (startDate) => {
    const res = await executeAsyncOperation(
      setInactiveCardHolders,
      null,
      path.CARDHOLDER.getOnlyInactive(startDate),
    );
    return res?.data;
  };

  const cardHolderMetaData = async () => {
    await executeAsyncOperation(
      setMetaData,
      null,
      path.CARDHOLDER.getCardHolderMetaData(),
    );
  };

  const getSignedUrl = async (cardHolderId) => {
    const res = await executeAsyncOperation(
      fetchSignedUrl,
      null,
      path.CARDHOLDER.getSignedUrlApiRoute(cardHolderId),
    );
    return res?.data?.data;
  };

  const editCardHolder = async (cardHolderId, cardholderData) => {
    const res = await executeAsyncOperation(
      updateCardHolder,
      apiMessages.CARDHOLDER_UPDATED,
      path.CARDHOLDER.updateCardHolder(cardHolderId),
      cardholderData,
    );

    return res;
  };

  const addCardHolder = async (cardholderData) => {
    const res = await executeAsyncOperation(
      createCardHolder,
      apiMessages.CARDHOLDER_CREATED,
      path.CARDHOLDER.createCardHolder,
      cardholderData,
    );
    return res;
  };

  const addAccountHolder = async (cardholderData) => {
    const res = await executeAsyncOperation(
      createAccountHolder,
      apiMessages.CARDHOLDER_CREATED,
      path.CARDHOLDER.createAccountHolder,
      cardholderData,
    );
    return res;
  };

  return {
    data,
    getSignedUrl,
    fetchCardHolder,
    fetchCardHolders,
    editCardHolder,
    activatingSoonCardhHolders,
    expiringTodayCardhHolders,
    inactiveCardHolders,
    cardHolderMetaData,
    addCardHolder,
    addAccountHolder,
  };
};
export default useGetCardHolder;
