import axios from 'axios';
import { Utils } from '../../../helpers';
import axiosClient from '../axiosClient';

const getPartnerPlatformV2Endpoint = (route) =>
  axios.get(route, { withCredentials: true, ...Utils.requestHeader() });

const getManufacturerSkuBanksEndpoint = (route) =>
  axiosClient.get(route, Utils.requestHeader());

const getManufacturerSkuBanksRefreshEndpoint = (route) =>
  axiosClient.get(route, Utils.requestHeader());

export {
  getPartnerPlatformV2Endpoint,
  getManufacturerSkuBanksEndpoint,
  getManufacturerSkuBanksRefreshEndpoint,
};
