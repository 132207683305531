import { useState, useEffect, useCallback } from 'react';
import {
  useLocation,
  useNavigate,
  useSearchParams,
  NavLink,
} from 'react-router-dom';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import axios from 'axios';
import LocationSelector from './LocationSelector';
import {
  constants,
  Utils,
  ManageMqttData,
  AppDefaults,
  roles,
} from '../../helpers';
import { ReactComponent as BrandLogoSvg } from '../../assets/images/BrandLogo.svg';
import { IconContext } from 'react-icons';
import {
  HiOutlineOfficeBuilding,
  HiOutlineBell,
  HiOutlineUser,
  HiOutlineLogout,
  HiOutlineShoppingCart,
  HiOutlineLockClosed,
} from 'react-icons/hi';
import { TbSettings } from 'react-icons/tb';
import { useKeycloak } from '@react-keycloak/web';
import { useCart } from '../../store/CartStore';
import { useOrganizations } from '../../store/OrganizationsStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { usePoliciesStore } from '../../store/policiesStore';
import DefaultPolicies from '../../store/reducers/DefaultPolicies';
import {
  clearLiveStreams,
  clearMQTTPeerConnections,
  clearRTCPeerConnections,
  clearRemoteStreams,
  clearRemoteStreamsAudio,
  clearWSSConnections,
  getMQTTConnection,
  getPlatformInfo,
  getWSSConnection,
  resetCDNInfo,
} from '../../store/reducers/StreamingReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  setAccountId,
  getMqttUpdateStatus,
  getAllMqttDataFromResponse,
  setMqttUpdateStatusFromResponse,
  getAccountId,
  getAllAreas,
  setAllAreas,
  setSubscribeAreasRequest,
  getSubscribeAreasRequest,
  setKeepAliveRequest,
  getKeepAliveRequest,
} from '../../store/reducers/AccountReducer';
import {
  checkMQTTConnection,
  connectWithMQTT,
  mqttDisconnectRequest,
  mqttPublish,
  mqttSubscribe,
  subscribeWithAccountId,
  subscribeWithOrgIds,
} from '../../utils/connection/mqttConnection';
import { connectWithWebSocket } from '../../utils/connection/wssConnection';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { devicesMQTTStore } from '../../store/DevicesMQTTStore';
import { v4 as uuidv4 } from 'uuid';
import { useNotificationStore } from '../../store/CreateNotificationStore';
import useEventsStore from '../../store/EventsStore';
import { useCustomerOrgDevices } from '../../store/CustomerOrgDevicesStore';
import { useModuleStore } from '../../store/ModuleStatusStore';
import { ReactComponent as MegatronLogoSvg } from '../../assets/images/MegatronLogo.svg';
import { IncidentTypeEnum } from '../../helpers/enums';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { useCustOrgLocationArea } from '../../store/CustOrgLocationAreaStore';
import useEventSelectionStore from '../../store/EventSelectionStore';
import { app_version } from '../../helpers/commonUtils';

function Header({
  backgroundColor,
  numOfICartItems,
  showCart = true,
  showNavigation = true,
  isAccountScreen = false,
  cartFetch = true,
  breadcrumbData = [],
  currentDeviceId = false,
  showHeader = true,
  onPushReceive,
  isFromVideoWall = false,
  isFromVerifyAccount = false,
}) {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();
  const location = useLocation();
  const [dynamicApp, setDynamicApp] = useState(null);
  const [searchParams] = useSearchParams();
  const details = navigator.userAgent;
  const regexp = /android|iphone|kindle|ipad/i;
  const isMobileDevice = regexp.test(details);
  const getModuleStatus = useModuleStore((state) => state.getModuleStatus);
  const setModuleStatus = useModuleStore((state) => state.setModuleStatus);
  setModuleStatus(Utils.getModuleStatus());
  const moduleStatus = getModuleStatus();
  const dispatch = useDispatch();
  const setPingApiCallTime = useLoggedInUserData(
    (state) => state.setPingApiCallTime,
  );
  // TODO: read these two from the store that has them
  let orgId = searchParams.get('orgId');
  let orgName = searchParams.get('orgName');
  const [url, setUrl] = useState(null);
  const [hasNotification, setHasNotification] = useState(false);
  const [numOfCartItems, setNumOfCartItems] = useState(numOfICartItems);
  const [custOrgData, setCustOrgData] = useState([]);
  const [areasFinal, setAreasFinal] = useState([]);
  const [organizationId, setOrganizationId] = useState(null);
  const navigate = useNavigate();
  const mqttConnection = useSelector(getMQTTConnection);
  const wssConnection = useSelector(getWSSConnection);
  const accountId = useSelector(getAccountId);
  const { resetLiveViewDevices } = useCustomerOrgDevices();
  //=== Store get/set actions
  const cart = useCart((state) => state.cart);
  let pendingOrder = useCart((state) => state.isOrderPending);
  const getCartTotalQuantity = useCart((state) => state.getCartTotalQuantity);
  const deleteCart = useCart((state) => state.deleteCart);
  const deleteOrder = useCart((state) => state.deleteOrder);
  const deleteOrgId = useCart((state) => state.deleteOrgId);
  const setOrderPending = useCart((state) => state.setOrderPending);
  const setOrder = useCart((state) => state.setOrder);
  const setOrgId = useCart((state) => state.setOrgId);

  //=== Store get/set actions
  const mqttUpdateStatus = useSelector(getMqttUpdateStatus);
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  const getMQTTConnectionStatus = useSelector(getMQTTConnection);
  const getPlatformDetails = useSelector(getPlatformInfo);
  const getSubscribeAreas = useSelector(getSubscribeAreasRequest);
  const getKeepAlive = useSelector(getKeepAliveRequest);
  const custOrgId = orgId || organizationId || custOrgData?.[0]?.orgId;
  const allAreasStore = useSelector(getAllAreas);

  const { setNavigatedFromCameraDetail } = useEventSelectionStore();

  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData,
  );

  const setCustomerOrgData = useOrganizations(
    (state) => state.setCustomerOrgData,
  );
  const customerOrgData = useOrganizations((state) => state.customerOrgData);
  const resetCustomerOrgData = useOrganizations(
    (state) => state.resetCustomerOrgData,
  );
  const resetSelfAccount = useOrganizations((state) => state.resetSelfAccount);
  const resetCustomerOrgLocations = useCustomerOrgLocations(
    (state) => state.resetCustomerOrgLocations,
  );
  const resetDeviceWallSelectedArea = useCustOrgLocationArea(
    (state) => state.resetDeviceWallSelectedArea,
  );
  const resetDeviceWallSelectedLocation = useCustOrgLocationArea(
    (state) => state.resetDeviceWallSelectedLocation,
  );
  const resetLoggedInUserData = useLoggedInUserData(
    (state) => state.resetLoggedInUserData,
  );
  const setLoggedInUserData = useLoggedInUserData(
    (state) => state.setLoggedInUserData,
  );
  const resetCameraWallDevices = useCustomerOrgDevices(
    (state) => state.resetCameraWallDevices,
  );
  const setSelfStatus = useOrganizations((state) => state.setSelfStatus);
  const getSelfStatus = useOrganizations((state) => state.getSelfStatus);
  const loggedInUserRole = useOrganizations((state) => state.loggedInUserRole);

  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData,
  );
  const getLoggedInUserPolicies = usePoliciesStore(
    (state) => state.getLoggedInUserPolicies,
  );
  const setCustomerOrgPolicies = usePoliciesStore(
    (state) => state.setCustomerOrgPolicies,
  );
  const resetNotificationData = useNotificationStore(
    (state) => state.resetStepData,
  );
  const getSelfAccountData = useOrganizations(
    (state) => state.getSelfAccountData,
  );
  const setUserPushData = useOrganizations((state) => state.setUserPushData);

  const { getState } = devicesMQTTStore;
  const state = getState();
  const { setSelectedEventStore, setEventFromSearch, setEventCVRMode } =
    useEventsStore();
  const [selfStatus, setSelfStatusFlag] = useState(getSelfStatus());

  useEffect(() => {
    dispatch(setKeepAliveRequest(false));
    if (Utils.detectBrowser() && !isAccountScreen) {
      localStorage.setItem('vmsAuthToken', null);
      if (process.env.REACT_APP_HIDE_LOGIN === 'true') {
        navigate(`/login`);
      } else {
        navigate(`/`);
      }
    }

    //TODO: This commented code is under inspection. Do not remove.
    // const tokenVms = localStorage.getItem('vmsAuthToken');
    // setTimeout(() => {
    //   if (
    //     (tokenVms == 'null' || tokenVms == 'undefined') &&
    //     !isFromVerifyAccount &&
    //     !isAccountScreen
    //   ) {
    //     if (process.env.REACT_APP_HIDE_LOGIN === 'true') {
    //       navigate(`/login`);
    //     } else {
    //       navigate(`/`);
    //     }
    //   }
    // }, 3000);

    if (
      (loggedInUserRole === roles.ROLE2VMS ||
        loggedInUserRole === roles.ROLE3VMS) &&
      !isMobileDevice &&
      moduleStatus?.NOTIFICATION
    ) {
      const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
      };
      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      const messaging = getMessaging(app);

      onMessage(messaging, (payload) => {
        //TODO Delete later
        if (payload?.data?.['pinpoint.notification.title']) {
          const notificationTitle =
            payload?.data?.['pinpoint.notification.title'];
          const notificationData = JSON.parse(
            payload?.data?.['pinpoint.jsonBody'],
          );
          const notificationOptions = {
            body: payload?.data?.['pinpoint.notification.body'],
            tag: payload?.data?.id,
            url: payload?.data?.url,
            icon: notificationData?.imgURL,
          };
          var notification = new Notification(
            notificationTitle,
            notificationOptions,
          );
          notification.onclick = function (event) {
            event?.preventDefault();
            if (
              notificationData?.nType === IncidentTypeEnum?.NOTIFICATION_TYPE
            ) {
              navigate(`/notificationdashboard.html`);
            } else if (localStorage.getItem('tokenSend')) {
              setUserPushData({});
              if (window.location.pathname === '/cameras/video-wall.html') {
                isFromVideoWall && onPushReceive(notificationData);
              } else if (
                window.location.pathname === '/cameras/dashboard.html'
              ) {
                setSelectedEventStore(
                  parseFloat(notificationData?.eventTimestamp),
                );
                setEventFromSearch(true);
                setEventCVRMode(true);
              } else {
                navigate(
                  `/cameras/video-wall.html?orgId=${notificationData?.orgId}&fromPush=true`,
                  {
                    state: {
                      notificationData: notificationData,
                    },
                  },
                );
              }
            } else {
              setUserPushData(notificationData);
            }
            window?.focus();
            notification?.close();
          };
        } else {
          const notificationTitle = payload.notification.title;
          const notificationOptions = {
            body: payload.notification.body,
            icon: payload.notification.icon,
          };
          new Notification(notificationTitle, notificationOptions);
        }
      });
      setDynamicApp(app);
    }
  }, []);

  useEffect(() => {
    if (
      dynamicApp &&
      (loggedInUserRole === roles.ROLE2VMS ||
        loggedInUserRole === roles.ROLE3VMS) &&
      localStorage.getItem('tokenSend') === null &&
      !isMobileDevice &&
      moduleStatus?.NOTIFICATION
    ) {
      Notification.requestPermission().then((permission) => {
        if (permission?.toUpperCase() === 'GRANTED') {
          try {
            const messaging = getMessaging(dynamicApp);
            setTimeout(() => {
              getToken(messaging, {
                vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
              }).then((currentToken) => {
                if (
                  localStorage.getItem('deviceToken') === null ||
                  localStorage.getItem('deviceToken') === undefined
                ) {
                  localStorage.setItem('deviceToken', Utils.generateUUID());
                }
                if (
                  currentToken &&
                  getCustomerOrgData()[0]?.orgId !== undefined
                ) {
                  sendToken(currentToken);
                }
              });
            }, 5000);
          } catch (error) {
            console.error(error);
          }
        }
      });
    }
  }, [dynamicApp]);

  const sendToken = async (token) => {
    const tokenWeb = localStorage.getItem('vmsAuthToken');
    if (!tokenWeb || tokenWeb === 'undefined') {
      return;
    }
    const params = {
      enabled: true,
      name: navigator?.userAgentData?.platform,
      osVersion: navigator?.platform,
      platform: 'WEB',
      regToken: token,
      udid: `${localStorage.getItem('deviceToken') + '#acs'}`,
    };
    axios
      .put(
        `/partner/orgs/${getCustomerOrgData()[0]?.orgId}/userDevices`,
        params,
        Utils.requestHeader().headers,
      )
      .then((res) => {
        localStorage.setItem('tokenSend', true);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const getPOCartItems = async () => {
      if (!cartFetch) {
        // Clear the cart and order
        deleteCart();
        deleteOrder();
        deleteOrgId();
        setOrderPending(false);
        setNumOfCartItems(0);
        return;
      }
      // Get current purchase order
      const resp = await axios.get(
        `/user/orgs/${orgId}/orders`,
        Utils.requestHeader(),
      );
      const response = resp?.data;

      if (parseInt(response?.meta?.code) === 200) {
        const orderStatus = response?.data?.orderStatus;

        if (
          orderStatus === 'PENDING_FULFILLMENT' ||
          orderStatus === 'FULFILLED'
        ) {
          // Clear the cart and order
          deleteCart();
          deleteOrder();
          deleteOrgId();
          setOrderPending(false);
          setNumOfCartItems(0);
        } else {
          setOrderPending(true);
        }
      }
    };

    if (orgId && showCart) {
      getPOCartItems();
    } else {
      setCustOrgData([...getCustomerOrgData()]);
      setOrganizationId(custOrgData.orgId);
    }
  }, []);

  useEffect(() => {
    if (
      Array.isArray(getCustomerOrgData()) &&
      getCustomerOrgData().length > 0 &&
      getMQTTConnectionStatus
    ) {
      subscribeWithOrgIds(getCustomerOrgData());

      const deviceSubscription = {
        topic: `d/notify/${accountId}/${state.getSessionId()}`,
        qos: 0,
      };

      // Subscribe  to the device topic
      mqttSubscribe(deviceSubscription);
      const devices = getCustomerOrgData()[0]?.devices;

      const areas = [...new Set(devices?.map((obj) => obj.areaId))];
      dispatch(setAllAreas(areas));
      if (!state.getSessionId()) {
        state.setSessionId(uuidv4());
      }
    }
    if (loggedInUserData) {
      subscribeWithAccountId(loggedInUserData?.accountId);
      dispatch(setAccountId(loggedInUserData?.accountId));
    }
  }, [getMQTTConnectionStatus, loggedInUserData]);

  // TODO: Below code is in observation
  useEffect(() => {
    const tokenWeb = localStorage.getItem('vmsAuthToken');
    if (!tokenWeb || tokenWeb === 'undefined') {
      return;
    }
    setTimeout(() => {
      if (!checkMQTTConnection() && accountId) {
        connectWithMQTT(accountId);
      }
    }, 1500);
  }, [accountId, checkMQTTConnection()]);

  // TODO: WILL REMOVE LATER useEffect(() => {
  //   if (!wssConnection && getPlatformDetails) {
  //     connectWithWebSocket(getPlatformDetails);
  //   }
  // }, [getPlatformDetails, wssConnection]);

  useEffect(() => {
    if (numOfICartItems === null || numOfICartItems === undefined) {
      let currentNumOfCartItems = getCartTotalQuantity();

      if (currentNumOfCartItems > 0) {
        setNumOfCartItems(currentNumOfCartItems);
      }
    } else {
      setNumOfCartItems(numOfICartItems);
    }
  }, [getCartTotalQuantity, numOfICartItems, orgId]);

  useEffect(() => {
    let requestUrl = location.pathname.replace(/\/+$/, '');
    setUrl(requestUrl);
  }, [location]);

  useEffect(() => {
    if (getSelfAccountData()?.orgType) {
      setLoggedInUserData(getSelfAccountData());
    }
  }, [getSelfAccountData()]);

  //=== Update the device data based on MQTT response data
  useEffect(() => {
    let modalStatus = parseInt(localStorage.getItem('modalOpen'));
    if (allMqttData !== null && mqttUpdateStatus && modalStatus !== 1) {
      //=== Update Customer Org Page Devices Data
      if (getCustomerOrgData().length > 0) {
        let updateCustData = ManageMqttData.updateCustomerOrgDevicesDataByMqtt(
          getCustomerOrgData(),
          allMqttData,
        );

        setCustomerOrgData(updateCustData);
      }
      //=== Update Devices Data
      ManageMqttData.updateDeviceDataByMqtt(allMqttData, currentDeviceId);
      dispatch(setMqttUpdateStatusFromResponse(false));
    }
  }, [allMqttData, mqttUpdateStatus]);

  const invalidateSessionApi = () => {
    const reqBody = {
      refresh_token: keycloak?.refreshToken,
    };
    axios
      .post('/user/token/invalidate/app', reqBody, Utils.requestHeader())
      .then(() => {
        handleSiteLogout();
      });
  };

  /* On click submit button - send request to approve the customer accounts API */
  const handleSiteLogout = (e) => {
    setSelfStatus('0');
    dispatch(clearWSSConnections());
    dispatch(clearRTCPeerConnections());
    dispatch(clearLiveStreams());
    dispatch(clearMQTTPeerConnections());
    dispatch(clearRemoteStreams());
    dispatch(clearRemoteStreamsAudio());
    dispatch(resetCDNInfo());
    resetLiveViewDevices();
    localStorage.setItem('authenticated', false);
    localStorage.setItem('vmsAuthToken', null);
    localStorage.removeItem('tokenSend');
    localStorage.setItem('isAmplitudeSingleCall', false);
    localStorage.setItem('mqttSubcribed', '0');
    resetCustomerOrgData();
    resetNotificationData();
    resetCustomerOrgLocations();
    resetCameraWallDevices();
    resetLoggedInUserData();
    resetDeviceWallSelectedLocation();
    resetDeviceWallSelectedArea();
    resetSelfAccount();

    keycloak.logout({
      redirectUri: process.env.REACT_APP_KEYCLOAK_LOGOUT_REDIRECT_URL,
    });
    setPingApiCallTime(0);
    if (allAreasStore) {
      dispatch(setAllAreas([]));
    }

    dispatch(setSubscribeAreasRequest(false));
    dispatch(setKeepAliveRequest(false));
    mqttDisconnectRequest();
  };

  // TODO: move this in the component that calls this Header component
  // and define it as a prop for Header
  const createPurchaseOrder = async () => {
    const token = localStorage.getItem('vmsAuthToken');
    if (!token) {
      return;
    }
    try {
      let order = [];
      let poRes, response;

      cart.forEach((license) => {
        order.push({
          productSku: license.sku,
          price: license.discountedPrice,
          quantity: license.quantity,
        });
      });

      const reqBody = {
        currency: 'usd',
        orderDetails: [...order],
      };

      // Check
      if (pendingOrder) {
        poRes = await axios.put(
          `/user/orgs/${orgId}/orders`,
          reqBody,
          Utils.requestHeader(),
        );
      } else {
        // create purchase order
        poRes = await axios.post(
          `/user/orgs/${orgId}/orders`,
          reqBody,
          Utils.requestHeader(),
        );
      }

      response = poRes.data;

      if (parseInt(response?.meta?.code) === 200) {
        setOrgId(orgId);
        setOrder(response.data);
        navigate(`/purchase/summary.html?orgId=${orgId}&orgName=${orgName}`);
      }
    } catch (error) {
      /*
       * TODO: figure out what to do with the error because the Header
       * component should not issue a toaster component
       */
      console.log(error);
    }
  };

  //=== Check if Customers exists in the breadcrumb then make the customer nav link active
  const checkBreadcrumbForCustomers = () => {
    let breadExists = false;
    if (Array.isArray(breadcrumbData) && breadcrumbData.length > 0) {
      breadExists = !!breadcrumbData.find(
        (breadData) => breadData.title === constants.MANAGE_ORG_PAGE_TITLE,
      );
    }
    return breadExists;
  };

  useEffect(() => {
    setSelfStatusFlag(getSelfStatus());
  }, [getSelfStatus()]);

  useEffect(() => {
    let policies = getCustomerOrgData()[0]?.policies;
    let updatedPoliciesData = Utils.mapUserPolicies(DefaultPolicies, policies);

    setCustomerOrgPolicies(updatedPoliciesData);
  }, [getCustomerOrgData, customerOrgData]);

  const setNavLinkClasses = (isActive) => {
    let classes = '';
    if (isActive) {
      classes = 'active nav-link';
    } else {
      classes = 'left-link nav-link';
    }
    if (loggedInUserData?.accountStatus === 'INACTIVE') {
      classes = `${classes} disabled`;
    }
    return classes;
  };

  return (
    showHeader && (
      <Navbar
        collapseOnSelect
        sticky="top"
        expand="lg"
        className={`site-top-bar ${selfStatus !== '1' && 'disabled'}`}
        style={{ backgroundColor: backgroundColor }}
      >
        <Container className="site-top-bar-container" fluid>
          <Navbar.Brand
            href={`${
              process.env.REACT_APP_PROJECT !== AppDefaults.PROJECT_MEGATRON
                ? '/customers/manage.html'
                : ''
            }`}
          >
            {/* {process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON ? (
              <MegatronLogoSvg />
            ) : (
              <BrandLogoSvg />
            )} */}
            {t('APPLICATION_NAME')}
          </Navbar.Brand>
          {showNavigation && (
            <>
              <Nav className="me-auto site-start-links">
                {loggedInUserRole !== roles.ROLE2VMS &&
                  loggedInUserRole !== roles.ROLE3VMS && (
                    <>
                      <NavLink
                        to={'/customers/manage.html'}
                        className={({ isActive }) =>
                          setNavLinkClasses(
                            isActive ||
                              location.pathname.startsWith('/customers/'),
                          )
                        }
                      >
                        {constants.TOP_HEADER_CUSTOMERS_NAV_TITLE}
                      </NavLink>
                    </>
                  )}
                {loggedInUserRole === roles.ROLE4VMS &&
                  getLoggedInUserPolicies()?.create_account && (
                    <NavLink
                      to={'/managepartnerusers.html'}
                      className={({ isActive }) => setNavLinkClasses(isActive)}
                    >
                      {constants.TOP_HEADER_USERS_NAV_TITLE}
                    </NavLink>
                  )}
                {(loggedInUserRole === roles.ROLE2VMS ||
                  loggedInUserRole === roles.ROLE3VMS) && (
                  <>
                    <NavLink
                      to={`/cameras/devices.html`}
                      className={({ isActive }) =>
                        setNavLinkClasses(
                          isActive ||
                            location.pathname.startsWith(
                              '/cameras/devices.html',
                            ),
                        )
                      }
                    >
                      {/* {constants.TOP_HEADER_DEVICE_WALL_NAV_TITLE} */}
                    </NavLink>
                    {moduleStatus?.EVENTS && (
                      <Nav.Link
                        onClick={() => {
                          setNavigatedFromCameraDetail(false);
                          navigate('/events.html');
                        }}
                        className={`${
                          url === '/events.html' ? 'active' : 'left-link'
                        } ${
                          loggedInUserData?.accountStatus === 'INACTIVE' &&
                          'disabled'
                        }`}
                      >
                        {/* {constants.TOP_HEADER_EVENTS_NAV_TITLE} */}
                      </Nav.Link>
                    )}
                  </>
                )}
                {(getLoggedInUserPolicies()?.view_all_incident ||
                  getLoggedInUserPolicies()?.view_created_incident ||
                  getLoggedInUserPolicies()?.view_shared_incident) &&
                  moduleStatus?.INCIDENT_MANAGEMENT && (
                    <NavLink
                      to={'/clips/list.html'}
                      className={({ isActive }) =>
                        setNavLinkClasses(
                          isActive || location.pathname.startsWith('/clips/'),
                        )
                      }
                    >
                      {/* {constants.TOP_HEADER_CLIPS_NAV_TITLE} */}
                    </NavLink>
                  )}
                {(getLoggedInUserPolicies()?.view_all_incident ||
                  getLoggedInUserPolicies()?.view_created_incident ||
                  getLoggedInUserPolicies()?.view_shared_incident) &&
                  moduleStatus?.INCIDENT_MANAGEMENT && (
                    <NavLink
                      to={'/incidents/manage.html'}
                      className={({ isActive }) =>
                        setNavLinkClasses(
                          isActive ||
                            location.pathname.startsWith('/incidents/'),
                        )
                      }
                    >
                      {/* {constants.TOP_HEADER_INCIDENTS_NAV_TITLE} */}
                    </NavLink>
                  )}
                {(loggedInUserRole === roles.ROLE2VMS ||
                  loggedInUserRole === roles.ROLE3VMS) &&
                  moduleStatus?.OPERATION_METRICES && (
                    <NavLink
                      to={'/analytics.html'}
                      className={({ isActive }) => setNavLinkClasses(isActive)}
                    >
                      {/* {constants.TOP_HEADER_ANALYTICS_NAV_TITLE} */}
                    </NavLink>
                  )}
              </Nav>
              <Nav className={`site-end-links`}>
                {/*TODO DELETE LATER {(loggedInUserRole === roles.ROLE2VMS ||
                  loggedInUserRole === roles.ROLE3VMS) && <LocationSelector />} */}
                {showCart &&
                moduleStatus?.PRODUCT_LISTING &&
                !isNaN(numOfCartItems) &&
                numOfCartItems > 0 ? (
                  <Nav.Link
                    href="#"
                    className={numOfCartItems < 1 ? 'hidden' : 'right-link'}
                    onClick={() => createPurchaseOrder()}
                  >
                    {/* <div className="site-end-links-cart">
                      <IconContext.Provider
                        value={{
                          size: '0.5',
                          className: 'site-end-links-cart-icon',
                        }}
                      >
                        <HiOutlineShoppingCart size={0.5} />
                      </IconContext.Provider>
                      <div className="site-end-links-cart-items">
                        ({numOfCartItems})
                      </div>
                    </div> */}
                  </Nav.Link>
                ) : null}
                {(loggedInUserRole === roles.ROLE2VMS ||
                  loggedInUserRole === roles.ROLE3VMS) && (
                  <NavLink
                    className={({ isActive }) =>
                      `${setNavLinkClasses(
                        isActive ||
                          location.pathname.startsWith('/notification'),
                      )} right-link`
                    }
                    to={'/notificationdashboard.html'}
                  >
                    {/* <div className="profile-nav-icon notification-background">
                      {hasNotification && (
                        <div className="has-notification"></div>
                      )}
                      <HiOutlineBell size={18} />
                    </div> */}
                  </NavLink>
                )}
                {getLoggedInUserPolicies()?.view_cust_org_dashboard &&
                  loggedInUserData?.orgType ===
                    AppDefaults.ORG_TYPE_CUSTOMER && (
                    <Nav.Link
                      onClick={() => {
                        if (
                          Array.isArray(getCustomerOrgData()) &&
                          getCustomerOrgData()[0]
                        ) {
                          let policies = getCustomerOrgData()[0]?.policies;
                          let updatedPoliciesData = Utils.mapUserPolicies(
                            DefaultPolicies,
                            policies,
                          );
                          setCustomerOrgPolicies(updatedPoliciesData);
                        }
                        navigate(
                          `/customers/dashboard.html?orgId=${
                            getCustomerOrgData()[0]?.orgId
                          }&orgName=${getCustomerOrgData()[0]?.orgName}`,
                        );
                      }}
                      className={`right-link ${
                        location.pathname.startsWith('/customers/') && 'active'
                      } ${
                        loggedInUserData?.accountStatus === 'INACTIVE' &&
                        'disabled'
                      }`}
                    >
                      {/* <div className={`profile-nav-icon`}>
                        <HiOutlineOfficeBuilding size={18} />
                      </div> */}
                    </Nav.Link>
                  )}
                <NavDropdown
                  className="profile-nav right-link"
                  title={
                    <div className="profile-nav-icon">
                      <TbSettings size={18} />
                    </div>
                  }
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item
                    href="#"
                    className="logged-in-user right-link"
                  >
                    <div className="loggedin-user-as">
                      Signed in as
                      <div className="loggedin-user-title">
                        {`${
                          loggedInUserData?.email ? loggedInUserData?.email : ''
                        }`}
                      </div>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className={`${
                      location.pathname.startsWith('/accountsettings') &&
                      'active'
                    } ${
                      loggedInUserData?.accountStatus === 'INACTIVE' &&
                      'inactive-user-setting'
                    }`}
                    onClick={() => navigate('/accountsettings.html')}
                  >
                    <span className="icon">
                      <HiOutlineUser />
                    </span>
                    {constants.TOP_HEADER_ACCOUNT_SETTINGS_NAV_TITLE}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className={`${
                      location.pathname.startsWith('/organizationsettings') ||
                      location.pathname.startsWith('/editOrganization')
                        ? 'active'
                        : ''
                    } ${
                      loggedInUserData?.accountStatus === 'INACTIVE' &&
                      'inactive-user-setting'
                    }`}
                    onClick={() => {
                      if (loggedInUserData?.orgType === 'CUSTOMER_ORG') {
                        navigate(
                          `/editOrganization.html?orgId=${loggedInUserData?.orgId}`,
                        );
                      } else {
                        navigate('/organizationsettings.html');
                      }
                    }}
                  >
                    <span className="icon">
                      <TbSettings />
                    </span>
                    {constants.TOP_HEADER_ORG_SETTINGS_NAV_TITLE}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className={`${
                      loggedInUserData?.accountStatus === 'INACTIVE' &&
                      'inactive-user-setting'
                    }`}
                    onClick={() => navigate('/updatepassword.html')}
                  >
                    <span className="icon">
                      <HiOutlineLockClosed />
                    </span>
                    {constants.TOP_HEADER_UPDATE_PASSWORD_NAV_TITLE}
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={invalidateSessionApi}>
                    <span className="icon">
                      <HiOutlineLogout />
                    </span>
                    {constants.TOP_HEADER_LOGOUT_SETTINGS_NAV_TITLE}
                  </NavDropdown.Item>
                  <p className="version-info">
                    <span className="title">
                      {constants.RELEASE_VERSION_TITLE}
                    </span>
                    <span>{app_version}</span>
                  </p>
                </NavDropdown>
              </Nav>
            </>
          )}
        </Container>
      </Navbar>
    )
  );
}

Header.defaultProps = {
  backgroundColor: getComputedStyle(document.documentElement).getPropertyValue(
    '--brand_white',
  ),
};

export default Header;
