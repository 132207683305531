import { create } from 'zustand';
import { Utils, roles } from '../helpers';
import axios from 'axios';
import axiosClient from '../services/api/axiosClient';
import { getOrganizationRoles } from '../services/api/organizations';

const useCustomRoles = create((set, get) => ({
  roleList: [],
  entitlementData: [],
  usersData: [],
  selectedEntitlement: [],
  selectedUsers: [],
  selectedCustomRole: {},
  organizationRoles: [],

  getCustomRoleList: async (route) => {
    try {
      if (!route) return;

      const res = await axiosClient.get(route, {
        ...Utils.requestHeader(),
      });

      const response = res.data;

      if (response.meta.code === 200) {
        const defaultRoles = [];
        const customRole = [];
        response?.data?.forEach((role) => {
          if (role.roleId !== roles.ROLE1VMS) {
            if (!role.customRole) {
              defaultRoles.push(role);
            } else {
              customRole.push(role);
            }
          }
        });

        customRole.sort(function (a, b) {
          if (a.roleName.toLowerCase() < b.roleName.toLowerCase()) {
            return -1;
          }
          if (a.roleName.toLowerCase() > b.roleName.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        const mergedArr = defaultRoles.concat(customRole);
        set({
          roleList: mergedArr,
        });
        return mergedArr;
      } else {
        let result;
        if (res?.code) {
          result = { status: 'failure', msg: `${res.code}: ${res.message}` };
        } else if (response?.data) {
          result = { status: 'failure', msg: response?.data?.userMsg };
        } else {
          result = { status: 'failure', msg: 'Unknown internal API error' };
        }
        return result;
      }
    } catch (err) {
      console.log('error', err);
    }
  },

  getCustomRoleEntitlements: async (route) => {
    try {
      if (!route) return;

      const res = await axios.get(route, {
        ...Utils.requestHeader(),
      });
      const response = res.data;
      if (response.meta.code === 200) {
        set({
          entitlementData: response.data.groupPolicies,
        });
        return response;
      } else {
        let result;
        if (res?.code) {
          result = { status: 'failure', msg: `${res.code}: ${res.message}` };
        } else if (response?.data) {
          result = { status: 'failure', msg: response?.data?.userMsg };
        } else {
          result = { status: 'failure', msg: 'Unknown internal API error' };
        }
        return result;
      }
    } catch (err) {
      console.log('error', err);
    }
  },

  getCustomRoleDetailWithEntitlement: async (route) => {
    try {
      if (!route) return;

      const res = await axios.get(route, {
        ...Utils.requestHeader(),
      });

      const response = res.data;
      if (response.meta.code === 200) {
        const grpPolicies = [];
        if (response.data.groupPolicies.length > 0) {
          response.data.groupPolicies.forEach((entitlement) => {
            grpPolicies.push(entitlement.groupId);
          });
        }
        set({
          selectedEntitlement: grpPolicies,
        });
        return grpPolicies;
      } else {
        let result;
        if (res?.code) {
          result = { status: 'failure', msg: `${res.code}: ${res.message}` };
        } else if (response?.data) {
          result = { status: 'failure', msg: response?.data?.userMsg };
        } else {
          result = { status: 'failure', msg: 'Unknown internal API error' };
        }
        return result;
      }
    } catch (err) {
      console.log('error', err);
    }
  },

  getCustomRoleDetailWithUser: async (route) => {
    try {
      if (!route) return;

      const res = await axios.get(route, {
        ...Utils.requestHeader(),
      });

      const response = res.data;
      if (response.meta.code === 200) {
        const users = [];
        if (response.data.accounts.length > 0) {
          response.data.accounts.forEach((user) => {
            users.push(user.accountId);
          });
        }
        set({
          selectedUsers: users,
        });
        return users;
      } else {
        let result;
        if (res?.code) {
          result = { status: 'failure', msg: `${res.code}: ${res.message}` };
        } else if (response?.data) {
          result = { status: 'failure', msg: response?.data?.userMsg };
        } else {
          result = { status: 'failure', msg: 'Unknown internal API error' };
        }
        return result;
      }
    } catch (err) {
      console.log('error', err);
    }
  },

  getAllOrganizationRoles: () => get().organizationRoles,
  setSelectedCustomRole: async (data) => {
    set({ selectedCustomRole: data });
  },

  setOrganizationRoles: async (route) => {
    const res = await getOrganizationRoles(route);
    const response = res.data;
    if (response.meta.code === 200) {
      set({ organizationRoles: response.data });
    }
  },
}));

export default useCustomRoles;
