// All Global Constants and Enum Types should be stored here
const AppDefaults = {
  ALL_LOCATIONS_ID: 'Location0',
  ERR_CANCELED: 'ERR_CANCELED',
  CAMERA_VIDEO_TIMELINE_MAX_DURATION_IN_SECONDS: 300,
  // Min Duration: 10 seconds
  INCIDENT_EVIDENCE_CLIP_MIN_DURATION_IN_SECONDS: 10,
  // Max Duration: 240 seconds
  INCIDENT_EVIDENCE_CLIP_MAX_DURATION_IN_SECONDS: 322,
  // Default Duration: 30 seconds
  INCIDENT_EVIDENCE_CLIP_DEFAULT_DURATION_IN_SECONDS: 30,
  // Width of clipper handle in pixels
  INCIDENT_EVIDENCE_CLIP_HANDLE_WIDTH: 7,
  INCIDENT_EVIDENCE_SUPPORTED_FILE_EXTENSIONS:
    '.pdf,.doc,.docx,.jpeg,.jpg,.png,.raw,.mp4,.zip',
  INCIDENT_EVIDENCE_FILE_MAX_SIZE: 10,
  INCIDENT_DATE_FORMAT: 'MMM Do YYYY',
  INCIDENT_EVIDENCE_NOTE_DATE_FORMAT: 'D MMM, YYYY HH:mm A',
  INCIDENT_EVIDENCE_NOTE_ORIGINAL_VIDEO_FILENAME_RETAINED: false,
  INCIDENT_FINAL_ANALYSIS_MAX_CHARACTERS: 1000,
  PROJECT_DUCLO: 'DUCLO',
  PROJECT_MEGATRON: 'MEGATRON',
  PROJECT_ACAAS: 'ACAAS',
  CAMERAS_VIDEO_WALL_MAX_VIEW_DEVICES_COUNT: 12,
  ORG_TYPE_CUSTOMER: 'CUSTOMER_ORG',
  ORG_TYPE_INSTALLER: 'INSTALLER_ORG',
  DEFAULT_TIMER_TIME: 1800000,
  ONEGB: 1024,
};

const DeviceTypeEnum = {
  NVR: 'NVR',
  IPCam: 'IPCAM',
};

const IncidentTypeEnum = {
  INCIDENT_VIDEO_CLIP: 'incident-video-clip-available',
  INCIDENT_ZIP_CLIP: 'incident-zip-available',
  NOTIFICATION_TYPE: 'incident',
};

const DeviceStatusEnum = {
  NOT_AUTHENTICATED: 'Authenticate',
  AUTHENTICATED: 'Add Device',
  ALREADY_ASSIGNED: 'Already Assigned',
  ADDED_N_CLAIMED: 'Claimed',
  ADDED_NOT_CLAIMED: 'Pending Claimed',
  DEACTIVATED: 'Reclaim Device',
};

const ServiceTypeEnum = {
  VMS_SERVICE: 'vms-service',
  DM_PRO_SERVICE: 'dmpro-service',
  SIGHT_MIND_SERVICE: 'sightmind-service',
  SALESHUB_SERVICE: 'saleshub-service',
  CLOUD_SERVICE: 'oncloud-service',
  FLEXAI_SERVICE: 'flexai-service',
  CLOUD_STORAGE: 'cloud-storage',
};

const EntitlementsTypeEnum = {
  LIVE_VIEW: 'LIVE_VIEW',
  CLOUD_STORAGE_CVR: 'CLOUD_STORAGE_CVR',
};

const RolesEnum = {
  ROLE0VMS: 'role0#vms',
  ROLE1VMS: 'role1#vms',
  ROLE2VMS: 'role2#vms',
  ROLE3VMS: 'role3#vms',
  ROLE4VMS: 'role4#vms',
  ROLE5VMS: 'role5#vms',
};

export {
  AppDefaults,
  DeviceStatusEnum,
  DeviceTypeEnum,
  IncidentTypeEnum,
  RolesEnum,
  ServiceTypeEnum,
  EntitlementsTypeEnum,
};
