import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  nvrDeviceData: null,
  allScannedDevices: [],
  nvrScannedDevices: [],
  devicesListOfCurrOrg: [],
  manufacturerSKUList: [],
  addedAndClaimedDeviceId: null,
};

export const nvrDevice = createSlice({
  name: 'nvrDevice',
  initialState,
  reducers: {
    setNVRDeviceData: (state, action) => {
      state.nvrDeviceData = action.payload;
    },
    setAllScannedDevices: (state, action) => {
      state.allScannedDevices = action.payload;
    },
    setNVRScannedDevices: (state, action) => {
      state.nvrScannedDevices = action.payload;
    },
    setDevicesListOfCurrOrg: (state, action) => {
      state.devicesListOfCurrOrg = action.payload;
    },
    setManufacturerSKUList: (state, action) => {
      state.manufacturerSKUList = action.payload;
    },
    setAddedAndClaimedDeviceId: (state, action) => {
      state.addedAndClaimedDeviceId = action.payload;
    },
    resetData: (state) => {
      state.nvrDeviceData = null;
      state.nvrScannedDevices = [];
      state.devicesListOfCurrOrg = [];
    },
  },
});

export const {
  setNVRDeviceData,
  setAllScannedDevices,
  setNVRScannedDevices,
  setDevicesListOfCurrOrg,
  setManufacturerSKUList,
  setAddedAndClaimedDeviceId,
  resetData,
} = nvrDevice.actions;
export const getNVRDeviceData = (state) => state.nvrDevice.nvrDeviceData;
export const getAllScannedDevices = (state) =>
  state.nvrDevice.allScannedDevices;
export const getNVRScannedDevices = (state) =>
  state.nvrDevice.nvrScannedDevices;
export const getDevicesListOfCurrOrg = (state) =>
  state.nvrDevice.devicesListOfCurrOrg;
export const getManufacturerSKUList = (state) =>
  state.nvrDevice.manufacturerSKUList;
export const getAddedAndClaimedDeviceId = (state) =>
  state.nvrDevice.addedAndClaimedDeviceId;
export default nvrDevice.reducer;
