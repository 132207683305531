import * as mqtt from 'mqtt/dist/mqtt';
import * as webRTCHandler from '../webRTC/webRTCmqttHandler';
import * as webRTCmqttHandler from '../../pages/multilive/components/live/webRTCHandler/webRTCmqttHandler';
import store from '../../store/Store';
import {
  setSnapshotImage,
  setMQTTConnection,
  getPlatformInfo,
  getLiveCameraIds,
  setSnapshotImages,
  getIsOnMultiCameraViewPage,
  getStreamBitmapData,
  getStremBitmapsData,
} from '../../store/reducers/StreamingReducer';
import {
  setAllMqttDataFromResponse,
  setKeepAliveRequest,
  setMqttUpdateStatusFromResponse,
  setSubscribeAreasRequest,
} from '../../store/reducers/AccountReducer';
import { devicesMQTTStore } from '../../store/DevicesMQTTStore';

import { v4 as uuidv4 } from 'uuid';
import {
  setLatestEventsForDots,
  setMetaDataForEvents,
} from '../../store/reducers/EventsReducer';
import { getSelectedView } from '../../store/reducers/ViewsReducer';

let client;
const { getState } = devicesMQTTStore;
const state = getState();

export const connectWithMQTT = (accountId) => {
  const platformDetails = store.getState(getPlatformInfo)?.streaming?.platform;

  if (platformDetails) {
    if (!state.getSessionId()) {
      state.setSessionId(uuidv4());
    }

    client = mqtt.connect(
      
      `wss://${platformDetails?.mqtt?.wsHost}:${platformDetails?.mqtt?.wsPort}/mqtt`,
      {
        clientId: `u#web#${accountId}#${uuidv4()}`,
        username: accountId,
        password: platformDetails?.mqtt?.token,
        properties: {
          receiveMaximum: 10000,
          maximumPacketSize: 10000,
        },
      }
    );
    client.on('connect', () => {
      store.dispatch(setMQTTConnection(true));
    });

    // platformDetails?.mqtt?.topic_details?.subscribe?.notifies.forEach(
    //   (topic) => {
    //     client.subscribe(topic, function (err) {
    //       // TODO : change/delete this later
    //       if (err) console.error(err);
    //     });
    //   }
    // );

    client.on('message', function (topic, message) {
      const payload = JSON.parse(message.toString());
      const data = payload?.msg?.properties;
      const error = payload?.msg?.action;
      //TODO DELETE LATER
      // console.log("MqttResponse",payload)
      if (payload?.msg?.resource?.includes('camera/streaming')) {
        console.log(payload);
        const deviceId = payload?.msg?.resource.toString().split('/')[1];
        const selectedView = store.getState(getSelectedView).views.selectedView;
        const isOnDeviceWallPage =
          store.getState(getSelectedView).views.isOnDeviceWallPage;
        const isOnMultiCameraViewPage = store.getState(
          getIsOnMultiCameraViewPage
        )?.streaming?.isOnMultiCameraViewPage;
        if (isOnDeviceWallPage || isOnMultiCameraViewPage) {
          const devices =
            (isOnDeviceWallPage
              ? selectedView?.devices
              : store.getState(getPlatformInfo)?.streaming?.liveCameraIds) ||
            [];
          if (devices.length > 0) {
            switch (data.type) {
              case 'answer':
                devices?.includes(deviceId) &&
                  webRTCmqttHandler.handleAnswer(data, deviceId);
                break;
              case 'candidate':
                devices?.includes(deviceId) &&
                  webRTCmqttHandler.handleCandidate(data.candidate, deviceId);
                break;
              case 'leave':
                devices?.includes(deviceId) &&
                  webRTCmqttHandler.handleLeaveCall(deviceId);
                break;
              default:
                break;
            }
          }
        } else {
          console.log(data.type)
          switch (data.type) {
            case 'answer':
              webRTCHandler.handleAnswer(data);
              break;
            case 'candidate':
              webRTCHandler.handleCandidate(data.candidate);
              break;
            case 'leave':
              webRTCHandler.handleLeaveCall();
              break;
            default:
              break;
          }
        }
      } else if (
        payload?.msg?.resource?.includes('camera/last-snap-timestamp')
      ) {
        const accountId = payload.to;
        const sessionId = state.getSessionId();
        const deviceId = payload?.msg?.resource.toString().split('/')[1];
        if (payload.msg.properties) {
          const { tLastSnapshot } = payload.msg.properties;

          if (!isNaN(tLastSnapshot)) {
            // Store latest snapshot from the device
            state.setSnapshotByDeviceId(
              accountId,
              sessionId,
              deviceId,
              tLastSnapshot
            );
          }
          const { tLastEvent } = payload.msg.properties;

          if (!isNaN(tLastEvent)) {
            // Store latest event from the device
            state.setEventByDeviceId(
              accountId,
              sessionId,
              deviceId,
              tLastEvent
            );
          }
        }
      } else if (
        Array.isArray(payload?.eventMeta?.events) &&
        payload.eventMeta.events.length > 0
      ) {
        let targetEvents = [];

        // Search for events with the type ObjectClass
        payload.eventMeta.events.every((eventEl) => {
          if (Array.isArray(eventEl.event)) {
            targetEvents = eventEl.event.filter((el) => {
              return el.eventType.toUpperCase() === 'OBJECTCLASS';
            });
            return !(targetEvents.length > 0);
          }
          return true;
        });

        // If at least one such event was received, let's
        // save it to the local data store
        if (targetEvents.length > 0) {
          const accountId = state.getAccountId();
          const sessionId = state.getSessionId();
          const deviceId = payload.src?.srcId;

          if (!isNaN(payload.t)) {
            const tLastEvent = parseInt(payload.t / 1000);

            if (!isNaN(tLastEvent)) {
              // Store latest event from the device
              state.setEventByDeviceId(
                accountId,
                sessionId,
                deviceId,
                tLastEvent
              );
            }
          }
        }
      } else if (payload?.msg?.resource?.includes('streams/')) {
        store.dispatch(setMetaDataForEvents(data));
      } else if (payload?.msg?.resource?.includes('camera/events/')) {
        if (error && error === 'error') {
          if (payload.msg.properties.desc === 'The subsciber does not exist') {
            store.dispatch(setSubscribeAreasRequest(false));
          } else if (
            payload.msg.properties.desc === 'The subsciber is already exist'
          ) {
            store.dispatch(setKeepAliveRequest(false));
          } else {
            store.dispatch(setSubscribeAreasRequest(false));
            store.dispatch(setKeepAliveRequest(false));
          }
        } else {
          store.dispatch(setLatestEventsForDots(data));
        }
      } else {
        // TODO: Delete later - support both the approaches
        switch (payload.type) {
          case 'answer':
            webRTCHandler.handleAnswer(payload);
            break;

          case 'candidate':
            webRTCHandler.handleCandidate(payload.candidate);
            break;

          case 'leave':
            webRTCHandler.handleLeaveCall();
            break;

          default:
            if(payload?.msg?.properties?.restart){
              setTimeout(() => {
                store.dispatch(setAllMqttDataFromResponse(payload));
                store.dispatch(setMqttUpdateStatusFromResponse(true));
              }, 1000);
            }
            else{
              store.dispatch(setAllMqttDataFromResponse(payload));
              store.dispatch(setMqttUpdateStatusFromResponse(true));
            } 
            break;
        }
      }
      store.dispatch(setAllMqttDataFromResponse(payload));
    });
  }
};

export const subscribeWithMQTT = (platformDetails, id, uuid) => {
  if (client) {
    const updatedTopic =
      (platformDetails?.topic_details?.subscribe?.signaling).replace(
        '${accountId}',
        id
      );
    const subscriptionTopic = updatedTopic.replace('${session}', uuid);
    client.subscribe(subscriptionTopic, function (err) {
      // TODO : change/delete this later
      if (err) console.error(err);
    });
  }
};

export const subscribeWithOrgIds = (orgList) => {
  if (client && Array.isArray(orgList)) {
    orgList.forEach((org) => {
      client.subscribe(`b/notify/${org?.orgId}`, function (err) {
        // TODO : change/delete this later
        if (err) console.error(err);
      });
    });
  }
};

export const subscribeWithAccountId = (id) => {
  if (client) {
    // TODO : delete this later
    client.subscribe(`b/notify/${id}`, function (err) {
      // TODO : change/delete this later
      if (err) console.error(err);
    });
  }
};

export const mqttSubscribe = (subscription) => {
  if (!subscription) {
    console.error('Subscribe: subscription context required');
    return;
  }

  if (client) {
    const { topic, qos } = subscription;

    if (!topic) {
      console.error('Subscribe: topic required');
      return;
    }

    client.subscribe(topic, { qos }, (error) => {
      if (error) {
        console.error('Subscribe to topic error: ', error);
        return;
      }
    });
  }
};

export const mqttUnsubscribe = (subscription) => {
  if (!subscription) {
    console.error('Unsubscribe: subscription context required');
    return;
  }

  if (client) {
    const { topic } = subscription;

    if (!topic) {
      console.error('Unsubscribe: topic required');
      return;
    }

    client.unsubscribe(topic, (error) => {
      if (error) {
        console.error('Unsubscribe from topic error', error);
        return;
      }
    });
  }
};

export const mqttDisconnectRequest = () => {
  if (client) {
    client.end(() => {
      //TODO: Delete later, keep it to check disconnected events
      console.log('mqtt Disconnected');
    });
  }
};

/**
 * Universal publish action
 * @param {Object} context - contains the topic, payload, and qos, if any
 */
export const mqttPublish = (context) => {
  if (!context) {
    console.error('Publish: context required');
    return;
  }

  if (client) {
    const { topic, qos, payload } = context;

    if (!topic || !payload) {
      console.error('Publish: topic and payload required');
      return;
    }

    if (Array.isArray(topic)) {
      topic.forEach((newTopic) => {
        client.publish(newTopic, payload, { qos }, (error) => {
          if (error) {
            console.error('Publish error: ', error);
          }
        });
      });
    } else {
      client.publish(topic, payload, { qos }, (error) => {
        if (error) {
          console.error('Publish error: ', error);
        }
      });
    }
  }
};

export const publishWithMQTT = (
  platformDetails,
  serverDetails,
  deviceDetails,
  accountId
) => {
  console.log(platformDetails,'platformDetails');
  console.log(serverDetails,'serverDetails');
  console.log(deviceDetails,'deviceDetails');
  console.log(accountId,'accountId');
  webRTCHandler.handleLeaveCall();
  if (client) {
    const publishTopic =
      (platformDetails?.topic_details?.publish?.signaling).replace(
        '${deviceId}',
        deviceDetails?.gatewayId
      );
    if (!state.getSessionId()) {
      state.setSessionId(uuidv4());
    }
    subscribeWithMQTT(platformDetails, accountId, state.getSessionId());
    webRTCHandler.getLocalStreamLive(
      publishTopic,
      serverDetails,
      accountId,
      deviceDetails?.deviceId,
      deviceDetails?.gatewayId,
      state.getSessionId(),
      new Date().getTime()
    );
  }
};

export const publishWithMQTTs = (
  platformDetails,
  serverDetails,
  deviceDetails,
  accountId
) => {
  webRTCmqttHandler.handleLeaveCall();
  if (client) {
    const publishTopic =
      (platformDetails?.topic_details?.publish?.signaling).replace(
        '${deviceId}',
        deviceDetails?.gatewayId
      );
    if (!state.getSessionId()) {
      state.setSessionId(uuidv4());
    }
    subscribeWithMQTT(platformDetails, accountId, state.getSessionId());
    webRTCmqttHandler.getLocalStreamLive(
      publishTopic,
      serverDetails,
      accountId,
      deviceDetails?.deviceId,
      deviceDetails?.gatewayId,
      state.getSessionId(),
      new Date().getTime()
    );
  }
};

const fetchSnapshotCanvas = (typeCanvas, typeVideo) => {
  let canvas = document.getElementById(typeCanvas);
  let video = document.getElementById(typeVideo);
  const context = canvas?.getContext('2d');
  const bitmapObject =
    store.getState(getStreamBitmapData)?.streaming?.bitmapObject;
  const bitmapObjectHeight = bitmapObject ? bitmapObject?.StreamHeight : 450;
  const bitmapObjectWidth = bitmapObject ? bitmapObject?.StreamWidth : 764;
  context?.drawImage(video, 0, 0, bitmapObjectWidth, bitmapObjectHeight);
  const data = canvas?.toDataURL('image/png');
  return data;
};

export const multiStreamSnapDownload = (
  time,
  typeCanvas,
  typeVideo,
  deviceId
) => {
  let canvas = document.getElementById(typeCanvas);
  let video = document.getElementById(typeVideo);
  const context = canvas?.getContext('2d');
  const bitmapObjects =
    store.getState(getStremBitmapsData)?.streaming?.bitmapObjects;
  const bitmapObject = bitmapObjects ? bitmapObjects[deviceId] : null;
  const bitmapObjectHeight = bitmapObject ? bitmapObject?.StreamHeight : 450;
  const bitmapObjectWidth = bitmapObject ? bitmapObject?.StreamWidth : 764;
  if (context?.canvas) {
    context.canvas.width = bitmapObjectWidth;
    context.canvas.height = bitmapObjectHeight;
  }
  context?.drawImage(video, 0, 0, bitmapObjectWidth, bitmapObjectHeight);
  const url = canvas?.toDataURL('image/png');
  const link = document.createElement('a');
  link.download = `${time}.png`;
  link.href = url;
  link.click();
};

export const fetchSnapshotCanvasById = (typeCanvas, typeVideo, deviceId) => {
  let canvas = document.getElementById(typeCanvas);
  let video = document.getElementById(typeVideo);
  const context = canvas?.getContext('2d');
  const bitmapObjects =
    store.getState(getStremBitmapsData)?.streaming?.bitmapObjects;
  const bitmapObject = bitmapObjects ? bitmapObjects[deviceId] : null;
  const bitmapObjectHeight = bitmapObject ? bitmapObject?.StreamHeight : 450;
  const bitmapObjectWidth = bitmapObject ? bitmapObject?.StreamWidth : 764;
  if (context?.canvas) {
    context.canvas.width = bitmapObjectWidth;
    context.canvas.height = bitmapObjectHeight;
  }
  context?.drawImage(video, 0, 0, bitmapObjectWidth, bitmapObjectHeight);
  const data = canvas?.toDataURL('image/png');
  return data;
};

export const disconnectWithMQTT = () => {
  // TODO: Below block is under observation
  // store.getState();
  const selectedView = store.getState(getSelectedView).views.selectedView;
  const isOnDeviceWallPage =
    store.getState(getSelectedView).views.isOnDeviceWallPage;
  const isOnMultiCameraViewPage = store.getState(getIsOnMultiCameraViewPage)
    ?.streaming?.isOnMultiCameraViewPage;
  if (isOnDeviceWallPage || isOnMultiCameraViewPage) {
    const liveDevices =
      (isOnDeviceWallPage
        ? selectedView?.devices
        : store.getState(getPlatformInfo)?.streaming?.liveCameraIds) || [];
    // const liveDevices =
    //   store.getState(getLiveCameraIds)?.streaming?.liveCameraIds;
    liveDevices?.forEach((liveDeviceId, index) => {
      const id = `${liveDeviceId}${liveDeviceId}`;
      const snapshot = fetchSnapshotCanvasById(
        `canvas${id}`,
        `video${id}`,
        liveDeviceId
      );
      store.dispatch(
        setSnapshotImages({ id: liveDeviceId, snapshotImage: snapshot })
      );
      webRTCmqttHandler.handleLeaveCall(liveDeviceId);
    });
  } else {
    const data = fetchSnapshotCanvas('canvas', 'video');
    store.dispatch(setSnapshotImage(data));
    webRTCHandler.handleLeaveCall();
  }
};

export const downloadStream = (time) => {
  let canvas = document.getElementById('canvas');
  let video = document.getElementById('video');
  const context = canvas?.getContext('2d');
  const bitmapObject = store.getState(getStreamBitmapData)?.streaming?.bitmapObject;
  const bitmapObjectHeight = bitmapObject ? bitmapObject?.StreamHeight : 450;
  const bitmapObjectWidth = bitmapObject ? bitmapObject?.StreamWidth:764
  context?.drawImage(video, 0, 0, bitmapObjectWidth , bitmapObjectHeight);
  const url = canvas?.toDataURL('image/png');
  const link = document.createElement('a');
  link.download = `${time}.png`;
  link.href = url;
  link.click();
};

export const downloadPlaybackStream = (time) => {
  let canvas = document.getElementById('canvas2');
  let video = document.getElementById('video2');
  const context = canvas?.getContext('2d');
  const bitmapObject = store.getState(getStreamBitmapData)?.streaming?.bitmapObject;
  const bitmapObjectHeight = bitmapObject ? bitmapObject?.StreamHeight : 450;
  const bitmapObjectWidth = bitmapObject ? bitmapObject?.StreamWidth:764
  context?.drawImage(video, 0, 0, bitmapObjectWidth , bitmapObjectHeight);
  const url = canvas?.toDataURL('image/png');
  const link = document.createElement('a');
  link.download = `${time}.png`;
  link.href = url;
  link.click();
};

// emitting events to server related with direct call

export const sendWebRTCOffer = (requestType, message) => {
  //TODO Delete later
  console.log("offersend>>>>>>>>>>>>>>>>>>>>>>>")
  client.publish(requestType, JSON.stringify(message), {
    qos: 0,
    retain: false,
  });
};

export const sendWebRTCAnswer = (data) => {
  client.emit('answer', data);
};

export const sendWebRTCCandidate = (requestType, message) => {
  client.publish(requestType, JSON.stringify(message), {
    qos: 0,
    retain: false,
  });
};

export const checkMQTTConnection = () => {
  if (client) return true;
};

export const getCurrentEventResource = (mqttresource) => {
  const resourceList = [
    'camera/system/device-info',
    'camera/image/rotate',
    'camera/image/wdr',
    'camera/media/video-profile',
    'camera/media/wisestream',
    'camera/image/ir-mode',
    'camera/image/image-enhancement',
    'camera/image/focus',
    'camera/media/audio-input',
    'camera/network/ip-support',
    'camera/system/date',
    'camera/event/motion-detection',
    'camera/event/tamper-detection',
    'camera/event/defocus-detection',
    'camera/event/virtual-line',
    'camera/event/virtual-area',
    'camera/event/audio-detection',
    'camera/event/sound-classification',
    'camera/event/people-count',
    'camera/event/queue-mgt',
    'camera/media/audio-output',
    'camera/event/object-detection',
    'camera/event/shock-detection',
    'camera/default-settings',
    'camera/diag/duclo-fw-update',
    'camera/system/device-status',
    'camera/settings/max-br',
    'diag/uploadLogs'
  ];
  if (mqttresource != null && mqttresource != undefined) {
    return resourceList?.find((resource) => resource === mqttresource);
  }
};
