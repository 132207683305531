import { lazy } from 'react';
import withSuspense from '../withSuspense';

const ManageAccessPolicies = withSuspense(
  lazy(() => import('../../pages/accesspolicies/ManageAccessPolicies'))
);

const AccessPoliciesDetails = withSuspense(
  lazy(() => import('../../pages/accesspolicies/AccessPoliciesDetails'))
);

export const accessPoliciesRoutes = [
  { path: 'manage.html', element: <ManageAccessPolicies /> },
  { path: 'details', element: <AccessPoliciesDetails /> },
];
