import { Avatar, PrimaryButton, TextBlock } from '../../components/common';
import { Utils, constants, roles } from '../../helpers';
import { warninground } from '../../assets/images';
import './ManageOrganizations.scss';
import axios from 'axios';
import { useEffect, useState } from 'react';
import useLicensesStore from '../../store/LicensesStore';
import { useOrganizations } from '../../store/OrganizationsStore';
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

const RemoveOrganization = (props) => {
  const { getLicenses } = useLicensesStore();
  const [activeLicenseMessage, setActiveLicenseMessage] = useState(false);
  const loggedInUserRole = useOrganizations((state) => state.loggedInUserRole);
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    const activeLicenses = getLicenses()?.filter(
      (license) =>
        license.licenseStatus === 'ACTIVE' ||
        license.licenseStatus === 'ACTIVE_UNASSIGNED' ||
        license.licenseStatus === 'ASSIGNED_ACTIVATION_PENDING' ||
        license.licenseStatus === 'UNASSIGNED'
    );

    if (activeLicenses?.length > 0) {
      setActiveLicenseMessage(true);
    }
  }, []);

  const invalidateSessionApi = () => {
    const reqBody = {
      refresh_token: keycloak?.refreshToken,
    };
    axios
      .post('/user/token/invalidate/app', reqBody, Utils.requestHeader())
      .then((res) => {
        if (res?.data?.meta?.code === 200) {
          keycloak.logout({
            redirectUri: process.env.REACT_APP_KEYCLOAK_LOGOUT_REDIRECT_URL,
          });
        }
      });
  };

  const removeOrganization = async () => {
    try {
      const response = await axios.delete(
        `/partner/orgs/${props?.editUser?.orgId}`,
        Utils.requestHeader()
      );
      if (response?.data?.meta?.code === 200) {
        props?.hideRemoveOrgModal();
        if (
          loggedInUserRole === roles.ROLE2VMS ||
          loggedInUserRole === roles.ROLE3VMS
        ) {
          invalidateSessionApi();
        } else {
          navigate('/customers/manage.html');
        }
      }
    } catch (err) {}
  };

  return (
    <div className="d-flex flex-column align-items-center gap-4 text-center">
      {activeLicenseMessage ? (
        <TextBlock
          fontWeight="400"
          fontSize="0.875rem"
          lineHeight="1.25rem"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--greyscale_56'
          )}
        >
          {`${constants.ACTIVE_LICENSE_HELPER_MESSAGE}`}
        </TextBlock>
      ) : null}
      <TextBlock
        fontWeight="400"
        fontSize="0.875rem"
        lineHeight="1.25rem"
        color={getComputedStyle(document.documentElement).getPropertyValue(
          '--greyscale_56'
        )}
      >
        {`${constants.REMOVE_ORGANIZATION_TEXT} ${props?.editUser?.orgName}?`}
      </TextBlock>

      <Avatar
        value={warninground}
        valueType="icon"
        size="extraLarge"
        style="roundedCircle"
      />

      <div className="w-100">
        <PrimaryButton
          className="btn btn-primary w-100"
          type="button"
          height="44px"
          fontSize="0.875rem"
          width="100%"
          onClick={() => props?.hideRemoveOrgModal()}
        >
          {constants.CANCEL_BUTTON_TITLE}
        </PrimaryButton>

        <PrimaryButton
          className="btn btn-outline-danger w-100 mt-2"
          type="button"
          height="44px"
          fontSize="0.875rem"
          backgroundColor="#fff"
          borderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--primary_40'
          )}
          borderWidth="1.5px"
          hoverBorderWidth="1.5px"
          hoverColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          hoverBackgroundColor="#fff"
          hoverBorderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          onClick={() => removeOrganization()}
        >
          {constants.REMOVE_ORGANIZATION}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default RemoveOrganization;
