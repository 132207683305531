import dayjs from 'dayjs';
export const generateScheduleUsingCount = (
  startDate,
  dayAbbreviation,
  occurrences
) => {
  // Validate input parameters
  if (
    !(startDate instanceof Date) ||
    typeof dayAbbreviation !== 'string' ||
    dayAbbreviation.length !== 2 ||
    !Number.isInteger(occurrences) ||
    occurrences <= 0
  ) {
    return 'Invalid input parameters';
  }

  // Create a map of day abbreviations to corresponding day indices (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const dayIndexMap = {
    SU: 0,
    MO: 1,
    TU: 2,
    WE: 3,
    TH: 4,
    FR: 5,
    SA: 6,
  };

  // Validate the provided day abbreviation
  if (!dayIndexMap.hasOwnProperty(dayAbbreviation)) {
    return 'Invalid day abbreviation';
  }

  // Get the day index corresponding to the provided day abbreviation
  const targetDayIndex = dayIndexMap[dayAbbreviation];

  // Get the starting day index based on the startDate
  const startDayIndex = startDate.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

  // Initialize an array to store schedule dates
  let schedule = [];

  // Calculate the first occurrence of the target day
  let daysToAdd = (targetDayIndex + 7 - startDayIndex) % 7; // Ensure positive result
  let nextDate = new Date(startDate);
  nextDate.setDate(startDate.getDate() + daysToAdd);
  schedule.push(nextDate);

  // Generate additional occurrences if needed
  for (let i = 1; i < occurrences; i++) {
    nextDate = new Date(nextDate);
    nextDate.setDate(nextDate.getDate() + 7); // Add 7 days to get the next occurrence of the same day
    schedule.push(nextDate);
  }

  // Return the schedule array
  return schedule;
};

export const generateScheduleBetweenTwoDates = (
  startDate,
  endDate,
  dayAbbreviation
) => {
  // Validate input parameters
  if (
    !(startDate instanceof Date) ||
    !(endDate instanceof Date) ||
    typeof dayAbbreviation !== 'string' ||
    dayAbbreviation.length !== 2 ||
    startDate >= endDate // Ensure startDate is before endDate
  ) {
    return 'Invalid input parameters';
  }

  // Create a map of day abbreviations to corresponding day indices (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const dayIndexMap = {
    SU: 0,
    MO: 1,
    TU: 2,
    WE: 3,
    TH: 4,
    FR: 5,
    SA: 6,
  };

  // Validate the provided day abbreviation
  if (!dayIndexMap.hasOwnProperty(dayAbbreviation)) {
    return 'Invalid day abbreviation';
  }

  // Get the day index corresponding to the provided day abbreviation
  const targetDayIndex = dayIndexMap[dayAbbreviation];

  // Get the starting day index based on the startDate
  const startDayIndex = startDate.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

  // Initialize an array to store schedule dates
  let schedule = [];

  // Calculate the first occurrence of the target day after or on startDate
  let daysToAdd = (targetDayIndex + 7 - startDayIndex) % 7; // Ensure positive result
  let nextDate = new Date(startDate);
  nextDate.setDate(startDate.getDate() + daysToAdd);

  // Generate occurrences until endDate is reached
  while (nextDate <= endDate) {
    schedule.push(new Date(nextDate)); // Clone the date to avoid mutating references
    nextDate.setDate(nextDate.getDate() + 7); // Add 7 days to get the next occurrence of the same day
  }

  // Return the schedule array
  return schedule;
};

export const scheduleDateTime = (sourceDate, destinationDate) => {
  // Validate input parameters
  if (!(sourceDate instanceof Date) || !(destinationDate instanceof Date)) {
    return 'Invalid input parameters';
  }
  // Get hours, minutes, and seconds from the source date
  const hours = sourceDate.getHours();
  const minutes = sourceDate.getMinutes();
  const seconds = sourceDate.getSeconds();
  // Set hours, minutes, and seconds to the destination date
  destinationDate.setHours(hours);
  destinationDate.setMinutes(minutes);
  destinationDate.setSeconds(seconds);
  // Optionally, return the updated destinationDate
  return destinationDate;
};

// Helper function to get number of days in a month
const getDaysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

export const generateScheduleForYear = (startDate, dayAbbreviation) => {
  // Validate input parameters
  if (
    !(startDate instanceof Date) ||
    typeof dayAbbreviation !== 'string' ||
    dayAbbreviation.length !== 2
  ) {
    return 'Invalid input parameters';
  }
  // Create a map of day abbreviations to corresponding day indices (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const dayIndexMap = {
    SU: 0,
    MO: 1,
    TU: 2,
    WE: 3,
    TH: 4,
    FR: 5,
    SA: 6,
  };

  // Validate the provided day abbreviation
  if (!dayIndexMap.hasOwnProperty(dayAbbreviation)) {
    return 'Invalid day abbreviation';
  }
  // Get the day index corresponding to the provided day abbreviation
  const targetDayIndex = dayIndexMap[dayAbbreviation];

  // Get the starting day index based on the startDate
  const startYear = startDate.getFullYear();
  const startMonth = startDate.getMonth();
  const startHours = startDate.getHours();
  const startMinutes = startDate.getMinutes();
  let startDay = startDate.getDate();
  // Initialize an array to store schedule dates
  let schedule = [];

  // Iterate through each week of the year
  for (let month = startMonth; month <= 11; month++) {
    // 11 is December
    for (let day = startDay; day <= getDaysInMonth(startYear, month); day++) {
      const currentDate = new Date(
        startYear,
        month,
        day,
        startHours,
        startMinutes
      );
      const currentDayIndex = currentDate.getDay();
      // Check if the current date matches the target day abbreviation
      if (currentDayIndex === targetDayIndex) {
        schedule.push(currentDate);
      }
    }
    // Reset start day after first iteration
    startDay = 1;
  }
  // Return the schedule array
  return schedule;
};

export const generateCustomSchedule = (
  startDate,
  occurrencesOrEndDate,
  freq = 'weekly',
  interval = 1
) => {
  // Validate input parameters
  if (
    !(startDate instanceof Date) ||
    (typeof occurrencesOrEndDate !== 'number' &&
      !(occurrencesOrEndDate instanceof Date)) ||
    typeof freq !== 'string' ||
    !['daily', 'weekly', 'monthly', 'yearly'].includes(freq) ||
    !Number.isInteger(interval) ||
    interval <= 0
  ) {
    return 'Invalid input parameters';
  }

  let occurrences;
  let endDate;

  // Determine occurrences or endDate based on the type of occurrencesOrEndDate
  if (typeof occurrencesOrEndDate === 'number' && occurrencesOrEndDate > 0) {
    occurrences = occurrencesOrEndDate;
  } else if (occurrencesOrEndDate instanceof Date) {
    endDate = occurrencesOrEndDate;
  } else {
    return 'Invalid occurrencesOrEndDate parameter';
  }

  // Initialize an array to store schedule dates
  let schedule = [];

  // Calculate the first occurrence based on the frequency and interval
  let nextDate = new Date(startDate);
  switch (freq) {
    case 'daily':
      while (
        (!occurrences || schedule.length < occurrences) &&
        (!endDate || nextDate <= endDate)
      ) {
        schedule.push(new Date(nextDate));
        nextDate.setDate(nextDate.getDate() + interval); // Increment by interval days
      }
      break;
    case 'weekly':
      while (
        (!occurrences || schedule.length < occurrences) &&
        (!endDate || nextDate <= endDate)
      ) {
        schedule.push(new Date(nextDate));
        nextDate.setDate(nextDate.getDate() + 7 * interval); // Increment by interval weeks
      }
      break;
    case 'monthly':
      while (
        (!occurrences || schedule.length < occurrences) &&
        (!endDate || nextDate <= endDate)
      ) {
        schedule.push(new Date(nextDate));
        nextDate.setMonth(nextDate.getMonth() + interval); // Increment by interval months
      }
      break;
    case 'yearly':
      while (
        (!occurrences || schedule.length < occurrences) &&
        (!endDate || nextDate <= endDate)
      ) {
        schedule.push(new Date(nextDate));
        nextDate.setFullYear(nextDate.getFullYear() + interval); // Increment by interval years
      }
      break;
    default:
      return 'Invalid frequency';
  }

  // Return the schedule array
  return schedule;
};

export const datesAreEqual = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const convertDateIntoICalenderFormat = (date) => {
  // Ensure date is a Date object
  if (!(date instanceof Date)) {
    return null;
  }
  return dayjs(date).format('YYYYMMDDTHHmmss');
};

export const parseDateIntoICalenderFormat = (dateString) => {
  if (typeof dateString !== 'string') {
    return null;
  }
  return dayjs(dateString, 'YYYYMMDDTHHmmss').toDate();
};

export const groupCustomSchedule = (customData) => {
  let groupedData = {};
  customData?.forEach((item) => {
    // Extract date part (YYYY-MM-DD) from timestamp
    let date = parseDateIntoICalenderFormat(item.dtstart);
    let dateString = date.toLocaleDateString(); // YYYY-MM-DD
    // Initialize array for the date if not already present
    if (!groupedData[dateString]) {
      groupedData[dateString] = [];
    }
    // Add transaction to the array for the corresponding date
    groupedData[dateString].push(item);
  });

  return groupedData;
};

export const generateEventDescriptions = (events, scheduleType) => {
  const days = {
    MO: 'Monday',
    TU: 'Tuesday',
    WE: 'Wednesday',
    TH: 'Thursday',
    FR: 'Friday',
    SA: 'Saturday',
    SU: 'Sunday',
  };
  // Group events by day or date
  const groupedEvents = {};
  events.forEach((event) => {
    const day = event.rrule.byDay;
    const start = dayjs(event.dtstart, 'YYYYMMDDTHHmmss');
    const end = dayjs(event.dtend, 'YYYYMMDDTHHmmss');

    const startDate = dayjs(event.dtstart, 'YYYYMMDDTHHmmss');
    const key = scheduleType == 'Weekly' ? day : startDate.format('MM/DD/YYYY');
    if (!groupedEvents[key]) {
      groupedEvents[key] = [];
    }
    groupedEvents[key].push({ start, end });
  });
  const descriptions = [];
  for (const [day, times] of Object.entries(groupedEvents)) {
    const dayName = scheduleType == 'Weekly' ? days[day] : day;
    const descriptionParts = [];
    // Sort events by start time
    times.sort((a, b) => a.start - b.start);
    times.forEach((time) => {
      descriptionParts.push(
        `${time.start.format('h:mm A')} to ${time.end.format('h:mm A')}`
      );
    });
    descriptions.push(`${dayName} : ${descriptionParts.join(' and ')}`);
  }

  return descriptions;
};

export const getTwoLetterDayAbbreviation = (dtString) => {
  const date = dayjs(dtString, 'YYYYMMDDTHHmmss');
  const dayAbbreviations = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
  const dayIndex = date.day();
  return dayAbbreviations[dayIndex];
};

export const formatAIScheduleEvent = (item, day = '', scheduleType) => {
  // Parse the date-time strings using dayjs
  const start = dayjs(item.dtstart, 'YYYYMMDDTHHmmss');
  let dtend = item?.dtend;
  if (!dtend) {
    let startDate = parseDateIntoICalenderFormat(item?.dtstart);
    startDate.setHours(23, 59, 0);
    dtend = convertDateIntoICalenderFormat(startDate);
  }
  const end = dayjs(dtend, 'YYYYMMDDTHHmmss');
  // Format the date and time
  const startDate = day ? day : start.format('dddd, MMM D YYYY'); // Example: 'Monday, Aug 20 2024'
  const startTime = start.format('hh:mm A'); // Example: '09:00 AM'
  const endTime = end.format('hh:mm A'); // Example: '05:00 PM'
  return {
    startDate,
    startTime,
    endTime,
  };
};

export const customScheduleFreq = [
  {
    value: 'daily',
    label: 'Daily',
  },
  {
    value: 'weekly',
    label: 'Weekly',
  },
  {
    value: 'monthly',
    label: 'Monthly',
  },
  {
    value: 'yearly',
    label: 'Yearly',
  },
];

export const traditionalWayType = ['Weekly', 'Custom'];
export const defaultWeeklyArray = [
  {
    day: 'Monday',
    letter: 'Mon',
    twoLetter: 'MO',
    selected: false,
    schedule: [{ from: '', until: '' }],
  },
  {
    day: 'Tuesday',
    letter: 'Tue',
    twoLetter: 'TU',
    selected: false,
    schedule: [{ from: '', until: '' }],
  },
  {
    day: 'Wednesday',
    letter: 'Wed',
    twoLetter: 'WE',
    selected: false,
    schedule: [{ from: '', until: '' }],
  },
  {
    day: 'Thursday',
    letter: 'Thu',
    twoLetter: 'TH',
    selected: false,
    schedule: [{ from: '', until: '' }],
  },
  {
    day: 'Friday',
    letter: 'Fri',
    twoLetter: 'FR',
    selected: false,
    schedule: [{ from: '', until: '' }],
  },
  {
    day: 'Saturday',
    letter: 'Sat',
    twoLetter: 'SA',
    selected: false,
    schedule: [{ from: '', until: '' }],
  },
  {
    day: 'Sunday',
    letter: 'Sun',
    twoLetter: 'SU',
    selected: false,
    schedule: [{ from: '', until: '' }],
  },
];

export const defaultCustomArray = [
  {
    date: '',
    selected: false,
    schedule: [{ from: '', until: '' }],
    repeats: {
      repeats: false,
      freq: 'daily',
      interval: 1,
    },
    ends: {
      ends: false,
      schedule: 'on',
      until: '',
      count: '',
    },
  },
];
