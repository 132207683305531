import { useMemo } from 'react';
import { useOrganizations } from '../store/OrganizationsStore';

const useRoutingApiPaths = () => {
  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData
  );
  const paths = useMemo(
    () => ({
      CREATE_POLICY: `${
        process.env.REACT_APP_NEW_API_BASE_URL
      }acscardholder/orgs/${getCustomerOrgData()[0]?.orgId}/policy`,
      GET_ORG_POLICIES: `${
        process.env.REACT_APP_NEW_API_BASE_URL
      }acscardholder/orgs/${getCustomerOrgData()[0]?.orgId}/policy`,
      CARDHOLDER_API: `acscardholder/orgs/${
        getCustomerOrgData()[0]?.orgId
      }/cardHolders`,
      attachPolicyToCardHolder: (policyId, cardHolderId) =>
        `${process.env.REACT_APP_NEW_API_BASE_URL}acscardholder/orgs/${
          getCustomerOrgData()[0]?.orgId
        }/policy/${policyId}/cardHolder/${cardHolderId}`,
      CARDHOLDER: {
        createCardHolder: `acscardholder/orgs/${
          getCustomerOrgData()[0]?.orgId
        }/cardHolders`,
        createAccountHolder: `partner/orgs/${
          getCustomerOrgData()[0]?.orgId
        }/accounts`,
        getCardHolders: `acscardholder/orgs/${
          getCustomerOrgData()[0]?.orgId
        }/cardHolders`,
        manageCredentialsApiRoute: (cardHolderId) =>
          `acscardholder/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/credential/cardHolders/${cardHolderId}`,
        updateCredentialApiRoute: (credentialId) =>
          `acscardholder/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/credential/${credentialId}`,
        deleteCredentialApiRoute: (credentialId) =>
          `acscardholder/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/credential/${credentialId}`,
        getSignedUrlApiRoute: (cardHolderId) =>
          `acscardholder/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/user/${cardHolderId}/profile/icon`,
        updateCardHolder: (cardHolderId) =>
          `acscardholder/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/cardHolders/${cardHolderId}`,
        getActivatingTodayCardHolders: (startOfDay, endOfDay) =>
          `acscardholder/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/cardHolders?&startDate=${startOfDay}&endDate=${endOfDay}&filterBy=activatingtoday`,
        getExpiringTodayCardHolders: (startDate, endDate) =>
          `acscardholder/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/cardHolders?&startDate=${startDate}&endDate=${endDate}&filterBy=expiringtoday`,
        getOnlyInactive: (startDate) =>
          `acscardholder/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/cardHolders?&startDate=${startDate}&filterBy=inactive`,
        getCardHolderMetaData: () =>
          `acscardholder/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/cardHolders/metadata`,
        attacGroupToCardHolder: (groupId, cardHolderId) =>
          `${process.env.REACT_APP_NEW_API_BASE_URL}acscardholder/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/groups/${groupId}/cardHolder/${cardHolderId}`,
      },
      CARDHOLDER_GROUPS: {
        getAllCardHolderGroups: `acscardholder/orgs/${
          getCustomerOrgData()[0]?.orgId
        }/groups`,
        createCardHolderGroup: `acscardholder/orgs/${
          getCustomerOrgData()[0]?.orgId
        }/groups`,
        getCardHolderGroupById: (cardHolderGroupId) =>
          `acscardholder/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/groups/${cardHolderGroupId}`,
        updateCardHolderGroup: (cardHolderGroupId) =>
          `acscardholder/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/groups/${cardHolderGroupId}`,
        deleteCardHolderFromGroup: (cardHolderGroupId, cardHolderId) =>
          `acscardholder/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/groups/${cardHolderGroupId}/cardHolder/${cardHolderId}`,
        deleteAccessPolicyFromGroup: (policyId, cardHolderGroupId) =>
          `acscardholder/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/policy/${policyId}/groups/${cardHolderGroupId}`,
      },
      ACCESS_POLICIES: {
        createPolicyApiRoute: `acscardholder/orgs/${
          getCustomerOrgData()[0]?.orgId
        }/policy`,
        updatePolicyApiRoute: (policyId) =>
          `acscardholder/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/policy/${policyId}`,
        getSinglePolicyById: (policyId) =>
          `acscardholder/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/policy/${policyId}`,
        deleteSinglePolicyById: (policyId) =>
          `acscardholder/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/policy/${policyId}`,
        attachDoorsScheduleToPolicyApiRoute: `acsio/orgs/${
          getCustomerOrgData()[0]?.orgId
        }/accessPoint`,
        attachDetachDoorsScheduleToPolicyApiRoute: `acsio/orgs/${
          getCustomerOrgData()[0]?.orgId
        }/accessPoint`,
        getAccessPointByPolicyId: (policyId) =>
          `acsio/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/accessPoint/${policyId}`,
        addPolicyTogGroup: (policyId, groupId) =>
          `acscardholder/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/policy/${policyId}/groups/${groupId}`,
        removePolicyToGroup: (policyId, groupId) =>
          `acscardholder/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/policy/${policyId}/groups/${groupId}`,
      },
      DOORS: {
        listDoors: `acsio/orgs/${getCustomerOrgData()[0]?.orgId}/doors`,
        createDoor: `acsio/orgs/${getCustomerOrgData()[0]?.orgId}/door`,
        updateDoor: (doorId) =>
          `acsio/orgs/${getCustomerOrgData()[0]?.orgId}/door/${doorId}`,
        getSingleDoor: (doorId) =>
          `acsio/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/doors?doorIds=${doorId}`,
      },
      DOORTEMPLATE: {
        listTemplates: `acsio/orgs/${
          getCustomerOrgData()[0]?.orgId
        }/doorTemplates`,
        deleteTemplate: (doorTemplateId) =>
          `acsio/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/doorTemplate/${doorTemplateId}`,
        createTemplate: `acsio/orgs/${
          getCustomerOrgData()[0]?.orgId
        }/doorTemplate`,
      },
      CONTROLLERS: {
        listControllers: `device/orgs/${
          getCustomerOrgData()[0]?.orgId
        }/devices`,
        discover: `device/orgs/${
          getCustomerOrgData()[0]?.orgId
        }/devices/discover`,
        discoverCount: `device/orgs/${
          getCustomerOrgData()[0]?.orgId
        }/devices/discover/count`,
      },
      SCHEDULES: {
        listSchedules: `acsio/orgs/${getCustomerOrgData()[0]?.orgId}/schedules`,
        createSchedule: `acsio/orgs/${getCustomerOrgData()[0]?.orgId}/schedule`,
        updateSchedule: (scheduleId) =>
          `acsio/orgs/${getCustomerOrgData()[0]?.orgId}/schedule/${scheduleId}`,
        deleteSchedule: (scheduleId) =>
          `acsio/orgs/${getCustomerOrgData()[0]?.orgId}/schedule/${scheduleId}`,
        aiSchedule: `acsio/orgs/${
          getCustomerOrgData()[0]?.orgId
        }/ai/schedule/prompt`,
      },
      ALARMS: {
        listAlarms: `acsautomation/orgs/${getCustomerOrgData()[0]?.orgId}/alarm`,
        createAlarm: `acsautomation/orgs/${getCustomerOrgData()[0]?.orgId}/alarm`,
        updateAlarm: (alarmId) =>
          `acsautomation/orgs/${getCustomerOrgData()[0]?.orgId}/alarm/${alarmId}`,
        deleteAlarm: (alarmId) =>
          `acsautomation/orgs/${getCustomerOrgData()[0]?.orgId}/alarm/${alarmId}`,
      },
      ELEVATORS: {
        listElevators: `acsio/orgs/${getCustomerOrgData()[0]?.orgId}/elevator`,
        createElevator: `acsio/orgs/${getCustomerOrgData()[0]?.orgId}/elevator`,
        updateElevator: (elevatorId) =>
          `acsio/orgs/${getCustomerOrgData()[0]?.orgId}/elevator/${elevatorId}`,
        deleteElevator: (elevatorId) =>
          `acsio/orgs/${getCustomerOrgData()[0]?.orgId}/elevator/${elevatorId}`,
      },
      ELEVATORSTEMPLATE: {
        listTemplates: `acsio/orgs/${
          getCustomerOrgData()[0]?.orgId
        }/elevatorTemplates`,
        deleteTemplate: (elevatorTemplateId) =>
          `acsio/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/elevatorTemplate/${elevatorTemplateId}`,
        createTemplate: `acsio/orgs/${
          getCustomerOrgData()[0]?.orgId
        }/elevatorTemplate`,
      },
      ZONES: {
        zoneCapability: `acsio/orgs/${
          getCustomerOrgData()[0]?.orgId
        }/zoneCapability`,
        listZones: `acsio/orgs/${getCustomerOrgData()[0]?.orgId}/zones`,
        createZone: `acsio/orgs/${getCustomerOrgData()[0]?.orgId}/zone`,
        updateZone: (zoneId) =>
          `acsio/orgs/${getCustomerOrgData()[0]?.orgId}/zone/${zoneId}`,
        deleteZone: (zoneId) =>
          `acsio/orgs/${getCustomerOrgData()[0]?.orgId}/zone/${zoneId}`,
      },
      DEVICES: {
        partnerPlatformV2: `partner/platform`,
        manufacturerSkuBanks: `device/manufacturerSkuBanks`,
        refreshmanufacturerSkuBanks: `device/manufacturerSkuBanks/refresh`,
      },
      LOCATIONS: {
        listLocations: `partner/orgs/${
          getCustomerOrgData()[0]?.orgId
        }/locations`,
        getOrganizationContact: `partner/orgs/${
          getCustomerOrgData()[0]?.orgId
        }/accounts/v2`,
        createLocation: `partner/orgs/${
          getCustomerOrgData()[0]?.orgId
        }/locationAreas`,
        updateLocation: (locationId) =>
          `partner/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/locations/${locationId}/v2`,
        getLocation: (locationId) =>
          `partner/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/locations/${locationId}`,
        deleteLocation: (locationId) =>
          `partner/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/locations/${locationId}`,
        listAreas: (locationId) =>
          `acsio/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/location/${locationId}/area/v2`,
        addArea: (locationId) =>
          `partner/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/locations/${locationId}/area`,
        updateArea: (locationId, areaId) =>
          `partner/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/locations/${locationId}/area/${areaId}`,
        attachDetachDoors: (locationId, areaId) =>
          `acsio/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/location/${locationId}/area/${areaId}/manageDoors`,
        attachDetachSchedules: (locationId, areaId) =>
          `acsio/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/location/${locationId}/area/${areaId}/manageSchedules`,
        getAreaDoorSchedules: (locationId, areaId) =>
          `acsio/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/location/${locationId}/area/${areaId}/managedDoorsSchedules`,
        reassignAreaOfDoors: (locationId, areaId) =>
          `acsio/orgs/${
            getCustomerOrgData()[0]?.orgId
          }/location/${locationId}/area/${areaId}/reassignAreaOfDoors`,
      },
      PARTNER: {
        cdnDetails: `partner/orgs/${getCustomerOrgData()[0]?.orgId}/cdn`,
      },
      ORGANIZATION: {
        orgRoles: `partner/orgs/${getCustomerOrgData()[0]?.orgId}/roles`,
      },
    }),
    []
  );

  return paths;
};

export default useRoutingApiPaths;
