import { lazy } from 'react';
import withSuspense from '../withSuspense';

const ManageAlarms = withSuspense(
  lazy(() => import('../../pages/alarms/ManageAlarms'))
);

export const alarmsRoutes = [
  { path: 'manage.html', element: <ManageAlarms /> },
];
