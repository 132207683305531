import React, { useState } from 'react';
import { Box, TextField, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Close';

const CustomSearch = ({
  handleSearchChange,
  searchQuery,
  setSearchQuery,
  ...props
}) => {
  const handleClear = () => {
    setSearchQuery('');
    if (props?.setSearchText) props?.setSearchText('');
  };

  const handleSearchKeyDown = (event) => {
    event.stopPropagation();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: `1px solid var(--brand_black)`,
        borderRadius: '8px',
        padding: '4px 8px',
        width: '100%',
        ...props.sx,
      }}
    >
      <SearchIcon
        style={{
          color: '#424242',
          fontSize: '20px',
        }}
      />
      <TextField
        placeholder={props?.placeHolder || 'Search'}
        variant="standard"
        value={searchQuery}
        color={`var(--brand_black)`}
        onChange={handleSearchChange}
        onKeyDown={handleSearchKeyDown}
        InputProps={{
          disableUnderline: true,
          style: {
            marginLeft: '8px',
            flex: 1,
          },
          inputProps: {
            style: {
              '::placeholder': { color: `var(--brand_black)` },
            },
          },
        }}
        sx={{
          '& .MuiInputBase-input::placeholder': {
            color: `var(--brand_black)`,
            opacity: 1,
          },
        }}
        fullWidth
      />
      {searchQuery && (
        <IconButton
          onClick={handleClear}
          size="small"
          sx={{
            padding: '4px',
            backgroundColor: '#f0f0f0',
            borderRadius: '50%',
            marginLeft: '8px',
            '&:hover': {
              backgroundColor: '#e0e0e0',
            },
          }}
        >
          <ClearIcon
            style={{
              color: '#757575',
              fontSize: '16px',
            }}
          />
        </IconButton>
      )}
    </Box>
  );
};

export default CustomSearch;
