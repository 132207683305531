import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import {
  fetchAllCardHolderGroups,
  fetchCardHolderGroupById,
  detachCardholderFromGroup,
  detachAccessPolicyFromGroup,
} from '../services/api/cardholdergroups';

export const useCardHolderGroupStore = create(
  persist(
    (set, get) => ({
      cardHolderGroups: [],
      cardHolderGroupById: {},
      cardHolderGroupPolicies: [],
      cardHoldersAttachedToGroup: [],
      setCardHolderGroups: async (route) => {
        if (!route) return;
        const res = await fetchAllCardHolderGroups(route);
        const responseData = res?.data;

        if (responseData?.meta?.code === 200) {
          set({
            cardHolderGroups: [...responseData?.data?.result],
          });
        }
        return responseData;
      },
      setCardHolderGroupById: async (route) => {
        if (!route) return;
        const res = await fetchCardHolderGroupById(route);
        const responseData = res?.data;

        if (responseData?.meta?.code === 200) {
          set({
            cardHolderGroupById: responseData?.data,
            cardHolderGroupPolicies: responseData?.data?.accessControls,
            cardHoldersAttachedToGroup: responseData?.data?.cardHolders,
          });
        }
        return responseData;
      },
      setCardHolderGroupIdPolicies: (policies) => {
        set({
          cardHolderGroupPolicies: policies,
        });
      },
      setCardHoldersToGroup: (cardHolders) => {
        set({
          cardHoldersAttachedToGroup: cardHolders,
        });
      },
      removeCardHolderFromGroup: async (route, cardHolderId) => {
        const res = await detachCardholderFromGroup(route);
        set({
          cardHoldersAttachedToGroup: get().cardHoldersAttachedToGroup?.filter(
            (cardHolder) => cardHolder.cardHolderId !== cardHolderId,
          ),
        });
        return res?.data;
      },
      removeAccessPolicyFromGroup: async (route, policyId) => {
        const res = await detachAccessPolicyFromGroup(route);
        set({
          cardHolderGroupPolicies: get().cardHolderGroupPolicies?.filter(
            (cardHolder) => cardHolder.policyId !== policyId,
          ),
        });
        return res?.data;
      },
      getAllCardHolderGroups: () => get().cardHolderGroups,
      getCardHolderGroupByIdDetails: () => get().cardHolderGroupById,
      getCardHolderGroupAccessPolicies: () => get().cardHolderGroupPolicies,
      getAttachedCardHolders: () => get().cardHoldersAttachedToGroup,
      resetCardHolderGroupId: () => {
        set({
          cardHolderGroupById: {},
          cardHolderGroupPolicies: [],
          cardHoldersAttachedToGroup: [],
        });
      },
      resetAll: () => {
        set({
          cardHolderGroups: [],
          cardHolderGroupById: {},
          cardHolderGroupPolicies: [],
          cardHoldersAttachedToGroup: [],
        });
      },
    }),
    {
      name: 'net.duclo.vms.cardHolderGroups',
      version: '1.0',
    },
  ),
);
