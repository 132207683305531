import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import axios from 'axios';
import { Utils } from '../helpers';
import {
  addCardHolder,
  getCardHolder,
  manageCredentials,
  updateCardHolder,
  updateCredentials,
  deleteCredential,
  getCardHolders,
  fetchActivatingTodayCardholders,
  fetchExpiredTodayCardHolders,
  fetchInactiveCardHolders,
  fetchCardHolderMetaData,
} from '../services/api/cardholders';

export const useCardholderStore = create(
  persist(
    (set, get) => ({
      cardholders: [],
      activatingSoonCardholders: [],
      expiringTodayCardholders: [],
      metaData: {},
      cardHolder: null,
      setCardholders: async (route) => {
        if (!route) return;

        const res = await getCardHolders(route);
        const responseData = res?.data;

        if (responseData?.meta?.code === 200) {
          set({
            cardholders: [...responseData?.data?.result],
          });
        }
        return responseData;
      },
      setCardHolder: async (route) => {
        if (!route) return;
        set({
          cardHolder: [],
        });
        const res = await getCardHolder(route);

        if (res?.data?.meta?.code === 200) {
          set({
            cardHolder: res?.data?.data,
          });
        }
        return res;
      },
      setActivatingTodayCardHolders: async (route) => {
        const res = await fetchActivatingTodayCardholders(route);
        if (res?.data?.meta?.code === 200) {
          set({
            activatingSoonCardholders: res?.data?.data?.result,
          });
        }
      },
      setExpiredTodayCardHolders: async (route) => {
        const res = await fetchExpiredTodayCardHolders(route);
        if (res?.data?.meta?.code === 200) {
          set({
            expiringTodayCardholders: res?.data?.data?.result,
          });
        }
      },
      setInactiveCardHolders: async (route) => {
        if (!route) return;

        const res = await fetchInactiveCardHolders(route);
        const responseData = res?.data;

        if (responseData?.meta?.code === 200) {
          set({
            cardholders: [...responseData?.data?.result],
          });
        }
        return responseData;
      },
      setMetaData: async (route) => {
        const res = await fetchCardHolderMetaData(route);
        if (res?.data?.meta?.code === 200) {
          set({
            metaData: res?.data?.data,
          });
        }
        return res?.data;
      },
      createCardHolder: async (route, cardholderData) => {
        if (!route) return;
        const res = await addCardHolder(route, cardholderData);
        return res?.data;
      },
      getCardHolder: () => get().cardHolder,
      getAllCardHolders: () => get().cardholders,
      getActivatingSoonCardHolders: () => get().activatingSoonCardholders,
      getExpiringTodayCardHolders: () => get().expiringTodayCardholders,
      getCardHolderMetaData: () => get().metaData,
      updateCardHolder: async (route, cardholderData) => {
        if (!route) return;
        const res = await updateCardHolder(route, cardholderData);

        set({
          cardHolder: res?.data?.data,
        });
        return res?.data;
      },
      getCardHolderEvent: async (route) => {
        const res = await axios.get(route, Utils.requestHeader());
      },
      handleCredentials: async (route, credentialData) => {
        const res = await manageCredentials(route, credentialData);
        if (res?.data?.meta?.code === 200) {
          let updatedCredentials = [];
          if (get().cardHolder?.acsCredentials) {
            updatedCredentials = [
              ...get().cardHolder?.acsCredentials,
              res?.data?.data,
            ];
          } else {
            updatedCredentials = [res?.data?.data];
          }

          set({
            cardHolder: {
              ...get().cardHolder,
              acsCredentials: updatedCredentials,
            },
          });
        }
        return res;
      },
      updateCredentials: async (route, credentialData) => {
        const res = await updateCredentials(route, credentialData);
        if (res?.data?.meta?.code === 200) {
          const credentialId = res?.data?.data?.credentialId;
          const updatedCredentials = get().cardHolder?.acsCredentials.map(
            (cred) => {
              if (cred.credentialId === credentialId) {
                return res?.data?.data;
              }
              return cred;
            },
          );

          set({
            cardHolder: {
              ...get().cardHolder,
              acsCredentials: updatedCredentials,
            },
          });
        }
        return res;
      },

      deleteCardHolderCredential: async (route, credentialId) => {
        const res = await deleteCredential(route);
        const updatedCredentials = get().cardHolder?.acsCredentials?.filter(
          (cred) => cred.credentialId !== credentialId,
        );
        set({
          cardHolder: {
            ...get().cardHolder,
            acsCredentials: updatedCredentials,
          },
        });
        return res;
      },
      resetCardHolder: () => {
        set({
          cardHolder: {},
        });
      },
    }),
    {
      name: 'net.duclo.vms.cardholder',
      version: '1.0',
    },
  ),
);
