import { lazy } from 'react';
import withSuspense from '../withSuspense';

const AllControllers = withSuspense(
  lazy(() => import('../../pages/controllers/AllControllers'))
);

const ManageControllers = withSuspense(
  lazy(() => import('../../pages/controllers/ManageControllers'))
);

const ConfigureController = withSuspense(
  lazy(() => import('../../pages/controllers/ConfigureController'))
);

export const controllerRoutes = [
  { path: 'manage.html', element: <AllControllers /> },
  { path: 'new.html', element: <ManageControllers /> },
  { path: 'configure.html', element: <ConfigureController /> },
];
