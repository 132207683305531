import { Utils } from '../../../helpers';
import axiosClient from '../axiosClient';

const addAccessPolicy = (route, body) =>
  axiosClient.post(route, body, Utils.requestHeader());

const updateAccessPolicy = (route, body) =>
  axiosClient.put(route, body, Utils.requestHeader());

const attachDoorsScheduleToPolicy = (route, body) =>
  axiosClient.post(route, body, Utils.requestHeader());
const getOrgPolicies = (route) => axiosClient.get(route, Utils.requestHeader());

const attachDetachDoorsScheduleToPolicy = (route, body) =>
  axiosClient.put(route, body, Utils.requestHeader());

const getAccessPointPolicy = (route) =>
  axiosClient.get(route, Utils.requestHeader());

const getSingleAccessPolicy = (route) =>
  axiosClient.get(route, Utils.requestHeader());

const deleteSingleAccessPolicy = (route) =>
  axiosClient.delete(route, Utils.requestHeader());

const attachPolicyToGroup = (route) =>
  axiosClient.post(route, Utils.requestHeader());

const removePolicyToGroup = (route) =>
  axiosClient.put(route, Utils.requestHeader());

export {
  addAccessPolicy,
  updateAccessPolicy,
  getSingleAccessPolicy,
  getOrgPolicies,
  attachDoorsScheduleToPolicy,
  getAccessPointPolicy,
  attachDetachDoorsScheduleToPolicy,
  deleteSingleAccessPolicy,
  attachPolicyToGroup,
  removePolicyToGroup,
};
