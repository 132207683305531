import { useGlobalApi } from '../../services/hooks/useGlobalApi';
import useRoutingApiPaths from '../useRoutingApiPaths';
import { fetchSignedUrl } from '../../services/api/cardholders';
import { apiMessages } from '../../helpers';
import useCustomRoles from '../../store/CustomRolesStore';

const useOrganization = () => {
  const { executeAsyncOperation } = useGlobalApi();
  const path = useRoutingApiPaths();
  const { setOrganizationRoles } = useCustomRoles();

  const fetchOrganizationRoles = async () => {
    const res = await executeAsyncOperation(
      setOrganizationRoles,
      null,
      path.ORGANIZATION.orgRoles,
    );
  };

  return {
    fetchOrganizationRoles,
  };
};

export default useOrganization;
