import ReactSlider from 'react-slider';
import { ErrorMessage } from 'formik';

const RangeInputField = ({
  classes,
  label,
  fieldName,
  currentValue,
  disabled,
  changeHandler,
  minValue = 1,
  maxValue = 100,
  minValueLabel,
  maxValueLabel,
  curValueLabel,
  isErrorMessage = true,
  thumbLabelHide = false,
  ...props
}) => {
  return (
    <div className={`range-field-wrap ${classes ? classes : ''}`}>
      <div className="range-field-container">
        {label && <label className="range-field-label">{label}</label>}
        <ReactSlider
          name={fieldName}
          value={currentValue}
          min={minValue}
          max={maxValue}
          className="range-slider"
          trackClassName="range-slider-track"
          thumbClassName="range-slider-thumb"
          renderTrack={(props, state) => (
            <div {...props}>
              {state?.index === 0 && (
                <span className="range-slider-start-value">
                  {minValueLabel ? minValueLabel : minValue}
                </span>
              )}
              {state?.index === 1 && (
                <span className="range-slider-last-value">
                  {maxValueLabel ? maxValueLabel : maxValue}
                </span>
              )}
            </div>
          )}
          renderThumb={(props, state) => (
            <div {...props}>
              {state?.valueNow !== minValue && state?.valueNow !== maxValue && !thumbLabelHide && (        
               <span className="range-slider-thumb-value">
                  {curValueLabel ? curValueLabel : state.valueNow}
                </span>
              )}
            </div>
          )}
          onAfterChange={changeHandler}
        />
        {isErrorMessage && <ErrorMessage component="div" name={fieldName} className="error" />}
      </div>
    </div>
  );
};

export default RangeInputField;
