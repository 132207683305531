import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';

function CustomTabsPanel({ tabs, activeTab, setActiveTab }) {
  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        value={activeTab}
        onChange={(event, newValue) => setActiveTab(newValue)}
        aria-label="Custom Tab Panel"
        TabIndicatorProps={{
          style: {
            backgroundColor: 'black',
          },
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            disableRipple
            sx={{
              textTransform: 'none',
              fontWeight: activeTab === index ? 'bold' : 'normal',
              color: 'black',
              '&.Mui-selected': {
                color: 'black',
              },
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
}

export default CustomTabsPanel;
