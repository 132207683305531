import {
  getAllDevicesData,
  getDoNotRefreshDeviceList,
  setAllDevicesData,
  setDeviceInformation,
} from '../store/reducers/AccountReducer';
import Store from '../store/Store';

/**
 *
 * @param {*} MQTT data, device data, and updateType (custOrgDevices & devices)
 * @returns updated device data
 */

const updateDeviceDataByMqtt = (data, currentDeviceId) => {
  const deviceList = Store.getState(getAllDevicesData)?.accounts?.allDevices;
  const doNotRefreshDeviceList = Store.getState(getDoNotRefreshDeviceList)?.accounts?.doNotRefreshDeviceList;
  if (deviceList === null) {
    return null;
  }

  const resource = data?.msg?.resource;
  const connectionStatus = data?.msg?.properties?.connectionStatus;
  const deviceStatus = data?.msg?.properties?.deviceStatus;
  if (resource?.includes('device/') || resource?.includes('devices/')) {
    const deviceAttributes = resource.split('/');
    if (Array.isArray(deviceAttributes)) {
      const deviceId = deviceAttributes[deviceAttributes.length - 1];
      if (deviceId && deviceStatus) {
        let updatedData = updateDevicesData(
          'deviceStatus',
          deviceStatus,
          deviceId,
          deviceList
        );
        if (currentDeviceId) {
          const currentDeviceDetail = updatedData?.find(
            (device) => device.deviceId === currentDeviceId
          );
          if (currentDeviceDetail) {
            Store.dispatch(setDeviceInformation(currentDeviceDetail));
          }
        }
        if (!doNotRefreshDeviceList) {
          Store.dispatch(setAllDevicesData(updatedData));
        }
      } else if (deviceId && connectionStatus) {
        let updatedData = updateDevicesData(
          'connectionStatus',
          connectionStatus,
          deviceId,
          deviceList
        );
        if (currentDeviceId) {
          const currentDeviceDetail = updatedData?.find(
            (device) => device.deviceId === currentDeviceId
          );
          if (currentDeviceDetail) {
            Store.dispatch(setDeviceInformation(currentDeviceDetail));
          }
        }
        if (!doNotRefreshDeviceList) {
          Store.dispatch(setAllDevicesData(updatedData));
        }
      }
    }
  }
  return null;
};

const updateDevicesData = (fieldName, fieldValue, deviceId, devicesData) => {
  let deviceData = devicesData?.map((curDevice) => {
    if (deviceId === curDevice.deviceId) {
      if (fieldName === 'connectionStatus') {
        return {
          ...curDevice,
          connectionStatus: fieldValue,
        };
      } else if (fieldName === 'deviceStatus') {
        return {
          ...curDevice,
          deviceStatus: fieldValue,
        };
      }
    }
    return curDevice;
  });
  return deviceData;
};

const updateCustomerOrgDevicesDataByMqtt = (orgData, data) => {
  if (orgData === null) {
    return null;
  }

  const resource = data?.msg?.resource;
  const connectionStatus = data?.msg?.properties?.connectionStatus;
  const deviceStatus = data?.msg?.properties?.deviceStatus;
  if (resource?.includes('device/') || resource?.includes('devices/')) {
    const deviceAttributes = resource.split('/');
    if (Array.isArray(deviceAttributes)) {
      const deviceId = deviceAttributes[deviceAttributes.length - 1];
      if (deviceId && deviceStatus) {
        let updatedData = updateCustomerOrgDevicesData(
          'deviceStatus',
          deviceStatus,
          deviceId,
          orgData
        );
        return updatedData;
      } else if (deviceId && connectionStatus) {
        let updatedData = updateCustomerOrgDevicesData(
          'connectionStatus',
          connectionStatus,
          deviceId,
          orgData
        );
        return updatedData;
      }
    }
  }
  return null;
};

const updateCustomerOrgDevicesData = (
  fieldName,
  fieldValue,
  deviceId,
  orgData
) => {
  let updatedOrgData = orgData?.map((curOrg) => {
    let deviceData = curOrg?.devices?.map((curDevice) => {
      if (deviceId === curDevice?.deviceId) {
        if (fieldName === 'connectionStatus') {
          return {
            ...curDevice,
            connectionStatus: fieldValue,
          };
        } else if (fieldName === 'deviceStatus') {
          return {
            ...curDevice,
            deviceStatus: fieldValue,
          };
        }
      }
      return curDevice;
    });
    return {
      ...curOrg,
      devices: deviceData,
    };
  });
  return updatedOrgData;
};

export { updateDeviceDataByMqtt, updateCustomerOrgDevicesDataByMqtt };
