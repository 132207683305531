import PrivateRoute from '../auth/PrivateRoute';
import PublicRoute from '../auth/PublicRoute';
import SignIn from '../auth/SignIn';
import CardHolders from '../pages/cardholders';
import Locations from '../pages/locations';
import Doors from '../pages/doors';
import Controllers from '../pages/controllers';
import Schedules from '../pages/schedules';
import AccessPolicies from '../pages/accesspolicies';
import Zones from '../pages/zones';
import Alarms from '../pages/alarms';
import Elevators from '../pages/elevators';

import { EditOrganization, Organizations } from '../pages/organizations';
import { Organizations as Customers } from '../pages/organizations';
import { Users } from '../pages/users';
import { cardHolderRoutes } from './cardholders';
import { customerRoutes } from './customers';
import { locationRoutes } from './locations';
import { userRoutes } from './users';
import { doorRoutes } from './doors';
import { controllerRoutes } from './controllers';
import { scheduleRoutes } from './schedules';
import { AccountSettings, UpdatePassword } from '../pages/settings';
import { organizationRoutes } from './oraganizations';
import { accessPoliciesRoutes } from './accesspolicies';
import CardHolderGroups from '../pages/cardholderGroups';
import { cardHolderGroupsRoutes } from './cardholdergroups';
import BecameAcustomer from '../pages/marketing/BecameAcustomer';
import BecameApartner from '../pages/marketing/BecameApartner';
import SuccessPartner from '../pages/marketing/SuccessPartner';
import SuccessCustomer from '../pages/marketing/SuccessCustomer';
import AccountVerify from '../auth/AccountVerify';
import Actions from '../pages/actions';
import { zonesRoutes } from './zones';
import { alarmsRoutes } from './alarms';
import PageNotFound from '../pages/comman/PageNotFound';
import { actionsRoutes } from './actions';
import { elevatorRoutes } from './elevators';
const routes = [
  {
    path: '/',
    element: <PublicRoute />,
    children: [
      { path: '/', element: <SignIn /> },
      { path: 'login', element: <SignIn /> },
      { path: 'becomeacustomer', element: <BecameAcustomer /> },
      { path: 'createpartnerorg', element: <BecameApartner /> },
      { path: 'successpartner', element: <SuccessPartner /> },
      { path: 'successcustomer', element: <SuccessCustomer /> },
    ],
  },
  {
    path: '/',
    element: <PrivateRoute privateLayout={false} />,
    children: [
      {
        path: 'accountVerify',
        element: <AccountVerify />,
      },
      {
        path: 'accountVerify.html/:invitationCode',
        element: <AccountVerify />,
      },
    ],
  },
  {
    path: '/',
    element: <PrivateRoute activeMenu={'0-0'} />,
    children: [
      {
        path: 'users',
        element: <Users />,
        children: userRoutes,
      },
    ],
  },
  {
    path: '/',
    element: <PrivateRoute />,
    children: [
      {
        path: 'customers',
        element: <Customers />,
        children: customerRoutes,
      },
    ],
  },
  {
    path: '/',
    element: <PrivateRoute activeMenu={'3-0'} />,
    children: [
      {
        path: 'cardholders',
        element: <CardHolders />,
        children: cardHolderRoutes,
      },
    ],
  },
  {
    path: '/cardholdergroups',
    element: (
      <PrivateRoute activeMenu="3-1">
        <CardHolderGroups />
      </PrivateRoute>
    ),
    children: cardHolderGroupsRoutes,
  },
  {
    path: '/',
    element: <PrivateRoute />,
    children: [
      {
        path: 'accountsettings.html',
        element: <AccountSettings />,
      },
      {
        path: 'updatepassword.html',
        element: <UpdatePassword />,
      },
      {
        path: 'editOrganization.html',
        element: <EditOrganization />,
      },
    ],
  },
  {
    path: '/',
    element: (
      <PrivateRoute>
        <Organizations />
      </PrivateRoute>
    ),
    children: organizationRoutes,
  },
  {
    path: '/accesspolicies',
    element: (
      <PrivateRoute activeMenu="">
        <AccessPolicies />
      </PrivateRoute>
    ),
    children: accessPoliciesRoutes,
  },
  {
    path: '/locations',
    element: (
      <PrivateRoute activeMenu="0-1">
        <Locations />
      </PrivateRoute>
    ),
    children: locationRoutes,
  },
  {
    path: '/doors',
    element: (
      <PrivateRoute activeMenu="1-2">
        <Doors />
      </PrivateRoute>
    ),
    children: doorRoutes,
  },
  {
    path: '/elevators',
    element: (
      <PrivateRoute activeMenu="1-3">
        <Elevators />
      </PrivateRoute>
    ),
    children: elevatorRoutes,
  },
  {
    path: '/controllers',
    element: (
      <PrivateRoute activeMenu="1-0">
        <Controllers />
      </PrivateRoute>
    ),
    children: controllerRoutes,
  },
  {
    path: '/schedules',
    element: (
      <PrivateRoute activeMenu="0-3">
        <Schedules />
      </PrivateRoute>
    ),
    children: scheduleRoutes,
  },
  {
    path: '/zones',
    element: (
      <PrivateRoute activeMenu="1-1">
        <Zones />
      </PrivateRoute>
    ),
    children: zonesRoutes,
  },
  {
    path: '/alarms',
    element: (
      <PrivateRoute activeMenu="0-2">
        <Alarms />
      </PrivateRoute>
    ),
    children: alarmsRoutes,
  },
  {
    path: '/actions',
    element: (
      <PrivateRoute activeMenu="2-1">
        <Actions />
      </PrivateRoute>
    ),
    children: actionsRoutes,
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
];

export default routes;
