import React from 'react';
import { Button } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';

const EllipsisButton = () => {
  return (
    <Button
      variant="outlined"
      sx={{
        borderRadius: '12px',
        minWidth: '40px',
        minHeight: '40px',
        padding: '0',
        borderColor: 'black',
        color: 'black',
        '&:hover': {
          borderColor: 'black',
          backgroundColor: 'transparent',
        },
      }}
    >
      <MoreHoriz />
    </Button>
  );
};

export default EllipsisButton;
