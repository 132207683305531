import { lazy } from 'react';
import withSuspense from '../withSuspense';

const ManageElevators = withSuspense(
  lazy(() => import('../../pages/elevators/ManageElevators'))
);

const CreateElevator = withSuspense(
  lazy(() => import('../../pages/elevators/CreateElevator/CreateElevator'))
);

export const elevatorRoutes = [
  { path: 'manage.html', element: <ManageElevators /> },
  { path: 'new.html', element: <CreateElevator /> },
];
