import axios from 'axios';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_NEW_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('vmsAuthToken'); // Assuming token is stored in localStorage

    if (token !== 'undefined') {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default axiosClient;
