import { lazy } from 'react';
import withSuspense from '../withSuspense';

const ManageUsers = withSuspense(
  lazy(() =>
    import('../../pages/users').then((module) => ({
      default: module.ManageUsers,
    }))
  )
);

export const userRoutes = [{ path: 'manage.html', element: <ManageUsers /> }];
