import React, { useState } from 'react';
import ResponsiveStack from '../../../components/common/SiderBarDrawer/ResponsiveStack';
import { Chip, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ReusableTable from '../../../components/common/ReusableTable';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import useCardHolderGroups from '../../../hooks/cardholderGroups/useCardHolderGroups';
import CustomLoadingButton from '../../../components/forms/CustomLoadingButton';
import { DeleteOutline } from '@mui/icons-material';

const policyStatus = [
  { status: 'allow', style: { color: '#127320', bgColor: '#D6F0DA' } },
  { status: 'deny', style: { color: '#8B2E2E', bgColor: '#F0D6D6' } },
  { status: 'inactive', style: { color: '#141414', bgColor: '#D3D3D3' } },
];
const StatusChip = ({ permission }) => {
  const statusStyle =
    policyStatus.find((item) => item.status === permission)?.style || {};
  return (
    <Chip
      label={permission?.charAt(0).toUpperCase() + permission?.slice(1)}
      sx={{
        color: statusStyle.color,
        backgroundColor: statusStyle.bgColor,
      }}
    />
  );
};

const PolicyListTable = ({ getCardHolderGroupAccessPolicies }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('policyName');
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(null);

  const { deleteAccessPolicyFromGroup } = useCardHolderGroups();
  const groupId = searchParams.get('groupId');

  const handleDelete = async (row) => {
    setLoading(row?.policyId);
    await deleteAccessPolicyFromGroup(row?.policyId, groupId);
    setLoading(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const columns = [
    {
      id: 'policyName',
      label: 'Access Policies',
      sortable: true,
      render: (row) => (
        <ResponsiveStack direction="column" spacing={0}>
          <Typography variant="customSubTitleBold">
            {row?.policyName}
          </Typography>
          <Typography variant="customSubTitleDisable48">
            {row?.description}
          </Typography>
        </ResponsiveStack>
      ),
    },
    {
      id: 'permission',
      label: 'Access',
      render: (row) => <StatusChip permission={row?.permission} />,
    },
    {
      id: 'type',
      label: 'Status',
      render: (row) => (
        <>
          {row?.type && (
            <Chip
              sx={{
                backgroundColor: 'transparent',
                borderRadius: '8px',
                border: `1px solid var(--greyscale_64)`,
                textTransform: 'capitalize',
                fontFamily: 'Noto Sans',
              }}
              label={row?.type}
            ></Chip>
          )}
        </>
      ),
    },
    {
      id: 'startDate',
      label: 'Start Date',
      render: (row) => (
        <ResponsiveStack direction="column" spacing={0}>
          <Typography variant="customSubTitleDisable48">
            {row?.startDate &&
              `${dayjs.unix(row?.startDate).format('MMM DD, YYYY')} `}
          </Typography>
        </ResponsiveStack>
      ),
    },
    {
      id: 'endDate',
      label: 'End Date',
      render: (row) => (
        <ResponsiveStack direction="column" spacing={0}>
          <Typography variant="customSubTitleDisable48">
            {row?.endDate &&
              `${dayjs.unix(row?.endDate).format('MMM DD, YYYY')} `}
          </Typography>
        </ResponsiveStack>
      ),
    },
    {
      id: '',
      label: '',
      align: 'right',
      render: (row) => (
        <CustomLoadingButton
          loading={loading === row.policyId}
          onClick={() => handleDelete(row)}
        >
          <DeleteOutline />
        </CustomLoadingButton>
      ),
    },
  ];

  return (
    <>
      <ReusableTable
        columns={columns}
        data={getCardHolderGroupAccessPolicies()}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
      />
    </>
  );
};

export default PolicyListTable;
