import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { HiArrowDownTray } from 'react-icons/hi2';
import { styled } from '@mui/material/styles';
import {
  List,
  ListItem,
  ListItemText,
  Drawer as MuiDrawer,
} from '@mui/material';
import './SideBarDrawer.scss';
import { useNavigate } from 'react-router-dom';
import { useOrganizations } from '../../../store/OrganizationsStore';

const drawerWidth = 224;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const SideBarDrawer = ({ activeMenu }) => {
  const [open, setOpen] = useState(true);
  const [isOpenBeforeDragOver, setIsOpenBeforeDragOver] = useState(true);
  const [showDeleteModal] = useState(false);
  const [showMaxDevciesModal] = useState(false);
  const [showJumpToDateModal] = useState(false);
  const [isRenamingView] = useState(false);
  const [editView] = useState(null);
  const [activeIndex, setActiveIndex] = useState(activeMenu);
  const navigate = useNavigate();
  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData
  );

  const handleDrawerOpen = () => {
    setOpen(true);
    setIsOpenBeforeDragOver(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setIsOpenBeforeDragOver(false);
  };

  const handleListItemClick = (index, child) => {
    setActiveIndex(index);
    navigate(child.link);
  };

  const sidebarMenu = [
    {
      parentMenu: 'System Management',
      children: [
        {
          name: 'Users',
          link: `/users/manage.html?orgId=${getCustomerOrgData()[0]?.orgId}`,
        },
        { name: 'Locations', link: '/locations/manage.html' },
        { name: 'Alarms', link: '/alarms/manage.html' },
        { name: 'Schedules', link: '/schedules/manage.html' },
      ],
    },
    {
      parentMenu: 'Device Management',
      children: [
        { name: 'Controllers', link: '/controllers/manage.html' },
        { name: 'Zones', link: '/zones/manage.html' },
        { name: 'Doors', link: '/doors/manage.html' },
        { name: 'Elevators', link: '/elevators/manage.html' },
      ],
    },
    {
      parentMenu: 'Event Management',
      children: [{ name: 'Events of Action', link: '/actions' }],
    },
    {
      parentMenu: 'Key Management',
      children: [
        {
          name: 'Cardholders',
          link: `/cardholders/manage.html?orgId=${
            getCustomerOrgData()[0]?.orgId
          }`,
        },
        {
          name: 'Cardholders Groups',
          link: `/cardholdergroups`,
        },
        {
          name: 'Access Level',
          link: `/accesspolicies/manage.html?orgId=${
            getCustomerOrgData()[0]?.orgId
          }`,
        },
      ],
    },
  ];

  return (
    <div className="App">
      <Drawer
        variant="permanent"
        open={open}
        className={`device-wall-drawer ${
          showDeleteModal || showMaxDevciesModal || showJumpToDateModal
            ? 'popup-opacity'
            : ''
        }`}
        onMouseEnter={() => setOpen(true)}
        onDragOver={() => {
          setOpen(!isOpenBeforeDragOver);
        }}
        onDragLeave={() => {
          setOpen(isOpenBeforeDragOver);
        }}
      >
        {' '}
        <DrawerHeader className="device-wall-drawer-header">
          <div
            className={`device-wall-drawer-header-title ${
              open ? 'visible' : 'hidden'
            } ${isRenamingView || editView ? 'disabled' : ''}`}
          >
            Dashboard
          </div>
          <IconButton
            className="device-wall-drawer-header-toggle-icon"
            onClick={() => {
              open ? handleDrawerClose() : handleDrawerOpen();
            }}
            disabled={isRenamingView || editView}
          >
            {open ? (
              <HiArrowDownTray
                className="device-wall-drawer-toggle-icon toggle-close"
                size={'24'}
                color={
                  isRenamingView || editView
                    ? getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_white')
                    : getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_black')
                }
              />
            ) : (
              <HiArrowDownTray
                className="device-wall-drawer-toggle-icon toggle-open"
                size={'24'}
                color={
                  isRenamingView || editView
                    ? getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_white')
                    : getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_black')
                }
              />
            )}
          </IconButton>
        </DrawerHeader>
        <List className="drawerList">
          {sidebarMenu.map((menu, index) => (
            <React.Fragment key={index}>
              <ListItem>
                <ListItemText
                  primary={menu.parentMenu.toUpperCase()}
                  className="listItemPrimary"
                />
              </ListItem>
              <List component="nav" disablePadding className="listOfItems">
                {menu.children.map((child, childIndex) => {
                  const listIndex = `${index}-${childIndex}`;
                  return (
                    <ListItem
                      button
                      sx={{ pl: 4 }}
                      key={childIndex}
                      className="active"
                      component="a"
                      onClick={() => handleListItemClick(listIndex, child)}
                    >
                      <ListItemText
                        primary={child.name}
                        classes={{
                          primary:
                            activeIndex === listIndex ? 'active-text' : '',
                        }}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </React.Fragment>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default SideBarDrawer;
