import { lazy } from 'react';
import withSuspense from '../withSuspense';

const ManageLocations = withSuspense(
  lazy(() => import('../../pages/locations/ManageLocations'))
);

const ManageAreas = withSuspense(
  lazy(() => import('../../pages/locations/ManageAreas'))
);

export const locationRoutes = [
  { path: 'manage.html', element: <ManageLocations /> },
  {
    path: 'areas.html',
    element: <ManageAreas />,
  },
];
