import React, { useState } from 'react';
import { Avatar, Box, Chip, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ReusableTable from '../../../components/common/ReusableTable';
import { useCardHolderGroupStore } from '../../../store/cardHolderGroupStore';
import { Link } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import useCardHolderGroups from '../../../hooks/cardholderGroups/useCardHolderGroups';
import CustomLoadingButton from '../../../components/forms/CustomLoadingButton';
import { DeleteOutline } from '@mui/icons-material';

const policyStatus = [
  { status: 'allow', style: { color: '#127320', bgColor: '#D6F0DA' } },
  { status: 'deny', style: { color: '#8B2E2E', bgColor: '#F0D6D6' } },
  { status: 'inactive', style: { color: '#141414', bgColor: '#D3D3D3' } },
];
const StatusChip = ({ permission }) => {
  const statusStyle =
    policyStatus.find((item) => item.status === permission)?.style || {};
  return (
    <Chip
      label={permission?.charAt(0).toUpperCase() + permission?.slice(1)}
      sx={{
        color: statusStyle.color,
        backgroundColor: statusStyle.bgColor,
      }}
    />
  );
};

const CardHolderListTable = () => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('policyName');
  const [loading, setLoading] = useState(null);
  const { getAttachedCardHolders } = useCardHolderGroupStore();
  const { deleteCardHolderFromGroup } = useCardHolderGroups();
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get('groupId');
  const handleDelete = async (row) => {
    setLoading(row?.cardHolderId);
    await deleteCardHolderFromGroup(groupId, row?.cardHolderId);
    setLoading(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const columns = [
    {
      id: 'firstName',
      label: 'Cardholders',
      sortable: true,
      render: (user) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{ mr: 2 }}
            src={`${user?.preSignUrl?.url}`}
          >{`${user?.firstName[0]}${user?.lastName[0]}`}</Avatar>
          <Box
            component={Link}
            to={`/cardholders/profile.html?cardHolderId=${user.cardHolderId}`}
            sx={{
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            <Typography variant="customSubTitleBold">{`${user.firstName} ${user.lastName}`}</Typography>
          </Box>
        </Box>
      ),
    },
    {
      id: 'createdBy',
      label: 'Assigned By',
      render: (row) => (
        <Typography variant="customSubTitleDisable48">
          {row?.createdBy}
        </Typography>
      ),
    },

    {
      id: '',
      label: '',
      align: 'right',
      render: (row) => (
        <CustomLoadingButton
          loading={loading === row.cardHolderId}
          onClick={() => handleDelete(row)}
        >
          <DeleteOutline />
        </CustomLoadingButton>
      ),
    },
  ];

  return (
    <>
      <ReusableTable
        columns={columns}
        data={getAttachedCardHolders()}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
      />
    </>
  );
};

export default CardHolderListTable;
