import React, { useEffect, useState } from 'react';
import ResponsiveStack from '../../../components/common/SiderBarDrawer/ResponsiveStack';
import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

// const CardHolderListing = ({ policies, setPolicyIds, policyIds = [] }) => {
const CardHolderListing = ({
  cardHolders,
  setCardHolderIds,
  cardHolderIds = [],
}) => {
  const [selectedPolicies, setSelectedPolicies] = useState(null);
  const [selectedCardHolders, setSelectedCardHolders] = useState(null);
  const policyStatus = [
    { status: 'allow', style: { color: '#127320', bgColor: '#D6F0DA' } },
    { status: 'deny', style: { color: '#8B2E2E', bgColor: '#F0D6D6' } },
    { status: 'inactive', style: { color: '#141414', bgColor: '#D3D3D3' } },
  ];

  const StatusChip = ({ permission }) => {
    const statusStyle =
      policyStatus.find((item) => item.status === permission)?.style || {};
    return (
      <Chip
        label={permission?.charAt(0).toUpperCase() + permission?.slice(1)}
        sx={{
          color: statusStyle.color,
          backgroundColor: statusStyle.bgColor,
        }}
      />
    );
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allCardHolderIds = cardHolders?.map(
        (cardHolder) => cardHolder.cardHolderId,
      );
      setSelectedCardHolders(allCardHolderIds);
      setCardHolderIds(allCardHolderIds);
    } else {
      setSelectedCardHolders([]);
      setCardHolderIds([]);
    }
  };

  const handlePolicy = (cardHolderId) => {
    setSelectedCardHolders((prevSelected) =>
      prevSelected.includes(cardHolderId)
        ? prevSelected.filter((id) => id !== cardHolderId)
        : [...prevSelected, cardHolderId],
    );
    setCardHolderIds((prevSelected) =>
      prevSelected.includes(cardHolderId)
        ? prevSelected.filter((id) => id !== cardHolderId)
        : [...prevSelected, cardHolderId],
    );
  };
  const isPolicySelected = (cardHolderId) =>
    selectedCardHolders?.includes(cardHolderId);
  const isAllSelected =
    selectedCardHolders?.length > 0 &&
    selectedCardHolders?.length === cardHolders?.length;

  useEffect(() => {
    setSelectedCardHolders(cardHolderIds);
  }, [cardHolderIds]);

  return (
    <>
      <ResponsiveStack direction="row" spacing={0} alignItems="center">
        <Checkbox checked={isAllSelected} onChange={handleSelectAll}></Checkbox>
        <Typography variant="customSubTitleBold">
          {selectedCardHolders?.length} of {cardHolders?.length} Selected
        </Typography>
      </ResponsiveStack>
      <Box
        sx={{
          borderRadius: '8px',
          border: '1px solid',
          p: '6px 4px',
          width: '100%',
        }}
        height="350px"
        overflow="scroll"
      >
        <ResponsiveStack direction="column" spacing={1.5} sx={{ p: '8px' }}>
          {cardHolders &&
            cardHolders?.length > 0 &&
            cardHolders?.map((cardHolder) => (
              <>
                <ResponsiveStack
                  direction="row"
                  spacing={0}
                  key={cardHolder?.cardHolderId}
                  alignItems="center"
                >
                  <Checkbox
                    size="small"
                    checked={isPolicySelected(cardHolder?.cardHolderId)}
                    onChange={() => handlePolicy(cardHolder?.cardHolderId)}
                  ></Checkbox>
                  <FormControl fullWidth>
                    <ResponsiveStack direction="column" spacing={0}>
                      <Box display="flex" alignItems="center">
                        <Avatar
                          sx={{ mr: 2 }}
                          src={`${cardHolder?.preSignUrl?.url}`}
                        >{`${cardHolder?.firstName[0]}${cardHolder?.lastName[0]}`}</Avatar>
                        <Box
                          component={Link}
                          to={`/cardholders/profile.html?cardHolderId=${cardHolder.cardHolderId}`}
                          sx={{
                            textDecoration: 'none',
                            color: 'inherit',
                          }}
                        >
                          <Typography variant="customSubTitleBold">{`${cardHolder.firstName} ${cardHolder.lastName}`}</Typography>
                        </Box>
                      </Box>
                    </ResponsiveStack>
                  </FormControl>
                </ResponsiveStack>
                <Divider variant="middle" sx={{ backgroundColor: '#000' }} />
              </>
            ))}
        </ResponsiveStack>
      </Box>
    </>
  );
};

export default CardHolderListing;
