import React, { Suspense } from 'react';
import { SiteSpinner } from '../components/common';
import { Box } from '@mui/material';

const Loading = () => (
  <Box
    width="100%"
    alignItems="center"
    display="flex"
    justifyContent="center"
    height="100vh"
  >
    <SiteSpinner height="50px" width="50px"></SiteSpinner>
  </Box>
);

const withSuspense = (Component) => {
  return (props) => (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  );
};

export default withSuspense;
