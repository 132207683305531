import { useState, useRef } from 'react';
import './SignUp.scss';
import { Alert, Container, Col, Form, Row } from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import 'bootstrap/dist/css/bootstrap.css';
import { Link, useNavigate } from 'react-router-dom';
import { GoSearch } from 'react-icons/go';
import { HiOutlineMail } from 'react-icons/hi';
import { SlCallEnd } from 'react-icons/sl';
import { BiWorld } from 'react-icons/bi';
import { CiLinkedin } from 'react-icons/ci';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import Autocomplete from 'react-google-autocomplete';
import ReCAPTCHA from 'react-google-recaptcha';
import { Header, PrimaryButton, TextBlock } from '../../components/common';
import timezones from '../../data/support/timezone.json';
import { TextField, SelectField } from '../../components/forms';
import { PiWarningCircleBold } from 'react-icons/pi';
import { Utils, constants, AppDefaults, roles } from '../../helpers';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { IoClose } from 'react-icons/io5';
import axiosClient from '../../services/api/axiosClient';

// Schema for yup
const validationSchema = Yup.object().shape({
  organization: Yup.object().shape({
    orgName: Yup.string()
      .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
      .matches(/^[a-zA-Z0-9 ]+$/, constants.ONLY_ALPHA_NUMBERIC_ERROR_MESSAGE)
      .required(constants.NAME_REQUIRED_ERROR_MESSAGE),
    shortName: Yup.string()
      .required(constants.SHORT_NAME_REQUIRED_ERROR_MESSAGE)
      .min(6, constants.SHORT_NAME_MIN_LENGTH_ERROR_MESSAGE)
      .max(12, constants.SHORT_NAME_MAX_LENGTH_ERROR_MESSAGE)
      .matches(
        /^[a-zA-Z0-9]+$/,
        constants.SHORT_NAME_NO_SPECIAL_CHARACTERS_ERROR_MESSAGE,
      ),
    addressLine1: Yup.string().required(
      constants.ADDRESS_REQUIRED_ERROR_MESSAGE,
    ),
    orgWebsite: Yup.string().matches(
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/i,
      constants.WEBSITE_CORRECT_URL_MESSAGE,
    ),
    orgLinkedInProfile: Yup.string().matches(
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/i,
      constants.WEBSITE_CORRECT_URL_MESSAGE,
    ),
  }),
  account: Yup.object().shape({
    email: Yup.string()
      .email(constants.EMAIL_REQUIRED_ERROR_MESSAGE)
      .max(100, constants.EMAIL_REQUIRED_CANT_LESS_ERROR_MESSAGE)
      .required(constants.EMAIL_REQUIRED_ERROR_MESSAGE),
    firstName: Yup.string()
      .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
      .matches(/^[a-zA-Z ]+$/, constants.ONLY_ALPHABETS_ERROR_MESSAGE)
      .required(constants.FIRST_NAME_REQUIRED_ERROR_MESSAGE),
    lastName: Yup.string()
      .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
      .matches(/^[a-zA-Z ]+$/, constants.ONLY_ALPHABETS_ERROR_MESSAGE)
      .required(constants.LAST_NAME_REQUIRED_ERROR_MESSAGE),
    phoneNumber: Yup.string().matches(
      /^[0-9]{10}$/,
      constants.VALID_PHONE_NUMBER_ERROR_MESSAGE,
    ),
  }),
});

function BecameAcustomer() {
  const { t } = useTranslation();
  const [showLoader, setShowLoader] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptBetaAgreement, setAcceptBetaAgreement] = useState(false);
  const recaptchaRef = useRef();
  const [userMsg, setUserMsg] = useState('');
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const zoneTimeList = timezones?.data;
  const [currentUserTimezone, setCurrentUserTimezone] = useState({});
  const [visibleLocHelperText, setVisibleLocHelperText] = useState(false);
  const [locHelperText, setLocHelperText] = useState('');
  const [isFieldDisabled, setIsFieldDisabled] = useState(true);
  const [shortNameExistsError, setShortNameExistsError] = useState(false);

  const changeTimezoneValue = (evebr, setFieldValue) => {
    let selectedVal = evebr?.target?.value;
    const getLocationSelected = zoneTimeList?.find(
      (zone) => zone.value === selectedVal,
    );
    setCurrentUserTimezone(getLocationSelected);
    setFieldValue('organization.timezone', getLocationSelected?.value);
    setVisibleLocHelperText(false);
  };

  const handleTermsChange = (event) => {
    setAcceptTerms(event.target.checked);
  };

  const disableInput = (event, values) => {
    if (values.organization.addressLine1) {
      event.preventDefault();
      return false;
    }
    return true;
  };
  const tenantID =
    !process.env.REACT_APP_PROJECT && !AppDefaults.PROJECT_MEGATRON
      ? 'dc'
      : process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON
        ? 'hva'
        : 'dc';
  const appID = 'acs';

  const appId = 'acs';
  const checkBetaAgreement = () => {
    if (process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON) {
      if (acceptBetaAgreement) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  /**
   * CALLING ORGNIAZATION SHORT NAME API
   */

  const callOrgShortNameApi = async (val) => {
    if (val) {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}partner/orgs/generateShortName?orgName=${val.organization.orgName}`,
        {
          headers: {
            'X-Correlation-ID': Utils.generateUUID(),
            'X-tenant-id': tenantID,
            'X-app-Id': appID,
          },
        },
      );

      const responseData = response?.data;
      if (responseData?.meta?.code === 200) {
        val.organization.shortName = responseData?.data?.shortName;
        setIsFieldDisabled(false);
      }
    }
  };

  /***
   * SHORT NAME VALIDATION API
   */

  const validateExistingName = async (changedValue, setFieldValue) => {
    try {
      let changedTextValue = changedValue.trim();
      setFieldValue('organization.shortName', changedTextValue);
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}partner/orgs/${changedTextValue}/exists`,
        {
          headers: {
            'X-Correlation-ID': Utils.generateUUID(),
            'X-tenant-id': tenantID,
            'X-app-Id': appID,
          },
        },
      );

      const responseData = response?.data;
      if (responseData?.meta?.code === 200) {
        if (!responseData?.data?.shortNameExists) {
          setShortNameExistsError(false);
        } else {
          setShortNameExistsError(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <div className="marketing-pages">
        <Header
          backgroundColor={getComputedStyle(
            document.documentElement,
          ).getPropertyValue('--primary_96')}
          isAccountScreen={true}
          showNavigation={false}
        />

        <section className="main-page">
          <Container>
            <Row className="justify-content-md-center">
              <Col xs lg="8">
                <Container className="main-page-content mt-5 text-center">
                  <Row>
                    <Col className="remove-padding-responsive">
                      <div className="card main-card remove-padding-responsive">
                        <div className="card-body text-left">
                          <div className="text-center mb-1">
                            <TextBlock
                              fontWeight="500"
                              fontSize="1.25rem"
                              lineHeight="28px"
                            >
                              {constants.CUSTOMER_REGISTER_HEADING}
                            </TextBlock>
                          </div>

                          <div className="text-center mb-2">
                            <TextBlock
                              fontWeight="700"
                              fontSize="1.5rem"
                              lineHeight="32px"
                              color="#111418"
                            >
                              {constants.CUSTOMER_REGISTER_SUB_HEADING}
                            </TextBlock>
                          </div>

                          <div className="text-center mb-4">
                            <TextBlock
                              fontWeight="500"
                              fontSize="0.875rem"
                              lineHeight="20px"
                            >
                              {t('CUSTOMER_REGISTER_FORM_INFO_HEADING')}
                            </TextBlock>
                          </div>

                          <Formik
                            initialValues={{
                              organization: {
                                orgName: '',
                                displayDetails: '',
                                addressLine1: '',
                                addressLine2: '',
                                city: '',
                                state: '',
                                country: '',
                                zipcode: '',
                                orgType: 'CUSTOMER_ORG',
                                partnerLevel: 'BASIC',
                                orgStatus: 'ACTIVE',
                                ein: '00-0000000',
                                tin: '00-0000000',
                                orgWebsite: '',
                                orgLinkedInProfile: '',
                                timezone: currentUserTimezone?.value,
                                shortName: '',
                              },
                              account: {
                                firstName: '',
                                lastName: '',
                                email: '',
                                accountLanguage: 'EN',
                                role: roles.ROLE2VMS,
                                phoneNumber: '',
                              },
                            }}
                            // Hooks up our validationSchema to Formik
                            validationSchema={validationSchema}
                            onSubmit={async (values, { setSubmitting }) => {
                              // When button submits form and form is in the process of submitting, submit button is disabled
                              setSubmitting(true);
                              setShowLoader(true);

                              const recaptchaValue =
                                await recaptchaRef.current.executeAsync();

                              if (recaptchaValue) {
                                const emailData = values?.account?.email;
                                let yourFormattedEmail =
                                  emailData.toLocaleLowerCase();
                                const updatedValues = {
                                  ...values,
                                  account: {
                                    ...values?.account,
                                    email: yourFormattedEmail,
                                  },
                                };

                                // Simulate submitting to database, shows us values submitted, resets form
                                axiosClient
                                  .post(`partner/orgs/self/v2`, updatedValues, {
                                    headers: {
                                      'X-Correlation-ID': Utils.generateUUID(),
                                      'X-tenant-id': tenantID,
                                      'X-app-Id': appID,
                                    },
                                  })
                                  .then((res) => {
                                    let response = res.data;
                                    if (response?.meta?.code === 200) {
                                      navigate('/successcustomer', {
                                        state: updatedValues,
                                      });
                                      setShowLoader(false);
                                    } else {
                                      setUserMsg(response?.meta?.userMsg);
                                      setShow(true);
                                      setShowLoader(false);
                                    }
                                    setSubmitting(false);
                                  });
                              } else {
                                setUserMsg(constants.CAPTCHA_ERROR_MESSAGE);
                                setShow(true);
                                setSubmitting(false);
                                setShowLoader(false);
                              }
                            }}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              setFieldValue,
                              setFieldTouched,
                              isValid,
                              dirty,
                              isSubmitting,
                            }) => (
                              <Form onSubmit={handleSubmit}>
                                <Row>
                                  <Col lg={6} xs={12}>
                                    <TextField
                                      required={true}
                                      removebottommargin="true"
                                      label=""
                                      placeholder={
                                        constants.FIRST_NAME_FIELD_LABEL
                                      }
                                      name="account.firstName"
                                      type="text"
                                    />
                                  </Col>
                                  <Col lg={6} xs={12}>
                                    <TextField
                                      required={true}
                                      removebottommargin="true"
                                      label=""
                                      placeholder={
                                        constants.LAST_NAME_FIELD_LABEL
                                      }
                                      name="account.lastName"
                                      type="text"
                                    />
                                  </Col>
                                </Row>

                                <TextField
                                  required={true}
                                  beforeinputicon={
                                    <HiOutlineMail size={15} stroke="#566376" />
                                  }
                                  label=""
                                  placeholder={
                                    constants.EMAIL_ADDRESS_FIELD_LABEL
                                  }
                                  name="account.email"
                                  type="text"
                                />

                                <TextField
                                  required={true}
                                  beforeinputicon={
                                    <GoSearch size={15} fill="#566376" />
                                  }
                                  label=""
                                  placeholder={
                                    constants.ORGANIZATION_NAME_FIELD_LABEL
                                  }
                                  name="organization.orgName"
                                  type="text"
                                  onChange={(e) => {
                                    handleChange(e);
                                    setFieldTouched(
                                      'organization.shortName',
                                      false,
                                    );
                                    setFieldValue('organization.shortName', '');
                                    setIsFieldDisabled(true);
                                    setShortNameExistsError(false);
                                  }}
                                  onBlur={(e) => {
                                    callOrgShortNameApi(values);
                                  }}
                                />

                                <TextField
                                  required={true}
                                  label=""
                                  placeholder={constants.ORG_SHORT_NAME}
                                  name="organization.shortName"
                                  type="text"
                                  disabled={isFieldDisabled}
                                  hideError={true}
                                  onChange={(e) => {
                                    validateExistingName(
                                      e.target.value,
                                      setFieldValue,
                                    );
                                  }}
                                />

                                <div className="org-text-hint">
                                  <div>
                                    {shortNameExistsError && (
                                      <div className="error error-parent display-f m-t-15-m">
                                        <AiOutlineExclamationCircle
                                          size={12}
                                          style={{ color: '#dc3545' }}
                                        />
                                        <div className="short-text-error">
                                          {constants.ORG_SHORT_EXISTS}
                                        </div>
                                      </div>
                                    )}
                                    {touched.organization?.shortName &&
                                      errors.organization?.shortName && (
                                        <div className="display-f">
                                          <div>
                                            <AiOutlineExclamationCircle
                                              size={12}
                                              style={{ color: '#dc3545' }}
                                            />
                                          </div>
                                          <div className="short-text-error">
                                            <div className="error error-parent m-t-3">
                                              {errors.organization.shortName}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    {!(
                                      (touched.organization?.shortName &&
                                        errors.organization?.shortName) ||
                                      shortNameExistsError
                                    ) && (
                                      <div className="display-f m-t-15-m">
                                        <div className="m-l-30">
                                          <AiOutlineExclamationCircle
                                            size={15}
                                            stroke="#7D8BA1"
                                          />
                                        </div>
                                        <div>
                                          <div className="w-93">
                                            {constants.ORG_SHORT_NAME_HINT}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="mt-3 mb-3 row">
                                  <div className="col position-relative input-with-icons address-with-reset">
                                    <div>
                                      <div className="icon-before-input">
                                        <GoSearch fill="#566376" size={15} />
                                      </div>
                                      <Autocomplete
                                        name="organization.displayDetails"
                                        // onFocus={
                                        //   (e) => e.target.setAttribute("autoComplete", "none")
                                        // }
                                        placeholder=""
                                        apiKey={
                                          process.env.REACT_APP_GOOGLE_PLACE_URL
                                        }
                                        onChange={(evt) => {
                                          if (!evt.target.value) {
                                            setFieldValue(
                                              'organization.zipcode',
                                              '',
                                            );
                                            setFieldValue(
                                              'organization.country',
                                              '',
                                            );
                                            setFieldValue(
                                              'organization.city',
                                              '',
                                            );
                                            setFieldValue(
                                              'organization.state',
                                              '',
                                            );
                                            setFieldValue(
                                              'organization.addressLine1',
                                              '',
                                            );
                                          }
                                        }}
                                        onBlur={handleBlur}
                                        onKeyDown={(e) =>
                                          disableInput(e, values)
                                        }
                                        id="displayDetails"
                                        options={{
                                          fields: [
                                            'formatted_address',
                                            'geometry',
                                            'name',
                                            'address_components',
                                          ],
                                          strictBounds: false,
                                          types: ['address'],
                                        }}
                                        onPlaceSelected={(place) => {
                                          if (
                                            place?.address_components?.length
                                          ) {
                                            setFieldValue(
                                              'organization.lat',
                                              place?.geometry?.location?.lat(),
                                            );
                                            setFieldValue(
                                              'organization.lng',
                                              place?.geometry?.location?.lng(),
                                            );

                                            let addressComponent,
                                              addressComponentType,
                                              rawzipcode,
                                              rawcountry,
                                              rawcity,
                                              rawstate,
                                              rawaddline;
                                            for (
                                              let i = 0;
                                              i <
                                              place?.address_components?.length;
                                              i++
                                            ) {
                                              addressComponent =
                                                place.address_components[i];
                                              let j = 0;
                                              addressComponentType =
                                                addressComponent.types[j];
                                              if (
                                                addressComponentType ===
                                                'postal_code'
                                              ) {
                                                rawzipcode =
                                                  addressComponent.long_name
                                                    ? addressComponent.long_name
                                                    : '';
                                              }
                                              if (
                                                addressComponentType ===
                                                'country'
                                              ) {
                                                rawcountry =
                                                  addressComponent.long_name
                                                    ? addressComponent.short_name
                                                    : '';
                                              }
                                              if (
                                                addressComponentType ===
                                                'administrative_area_level_1'
                                              ) {
                                                rawstate =
                                                  addressComponent.long_name
                                                    ? addressComponent.short_name
                                                    : '';
                                              }
                                              if (
                                                addressComponentType ===
                                                'locality'
                                              ) {
                                                rawcity =
                                                  addressComponent.long_name
                                                    ? addressComponent.long_name
                                                    : '';
                                              }

                                              if (
                                                addressComponentType ===
                                                  'street_number' ||
                                                addressComponentType ===
                                                  'route' ||
                                                addressComponentType ===
                                                  'neighborhood' ||
                                                addressComponentType.includes(
                                                  'sublocality',
                                                )
                                              ) {
                                                rawaddline = `${
                                                  rawaddline ? rawaddline : ''
                                                }${
                                                  addressComponent.long_name
                                                }, `;
                                              }
                                            }
                                            setFieldValue(
                                              'organization.zipcode',
                                              rawzipcode,
                                            );
                                            setFieldValue(
                                              'organization.country',
                                              rawcountry,
                                            );
                                            setFieldValue(
                                              'organization.city',
                                              rawcity,
                                            );
                                            setFieldValue(
                                              'organization.state',
                                              rawstate,
                                            );
                                            setFieldValue(
                                              'organization.addressLine1',
                                              rawaddline,
                                            );
                                            setFieldValue(
                                              'organization.displayDetails',
                                              (rawaddline ? rawaddline : '') +
                                                (rawcity
                                                  ? rawcity + ', '
                                                  : '') +
                                                (rawstate
                                                  ? rawstate + ', '
                                                  : '') +
                                                (rawcountry
                                                  ? rawcountry + ', '
                                                  : '') +
                                                (rawzipcode ? rawzipcode : ''),
                                            );

                                            if (
                                              place?.geometry?.location?.lat() &&
                                              place?.geometry?.location?.lng()
                                            ) {
                                              var config = {
                                                method: 'get',
                                                url: `https://maps.googleapis.com/maps/api/timezone/json?location=${place?.geometry?.location?.lat()},${place?.geometry?.location?.lng()}&timestamp=${
                                                  new Date().getTime() / 1000
                                                }&language=es&key=${
                                                  process.env
                                                    .REACT_APP_GOOGLE_TIMEZONE_API_KEY
                                                }`,
                                                headers: {},
                                              };

                                              fetch(config?.url)
                                                .then(function (response) {
                                                  return response.json();
                                                })
                                                .then(function (json) {
                                                  if (json?.status === 'OK') {
                                                    const data =
                                                      zoneTimeList?.find(
                                                        (zone) =>
                                                          zone?.location ===
                                                          json?.timeZoneId,
                                                      );
                                                    setCurrentUserTimezone(
                                                      data,
                                                    );
                                                    if (data) {
                                                      setFieldValue(
                                                        'organization.timezone',
                                                        data?.value,
                                                      );
                                                      setLocHelperText(
                                                        constants.LOCATION_TIMEZONE_ORGANIZATION_MESSAGE,
                                                      );
                                                    } else {
                                                      setLocHelperText(
                                                        constants.AUTO_TIMEZONE_FAIL_MESSAGE,
                                                      );
                                                      setFieldValue(
                                                        'organization.timezone',
                                                        '',
                                                      );
                                                    }
                                                    setVisibleLocHelperText(
                                                      true,
                                                    );
                                                  } else {
                                                    setVisibleLocHelperText(
                                                      true,
                                                    );
                                                    setLocHelperText(
                                                      constants.AUTO_TIMEZONE_FAIL_MESSAGE,
                                                    );
                                                  }
                                                });
                                            }
                                          }
                                        }}
                                        className={`form-control shadow-none autoaddress ${
                                          touched?.organization
                                            ?.displayDetails &&
                                          (errors?.organization?.addressLine1 ||
                                            !values?.organization
                                              ?.displayDetails) &&
                                          'is-invalid'
                                        } ${
                                          values?.organization?.addressLine1 &&
                                          'has-value'
                                        }`}
                                      />
                                      {!document.getElementById(
                                        'displayDetails',
                                      )?.value ? (
                                        <span
                                          className={`placeholder-wrapper required`}
                                        >
                                          {
                                            constants.ORGANIZATION_ADDRESS_FIELD_LABEL
                                          }
                                        </span>
                                      ) : null}
                                    </div>
                                    {values.organization?.addressLine1 ? (
                                      <span className={`reset-address-wrapper`}>
                                        <IoClose
                                          size={15}
                                          onClick={() => {
                                            setFieldValue(
                                              'organization.zipcode',
                                              '',
                                            );
                                            setFieldValue(
                                              'organization.country',
                                              '',
                                            );
                                            setFieldValue(
                                              'organization.city',
                                              '',
                                            );
                                            setFieldValue(
                                              'organization.state',
                                              '',
                                            );
                                            setFieldValue(
                                              'organization.addressLine1',
                                              '',
                                            );
                                            setFieldValue(
                                              'organization.displayDetails',
                                              '',
                                            );
                                            setFieldValue(
                                              'organization.timezone',
                                              '',
                                            );
                                            document.getElementById(
                                              'displayDetails',
                                            ).value = '';
                                            setFieldTouched(
                                              'organization.displayDetails',
                                              true,
                                              false,
                                            );
                                          }}
                                        />
                                      </span>
                                    ) : null}
                                    <ErrorMessage
                                      component="div"
                                      name="organization.addressLine1"
                                      className="error"
                                    />
                                    {touched.organization?.displayDetails &&
                                      (!values.organization?.addressLine1 ||
                                        !values.organization.city ||
                                        !values.organization.state ||
                                        !values.organization.country ||
                                        !values.organization.zipcode) && (
                                        <div className="error">
                                          {
                                            constants.ADDRESS_FIELD_ERROR_MESSAGE
                                          }
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col position-relative">
                                    <div>
                                      <SelectField
                                        isFromOrg={true}
                                        name="timezone"
                                        options={zoneTimeList}
                                        label=""
                                        defaultOptionLabel="Time Zone"
                                        value={values.organization.timezone}
                                        onChange={(evebr) =>
                                          changeTimezoneValue(
                                            evebr,
                                            setFieldValue,
                                          )
                                        }
                                        beforeinputicon={
                                          <GoSearch fill="#566376" size={15} />
                                        }
                                        placeholderClass={
                                          currentUserTimezone?.value
                                            ? ''
                                            : 'timezone-placeholder'
                                        }
                                      />
                                    </div>
                                    <ErrorMessage
                                      component="div"
                                      name="timezone"
                                      className="info-timezone"
                                    />
                                    {visibleLocHelperText ? (
                                      <div className="info-timezone">
                                        <PiWarningCircleBold
                                          color={getComputedStyle(
                                            document.documentElement,
                                          ).getPropertyValue('--greyscale_56')}
                                          size={16}
                                        />
                                        <div className="message-info">
                                          {locHelperText}
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <TextField
                                  beforeinputicon={
                                    <SlCallEnd
                                      fill="#566376"
                                      size={15}
                                      className="call-icon"
                                    />
                                  }
                                  label=""
                                  placeholder={
                                    constants.ORGANIZATION_PHONE_FIELD_LABEL
                                  }
                                  name="account.phoneNumber"
                                  type="text"
                                />

                                <Row>
                                  <Col lg={6} xs={12}>
                                    <TextField
                                      removetopmargin="true"
                                      beforeinputicon={
                                        <BiWorld size={15} fill="#566376" />
                                      }
                                      label=""
                                      placeholder={
                                        constants.ORGANIZATION_WEBSITE_NAME_FIELD_LABEL
                                      }
                                      name="organization.orgWebsite"
                                      type="text"
                                    />
                                  </Col>
                                  <Col lg={6} xs={12}>
                                    <TextField
                                      removetopmargin="true"
                                      beforeinputicon={
                                        <CiLinkedin size={15} fill="#566376" />
                                      }
                                      label=""
                                      placeholder={
                                        constants.ORGANIZATION_LINKEDIN_PROFILE_NAME_FIELD_LABEL
                                      }
                                      name="organization.orgLinkedInProfile"
                                      type="text"
                                    />
                                  </Col>
                                </Row>

                                <Form.Group className="" controlId="reCaptcha">
                                  <ReCAPTCHA
                                    name="recaptcha"
                                    ref={recaptchaRef}
                                    size="invisible"
                                    sitekey={process.env.REACT_APP_CAPATCHA_ID}
                                  />
                                </Form.Group>

                                <Form.Group as={Row} className="">
                                  <Col>
                                    {show && (
                                      <Alert
                                        variant="danger"
                                        className="text-start"
                                        onClose={() => setShow(false)}
                                        dismissible
                                      >
                                        {userMsg}
                                      </Alert>
                                    )}
                                  </Col>
                                </Form.Group>
                                <Form.Group
                                  className={`remember-me pt-3 ${
                                    process.env.REACT_APP_PROJECT ===
                                    AppDefaults.PROJECT_MEGATRON
                                      ? 'pb-1'
                                      : 'pb-3'
                                  }`}
                                  controlId="formHorizontalCheck"
                                >
                                  <Form.Check
                                    id={`check-api`}
                                    className="d-flex"
                                  >
                                    <Form.Check.Input
                                      checked={acceptTerms}
                                      onChange={handleTermsChange}
                                      role="button"
                                      className="custom-form-check-input"
                                    />
                                    <Form.Check.Label
                                      className="ms-2 ms-xxl-3 text-start"
                                      role="button"
                                    >
                                      {constants.FORM_CHECK_TERMS_CONTENT}
                                      <a
                                        href={`${process.env.REACT_APP_API_BASE_URL}user/apps/${tenantID}/${appId}/tos`}
                                        target="_blank"
                                        class="primary_anchor pe-1"
                                        rel="noreferrer"
                                      >
                                        {
                                          constants.FOOTER_TERMS_CONDITION_LINK_TEXT
                                        }
                                      </a>
                                      {`${constants.AND_TEXT_POLICY_SERVICE}`}
                                      <a
                                        href={`${process.env.REACT_APP_API_BASE_URL}user/apps/${tenantID}/${appId}/privacy`}
                                        target="_blank"
                                        className="form-privacy-link"
                                        rel="noreferrer"
                                      >
                                        {
                                          constants.FOOTER_PRIVACY_POLICY_LINK_TEXT
                                        }
                                      </a>
                                      .
                                    </Form.Check.Label>
                                  </Form.Check>
                                </Form.Group>
                                {process.env.REACT_APP_PROJECT ===
                                  AppDefaults.PROJECT_MEGATRON && (
                                  <Form.Group
                                    className="remember-me pb-3"
                                    controlId="formHorizontalCheck"
                                  >
                                    <Form.Check
                                      id={`check-beta-api`}
                                      className="d-flex"
                                    >
                                      <Form.Check.Input
                                        checked={acceptBetaAgreement}
                                        onChange={(event) =>
                                          setAcceptBetaAgreement(
                                            event.target.checked,
                                          )
                                        }
                                        role="button"
                                        className="custom-form-check-input"
                                      />
                                      <Form.Check.Label
                                        className="ms-2 ms-xxl-3 text-start"
                                        role="button"
                                      >
                                        {constants.FORM_CHECK_TERMS_CONTENT}
                                        <a
                                          href={`${process.env.REACT_APP_API_BASE_URL}user/apps/${tenantID}/${appId}/agreement`}
                                          target="_blank"
                                          class="primary_anchor"
                                          rel="noreferrer"
                                        >
                                          {constants.BETA_AGREEMENT_TITLE}
                                        </a>
                                        .
                                      </Form.Check.Label>
                                    </Form.Check>
                                  </Form.Group>
                                )}
                                <Form.Group as={Row} className="mb-3 mt-3">
                                  <Col>
                                    <PrimaryButton
                                      className="btn btn-primary w-100"
                                      type="submit"
                                      disabled={
                                        !(
                                          dirty &&
                                          isValid &&
                                          acceptTerms &&
                                          checkBetaAgreement()
                                        ) ||
                                        currentUserTimezone === undefined ||
                                        !values.organization.city ||
                                        !values.organization.state ||
                                        !values.organization.zipcode ||
                                        !values.organization.country ||
                                        shortNameExistsError
                                      }
                                      loader={showLoader}
                                    >
                                      {constants.SUBMIT_BUTTON_LABEL}
                                    </PrimaryButton>
                                  </Col>
                                </Form.Group>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
}

export default BecameAcustomer;
