import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import _ from 'lodash';


export const useDeviceSnapshots = create(
  persist(
    (set, get) => ({
      deviceSnapshots: {},
      
      getDeviceSnapshots: (deviceId) => {
        if (!deviceId) return null;

        let snapData = get().deviceSnapshots;
        
        return snapData?.deviceId ? snapData?.deviceId: null;
      },

      setDeviceSnapshots: (deviceId, snapshot) => {
        if (!deviceId || !snapshot) return;

        let snapData = get().deviceSnapshots;
        snapData[deviceId] = snapshot;
        set({ 
          deviceSnapshots: snapData,
        });
      },

      resetDeviceSnapshots: () => {        
        set({
          deviceSnapshots: {},
        });
      },
     
    }),
    {
      name: 'net.duclo.vms.useDeviceSnapshots',
      version: '1.9',
    }
  )
);
