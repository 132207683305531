import { lazy } from 'react';
import withSuspense from '../withSuspense';

const EditOrganization = withSuspense(
  lazy(() => import('../../pages/organizations/EditOrganization'))
);

export const organizationRoutes = [
  { path: 'editOrganization.html', element: <EditOrganization /> },
];
