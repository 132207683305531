import { Container, Col, Navbar, Nav, Row } from 'react-bootstrap';
import { constants, AppDefaults } from '../../helpers';

function Footer(props) {
  const tenantID =
    !process.env.REACT_APP_PROJECT && !AppDefaults.PROJECT_MEGATRON
      ? 'dc'
      : process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON
      ? 'hva'
      : 'dc';
  const appID ='vms';
  return (
    <footer
      className="footer"
      style={{ backgroundColor: props.backgroundColor }}
    >
      <Container className='mw-100'>
        <Row className="justify-content-center">
          <Col md={11}>
            <Navbar
              expand="lg"
              className={
                props?.centeredLinks === 'true'
                  ? 'justify-content-center'
                  : 'justify-content-start'
              }
            >
              <Nav.Link 
               href={`${process.env.REACT_APP_API_BASE_URL}user/apps/${tenantID}/${appID}/tos`}
               target="_blank"
              >
                {constants.FOOTER_NAV_TERMS_CONDITION}
              </Nav.Link>
              <Nav.Link 
               href={`${process.env.REACT_APP_API_BASE_URL}user/apps/${tenantID}/${appID}/privacy`}
               target="_blank"
              >
                {constants.FOOTER_NAV_PRIVACY_POLICY}
              </Nav.Link>
            </Navbar>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

Footer.defaultProps = {
  backgroundColor: '#FFFFFF',
};

export default Footer;
