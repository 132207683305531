import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ResponsiveStack from '../../../components/common/SiderBarDrawer/ResponsiveStack';
import CustomLoadingButton from '../../../components/forms/CustomLoadingButton';
import EllipsisButton from '../../../components/common/EllipsisButton';

import CustomTabsPanel from '../../../components/common/CustomTabsPanel';
import GeneralInformation from './GeneralInformation';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useCardHolderGroupStore } from '../../../store/cardHolderGroupStore';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useCardHolderGroups from '../../../hooks/cardholderGroups/useCardHolderGroups';
import CardHolders from './CardHolders';

const validationSchema = Yup.object({
  groupName: Yup.string()
    .required('Group Name is required')
    .max(25, 'Group Name must be 25 characters or less'),
  description: Yup.string().max(
    25,
    'Description must be 25 characters or less',
  ),
});
const EditCardholderGroup = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const groupId = searchParams.get('groupId');
  const { modifyCardHolderGroup } = useCardHolderGroups();
  const {
    getCardHolderGroupByIdDetails,
    resetCardHolderGroupId,
    getCardHolderGroupAccessPolicies,
    getAttachedCardHolders,
  } = useCardHolderGroupStore();

  const handleSubmitForm = async (values) => {
    setLoading(true);
    const data = {
      groupName: values?.groupName,
      description: values?.description,
      accessPolicies: values?.selectedPolicies?.map(
        (policy) => policy.policyId,
      ),
      acsCardHolderIds: values?.selectedCardHolders?.map(
        (cardHolder) => cardHolder.cardHolderId,
      ),
    };

    await modifyCardHolderGroup(data, groupId);
    setLoading(false);
    navigate('/cardholdergroups');
  };

  const tabs = [
    {
      label: 'General Information',
      content: (
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        setFieldValue,
      ) => (
        <GeneralInformation
          {...{
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            setFieldValue,
          }}
        />
      ),
    },
    {
      label: 'Cardholders',
      content: (setFieldValue) => <CardHolders {...{ setFieldValue }} />,
    },
  ];

  const initialValues = {
    groupName: getCardHolderGroupByIdDetails()?.groupName || '',
    description: getCardHolderGroupByIdDetails()?.description || '',
    selectedPolicies: getCardHolderGroupAccessPolicies() || undefined,
    selectedCardHolders: getAttachedCardHolders() || undefined,
  };

  useEffect(() => {
    return () => resetCardHolderGroupId();
  }, []);

  return (
    <Formik
      validateOnMount={true}
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmitForm}
    >
      {({
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        isValid,
        dirty,
        setFieldValue,
      }) => {
        const policiesChanged =
          values?.selectedPolicies?.length !==
            getCardHolderGroupByIdDetails()?.accessControls?.length ||
          values?.selectedPolicies?.some(
            (policy) =>
              !getCardHolderGroupByIdDetails()?.accessControls?.includes(
                policy,
              ),
          );

        const cardHoldersChanged =
          values?.selectedCardHolders?.length !==
            getCardHolderGroupByIdDetails()?.cardHolders?.length ||
          values?.selectedCardHolders?.some(
            (cardHolder) =>
              !getCardHolderGroupByIdDetails()?.cardHolders?.includes(
                cardHolder,
              ),
          );
        const isFormModified = dirty || policiesChanged || cardHoldersChanged;

        return (
          <Box className="App" component={Form}>
            <ResponsiveStack
              direction="column"
              spacing={4}
              smallScreenSpacing={10}
            >
              <ResponsiveStack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ height: '44.5px' }}
              >
                <Typography variant="customTitle">
                  Editing {getCardHolderGroupByIdDetails().groupName}
                </Typography>
                <ResponsiveStack direction="row" spacing={2}>
                  <CustomLoadingButton
                    variant="contained"
                    type="submit"
                    disabled={!isValid || !isFormModified}
                    loading={loading}
                  >
                    Save Changes
                  </CustomLoadingButton>
                  <EllipsisButton />
                </ResponsiveStack>
              </ResponsiveStack>
              <CustomTabsPanel
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
              <Box>
                {tabs[activeTab].content(
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                  setFieldValue,
                )}
              </Box>
            </ResponsiveStack>
          </Box>
        );
      }}
    </Formik>
  );
};

export default EditCardholderGroup;
