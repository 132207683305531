import { lazy } from 'react';
import withSuspense from '../withSuspense';

const ManageDoors = withSuspense(
  lazy(() => import('../../pages/doors/ManageDoors'))
);

const CreateDoor = withSuspense(
  lazy(() => import('../../pages/doors/CreateDoor/CreateDoor'))
);

export const doorRoutes = [
  { path: 'manage.html', element: <ManageDoors /> },
  { path: 'new.html', element: <CreateDoor /> },
];
