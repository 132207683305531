import { styled } from '@mui/material/styles';
import { FormLabel } from '@mui/material';
const CustomFormLabel = styled(FormLabel)(() => ({
  fontFamily: 'Noto Sans',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  color: `var(--brand_black)`,
}));

export default CustomFormLabel;
