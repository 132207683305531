import { IOType } from './controllerUtils';

export const DoorTypes = [
  {
    label: 'Default',
    value: 'Default',
  },
  {
    label: 'Swing Door',
    value: 'Swing Door',
  },
  {
    label: 'Double Door',
    value: 'Double Door',
  },
  {
    label: 'Sliding Door',
    value: 'Sliding Door',
  },
  {
    label: 'Emergency Exit',
    value: 'Emergency Exit',
  },
  {
    label: 'Revolving Door',
    value: 'Revolving Door',
  },
];

export const IONames = {
  entrySensor: 'Entry Sensor',
  rex: 'Request to Exit',
  lockSensor: 'Lock Sensor',
  doorPositionSwitch: 'Door Position Switch',
  lock: 'Lock',
  reader: 'Reader',
};

export const IOLabelIds = {
  entrySensor: 'entrySensor',
  rex: 'rex',
  lockSensor: 'lockSensor',
  doorPositionSwitch: 'doorPositionSwitch',
  lock: 'lock',
  reader: 'reader',
};

export const getIONames = (item) => IONames[item];

const mapIoData = (ioId, ioArray, ioType, ioName, ioLabelId) => {
  const ioPortItem = ioArray.find((item) => item.ioId === ioId);
  return {
    ioType,
    ioName,
    ioId,
    ioLabelId,
    ioPort: ioPortItem ? ioPortItem.ioPort : null,
  };
};
const mapAndPushIoData = (ioId, ioArray, ioType, ioName, ioLabelId, targetArray) => {
  if (ioId) {
    const obj = mapIoData(ioId, ioArray, ioType, ioName, ioLabelId);
    targetArray.push(obj);
  }
};
export const modifiedDoorObject = (data, controllerIOData) => {
  let newObj = {
    doorName: data?.doorName ?? '',
    description: data?.description ?? '',
    locationId: data?.locationId ?? '',
    areaIds: data?.areaIds ?? [],
    doorType: data?.doorType ?? [],
    controllerId: data?.controllerId ?? null,
    doorTemplateId: data?.doorTemplateId ?? '',
    defaultDoorTemplate: data?.defaultDoorTemplate ?? null,
    sideA: {
      name: data.sideA && data.sideA?.name ? data.sideA?.name : '',
      ioMapping: {
        input: [],
        output: [],
        reader: [],
      },
      cameraList: data.sideA?.cameras?.map((item) => ({cameraId: item})) ?? [],
    },
    sideB: {
      name: data.sideB && data.sideB?.name ? data.sideB?.name : '',
      ioMapping: {
        input: [],
        output: [],
        reader: [],
      },
      cameraList: data.sideB?.cameras?.map((item) => ({cameraId: item})) ?? [],
    },
    locking: {
      ioMapping: {
        input: [],
        output: [],
        reader: [],
      },
    },
    doorSchedules: data?.doorSchedules ?? [],
    unlockedBySchedule: {
      ignoreHeldEnabled: data?.unlockedBySchedule?.ignoreHeldEnabled ?? false,
      ignoreAccessEnabled:
        data?.unlockedBySchedule?.ignoreAccessEnabled ?? false,
    },
  };

 // Locking IO mappings
  mapAndPushIoData(data?.locking?.doorPositionSwitch, controllerIOData.inputArray, IOType.INPUT, IONames.doorPositionSwitch, IOLabelIds.doorPositionSwitch, newObj.locking.ioMapping.input);
  mapAndPushIoData(data?.locking?.lock, controllerIOData.outputArray, IOType.OUTPUT, IONames.lock, IOLabelIds.lock, newObj.locking.ioMapping.output);
  mapAndPushIoData(data?.locking?.lockSensor, controllerIOData.inputArray, IOType.INPUT, IONames.lockSensor, IOLabelIds.lockSensor, newObj.locking.ioMapping.input);

  // Side A IO mappings
  mapAndPushIoData(data?.sideA?.reader, controllerIOData.readerArray, IOType.READER, IONames.reader, IOLabelIds.reader, newObj.sideA.ioMapping.reader);
  mapAndPushIoData(data?.sideA?.entrySensor, controllerIOData.inputArray, IOType.INPUT, IONames.entrySensor, IOLabelIds.entrySensor, newObj.sideA.ioMapping.input);
  mapAndPushIoData(data?.sideA?.requestToExit, controllerIOData.inputArray, IOType.INPUT, IONames.rex, IOLabelIds.rex, newObj.sideA.ioMapping.input);

  // Side B IO mappings
  mapAndPushIoData(data?.sideB?.reader, controllerIOData.readerArray, IOType.READER, IONames.reader, IOLabelIds.reader, newObj.sideB.ioMapping.reader);
  mapAndPushIoData(data?.sideB?.entrySensor, controllerIOData.inputArray, IOType.INPUT, IONames.entrySensor, IOLabelIds.entrySensor, newObj.sideB.ioMapping.input);
  mapAndPushIoData(data?.sideB?.requestToExit, controllerIOData.inputArray, IOType.INPUT, IONames.rex, IOLabelIds.rex, newObj.sideB.ioMapping.input);

  return newObj;
};

const updateIoMapping = (target, items) => {
  items?.forEach(item => {
    switch (item?.ioLabelId) {
      case IOLabelIds.lockSensor:
        target.lockSensor = item.ioId;
        break;
      case IOLabelIds.lock:
        target.lock = item.ioId;
        break;
      case IOLabelIds.doorPositionSwitch:
        target.doorPositionSwitch = item.ioId;
        break;
      case IOLabelIds.reader:
        target.reader = item.ioId;
        break;
      case IOLabelIds.rex:
        target.requestToExit = item.ioId;
        break;
      case IOLabelIds.entrySensor:
        target.entrySensor = item.ioId;
        break;
    }
  });
};

export const prefillDataObj = (singleDoorData) => {
  const defaultSide = {
    name: '',
    reader: '',
    entrySensor: '',
    requestToExit: '',
    cameras: [],
  };

  const defaultLocking = {
    lock: '',
    lockSensor: '',
    doorPositionSwitch: '',
  };

  let newObj = {
    doorName: singleDoorData?.doorName ?? '',
    description: singleDoorData?.description ?? '',
    locationId: singleDoorData?.locationId ?? '',
    areaIds: singleDoorData?.areaIds ?? [],
    doorType: singleDoorData?.doorType ?? '',
    doorId: singleDoorData?.doorId ?? '',
    doorTemplateId: singleDoorData?.doorTemplateId ?? '',
    controllerId: singleDoorData?.controllerId ?? '',
    defaultDoorTemplate: singleDoorData?.defaultDoorTemplate ?? null,
    sideA: { ...defaultSide, name: singleDoorData?.sideA?.name ?? '', cameras: singleDoorData?.sideA?.cameraList ?? [] },
    sideB: { ...defaultSide, name: singleDoorData?.sideB?.name ?? '', cameras: singleDoorData?.sideB?.cameraList ?? [] },
    locking: { ...defaultLocking },
    doorSchedules: singleDoorData?.doorSchedules ?? [],
    unlockedBySchedule: {
      ignoreHeldEnabled: singleDoorData?.unlockedBySchedule?.ignoreHeldEnabled ?? false,
      ignoreAccessEnabled: singleDoorData?.unlockedBySchedule?.ignoreAccessEnabled ?? false,
    },
  };

  // Update IO mappings
  if (singleDoorData?.locking?.ioMapping) {
    updateIoMapping(newObj.locking, singleDoorData.locking.ioMapping.input);
    updateIoMapping(newObj.locking, singleDoorData.locking.ioMapping.output);
    updateIoMapping(newObj.locking, singleDoorData.locking.ioMapping.reader);
  }

  if (singleDoorData?.sideA?.ioMapping) {
    updateIoMapping(newObj.sideA, singleDoorData.sideA.ioMapping.input);
    updateIoMapping(newObj.sideA, singleDoorData.sideA.ioMapping.output);
    updateIoMapping(newObj.sideA, singleDoorData.sideA.ioMapping.reader);
  }

  if (singleDoorData?.sideB?.ioMapping) {
    updateIoMapping(newObj.sideB, singleDoorData.sideB.ioMapping.input);
    updateIoMapping(newObj.sideB, singleDoorData.sideB.ioMapping.output);
    updateIoMapping(newObj.sideB, singleDoorData.sideB.ioMapping.reader);
  }
  return newObj;
};
