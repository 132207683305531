import { lazy } from 'react';
import withSuspense from '../withSuspense';

const AllSchedules = withSuspense(
  lazy(() => import('../../pages/schedules/AllSchedules'))
);

export const scheduleRoutes = [
  { path: 'manage.html', element: <AllSchedules /> },
];
