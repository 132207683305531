import { useState, useEffect } from 'react';
import { Alert, Container, Form, Row, Col } from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Header, Footer, PrimaryButton } from '../../components/common';
import {
  RangeInputField,
  SelectField,
  TextField,
  ToggleInput,
} from '../../components/forms';
import { AppDefaults, Utils, constants, roles } from '../../helpers';
import PageWrapper from '../PageWrapper';
import { useOrganizations } from '../../store/OrganizationsStore';
import axios from 'axios';
import Autocomplete from 'react-google-autocomplete';
import { BiWorld } from 'react-icons/bi';
import { AiOutlineLinkedin } from 'react-icons/ai';
import { usePoliciesStore } from '../../store/policiesStore';
import timezones from '../../data/support/timezone.json';
import './Settings.scss';
import { PiWarningCircleBold } from 'react-icons/pi';
import { moveArrayPosition } from '../../helpers/commonUtils';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { IoClose } from "react-icons/io5";

// Schema for yup
const validationSchema = Yup.object().shape({
  orgName: Yup.string()
    .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
    .required(constants.NAME_REQUIRED_ERROR_MESSAGE),
  addressLine1: Yup.string().required(constants.ADDRESS_REQUIRED_ERROR_MESSAGE),
  orgWebsite: Yup.string()
    .matches(
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/i,
      constants.WEBSITE_CORRECT_URL_MESSAGE
    ),
  orgLinkedInProfile: Yup.string().matches(
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/i,
    constants.WEBSITE_CORRECT_URL_MESSAGE
  ),
  timezone: Yup.string().required(constants.TIMEZONE_REQUIRED_ERROR_MESSAGE),
});

function OrganizationSettings(props) {
  const [variant, setVariant] = useState('danger');
  const [userMsg, setUserMsg] = useState('');
  const [visibleLocHelperText, setVisibleLocHelperText] = useState(false);
  const [locHelperText, setLocHelperText] = useState('');
  const [orgContact, setOrgContact] = useState([]);
  const zoneTimeList = timezones?.data;
  //=== Store get/set actions
  const loggedInUserRole = useOrganizations((state) => state.loggedInUserRole);
  const customerOrgData = useOrganizations((state) => state.customerOrgData);
  const getOrganizations = useOrganizations((state) => state.getOrganizations);
  const getOrganizationContact = useOrganizations(
    (state) => state.getOrganizationContact
  );
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData
  );
  const partnerOrgData = useOrganizations((state) => state.partnerOrgData);
  const setPartnerOrgData = useOrganizations((state) => state.setPartnerOrgData);
  const partnerOrgId =
    loggedInUserRole === roles.ROLE4VMS || loggedInUserRole === roles.ROLE5VMS
      ? partnerOrgData?.orgId
      : customerOrgData[0]?.orgId;
  const orgSettingsData =
    loggedInUserRole === roles.ROLE4VMS || loggedInUserRole === roles.ROLE5VMS
      ? partnerOrgData
      : customerOrgData[0];
  const getLoggedInUserPolicies = usePoliciesStore(
    (state) => state.getLoggedInUserPolicies
  );
  const [currentUserTimezone, setCurrentUserTimezone] = useState(
    zoneTimeList?.find((zone) => zone.value === orgSettingsData?.timezone)
  );
  const fetchOrganizationSettingData = async () => {
    try {
      await getOrganizations(`/partner/account/self`);
    } catch (error) {
      setUserMsg(error);
    }
  };

  const disableInput = (event, values) => {
    if (values.addressLine1) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (partnerOrgId) {
      // get data for organization contact dropdown
      const roleParam = encodeURIComponent(loggedInUserRole);
      getOrganizationContact(
        `/partner/orgs/${partnerOrgId}/accounts/v2?roleIds=${roleParam}`
      )
        .then((res) => {
          let updatedContacts = res ?? [];
          if (orgSettingsData?.contactId) {
            const selectedObjIndex = res?.findIndex(
              (data) => data?.value === orgSettingsData?.contactId
            );
            updatedContacts = moveArrayPosition(res, selectedObjIndex, 0);
          }
          setOrgContact(updatedContacts);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [orgSettingsData?.orgId]);

  useEffect(() => {
    if (partnerOrgId) {
      fetchOrganizationSettingData();
    }
  }, [partnerOrgId]);

  return (
    <div className="App organization-settings">
      <Header />
      <PageWrapper className="mb-5 mw-100">
        <div className="list-block">
          <Container className="h-100 mw-100">
            <Row className="g-4  w-100">
              <Col md={12}>
                {/* Show messages */}
                <Alert
                  variant={variant}
                  show={!!userMsg}
                  onClose={() => setUserMsg('')}
                  dismissible
                >
                  {userMsg}
                </Alert>
                <Formik
                  initialValues={{
                    orgId: orgSettingsData?.orgId,
                    orgName: orgSettingsData?.orgName,
                    displayDetails: `${orgSettingsData?.addressLine1}${orgSettingsData?.city}, ${orgSettingsData?.stateName}, ${orgSettingsData?.country}, ${orgSettingsData?.zipcode}`,
                    addressLine1: orgSettingsData?.addressLine1,
                    city: orgSettingsData?.city,
                    state: orgSettingsData?.stateName,
                    country: orgSettingsData?.country,
                    zipcode: orgSettingsData?.zipcode,
                    orgWebsite: orgSettingsData?.orgWebsite,
                    orgLinkedInProfile: orgSettingsData?.orgLinkedInProfile === 'undefined' ? "": orgSettingsData?.orgLinkedInProfile,
                    createdDate: orgSettingsData?.createdDate,
                    orgStatus: orgSettingsData?.orgStatus,
                    lat: orgSettingsData?.lat,
                    lng: orgSettingsData?.lng,
                    timezone: currentUserTimezone?.value,
                    mfa: orgSettingsData?.mfa,
                    contactId: orgSettingsData?.contactId ?? '',
                    retainNotification: orgSettingsData?.retainNotification,
                  }}
                  enableReinitialize={true}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting, resetForm,setFieldValue }) => {
                    setSubmitting(true);
                    // Simulate submitting to database
                    if (partnerOrgId) {
                      axios
                        .put(
                          `partner/orgs/${partnerOrgId}`,
                          values,
                          Utils.requestHeader()
                        )
                        .then((res) => {
                          let response = res?.data;
                          resetForm();
                          if (response?.meta?.code === 200) {
                            if(response?.data?.orgType === AppDefaults?.ORG_TYPE_INSTALLER){
                              setPartnerOrgData(response?.data)
                            }
                            else{
                              fetchOrganizationSettingData();
                            }
                            if(response?.data?.orgLinkedInProfile){
                              setFieldValue('orgLinkedInProfile',response?.data?.orgLinkedInProfile)
                            }
                            else{
                              setFieldValue('orgLinkedInProfile','')
                            }
                            setSubmitting(false);
                            setVariant('success');
                            setUserMsg(
                              constants.ORGANIZATION_SETTINGS_PROFILE_SETTINGS_SUCCESS_TEXT
                            );
                          } else if (res?.response?.status !== 200) {
                            setVariant('danger');
                            setUserMsg(res?.message);
                            setSubmitting(false);
                          } else {
                            setVariant('danger');
                            setUserMsg(response?.meta?.userMsg);
                            setSubmitting(false);
                          }
                        })
                        .catch(function (error) {
                          resetForm();
                          setVariant('danger');
                          setUserMsg(error.message);
                          setSubmitting(false);
                        });
                    } else setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    dirty,
                  }) => (
                    <Form
                      className="modal-form d-flex flex-row"
                      onSubmit={handleSubmit}
                    >
                      <Col md={6}>
                        <div className="page-header mb-5">
                          <Container>
                            <Col className="g-4">
                              <Row className="page-title-breadcrumbs text-start p-0">
                                {constants.ORGANIZATION_SETTINGS_PAGE_TITLE}
                              </Row>
                              <Row className="page-title text-start p-0">
                                {constants.ORGANIZATION_SETTINGS_PAGE_TITLE}
                              </Row>
                            </Col>
                          </Container>
                        </div>
                        <TextField
                          required={true}
                          removebottommargin="true"
                          removetopmargin="true"
                          label=""
                          placeholder={constants.CUSTOMER_NAME_FIELD}
                          name="orgName"
                          type="text"
                        />

                        <div className="row">
                          <div className="col position-relative address-with-reset">
                            <div>
                              <Autocomplete
                                name="displayDetails"
                                placeholder=""
                                apiKey={process.env.REACT_APP_GOOGLE_PLACE_URL}
                                onChange={(evt) => {
                                  setFieldValue('displayDetails', evt?.target?.value);
                                }}
                                onBlur={handleBlur}
                                onKeyDown={(e) => disableInput(e, values)}
                                // onFocus={
                                //   (e) => e.target.setAttribute("autoComplete", "none")
                                // }
                                id="displayDetails"
                                options={{
                                  fields: [
                                    'formatted_address',
                                    'geometry',
                                    'name',
                                    'address_components',
                                  ],
                                  strictBounds: false,
                                  types: ['address'],
                                }}
                                onPlaceSelected={(place) => {
                                  if(place?.address_components?.length){
                                  setFieldValue('lat', place?.geometry?.location?.lat());
                                  setFieldValue('lng', place?.geometry?.location?.lng());
                                  let addressComponent, addressComponentType, rawzipcode, rawcountry, rawcity, rawstate, rawaddline;
                                  for (
                                    let i = 0;
                                    i < place?.address_components?.length;
                                    i++
                                  ) {
                                    addressComponent = place.address_components[i];
                                    let j = 0;
                                    addressComponentType = addressComponent.types[j];
                                    if (addressComponentType === 'postal_code') {
                                      rawzipcode = addressComponent.long_name ? addressComponent.long_name : '';
                                    }
                                    if (addressComponentType === 'country') {
                                      rawcountry = addressComponent.long_name ? addressComponent.short_name : '';
                                    }
                                    if (addressComponentType === 'administrative_area_level_1') {
                                      rawstate = addressComponent.long_name ? addressComponent.short_name : '';
                                    }
                                    if (addressComponentType === 'locality') {
                                      rawcity = addressComponent.long_name ? addressComponent.long_name : '';
                                    }

                                    if (
                                      addressComponentType === 'street_number' ||
                                      addressComponentType === 'route' ||
                                      addressComponentType === 'neighborhood' ||
                                      addressComponentType.includes('sublocality')
                                    ) {
                                      rawaddline = `${rawaddline ? rawaddline : ''}${addressComponent.long_name}, `;
                                    }
                                  }

                                  setFieldValue('zipcode', rawzipcode);
                                  setFieldValue('country', rawcountry);
                                  setFieldValue('city', rawcity);
                                  setFieldValue('state', rawstate);
                                  setFieldValue('addressLine1', rawaddline);
                                  setFieldValue(
                                    'displayDetails',
                                    (rawaddline ? rawaddline : '') +
                                      (rawcity ? rawcity + ', ' : '') +
                                      (rawstate ? rawstate + ', ' : '') +
                                      (rawcountry ? rawcountry + ', ' : '') +
                                      (rawzipcode ? rawzipcode : '')
                                  );
                                  if(place?.geometry?.location?.lat() && place?.geometry?.location?.lng()){
                                    var config = {
                                      method: 'get',
                                      url: `https://maps.googleapis.com/maps/api/timezone/json?location=${place?.geometry?.location?.lat()},${place?.geometry?.location?.lng()}&timestamp=${new Date().getTime() / 1000
                                        }&language=es&key=${process.env
                                          .REACT_APP_GOOGLE_TIMEZONE_API_KEY
                                        }`,
                                      headers: {},
                                    };

                                    fetch(config?.url)
                                    .then(function (response) {
                                      return response.json();
                                    })
                                    .then(function (json) {
                                      if (json?.status === 'OK') {
                                        const data = zoneTimeList?.find(
                                          (zone) => zone?.location === json?.timeZoneId
                                        );
                                        if (data) {
                                          setCurrentUserTimezone(data);
                                          setFieldValue('timezone', data?.value);
                                          setLocHelperText(
                                            constants.LOCATION_TIMEZONE_ORGANIZATION_MESSAGE
                                          );
                                        } else {
                                          setLocHelperText(
                                            constants.AUTO_TIMEZONE_FAIL_MESSAGE
                                          );
                                          setFieldValue('timezone', '');
                                        }
                                        setVisibleLocHelperText(true);
                                      } else {
                                        setVisibleLocHelperText(true);
                                        setLocHelperText(
                                          constants.AUTO_TIMEZONE_FAIL_MESSAGE
                                        );
                                      }
                                    });
                                  }
                                }
                                }}
                                value={values.displayDetails}
                                className={`form-control shadow-none autoaddress ${
                                  touched.displayDetails &&
                                  (errors.addressLine1 || !values.displayDetails) &&
                                  'is-invalid'
                                } ${values.addressLine1 && 'has-value'}`}
                              />
                              {!document.getElementById("displayDetails")?.value ?
                              <span className="placeholder-wrapper required">
                                {constants.ADDRESS_FIELD}
                              </span>: null}
                              {values.addressLine1 ?
                                <span className={`reset-address-wrapper`}>
                                  <IoClose size={15}
                                    onClick={()=> {
                                    setFieldValue('zipcode', '');
                                    setFieldValue('country', '');
                                    setFieldValue('city', '');
                                    setFieldValue('state', '');
                                    setFieldValue('addressLine1', '');
                                    setFieldValue('displayDetails', '');
                                    setFieldValue('timezone', '');
                                    document.getElementById("displayDetails").value='';
                                    setFieldTouched('displayDetails', true, false);
                                  }}/>
                                </span> : null
                              }
                            </div>
                            <ErrorMessage
                              component="div"
                              name="addressLine1"
                              className="error"
                            />
                            {touched.displayDetails &&
                              (!values.addressLine1 ||
                                !values.city ||
                                !values.state ||
                                !values.zipcode || !values.country) && (
                                <div className="error">
                                  {constants.ADDRESS_FIELD_ERROR_MESSAGE}
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col position-relative">
                            <div>
                              <SelectField
                                isFromOrg={true}
                                className="mb-2"
                                name="timezone"
                                options={zoneTimeList}
                                label=""
                                defaultOptionLabel="Time Zone"
                                value={values.timezone}
                                onChange={(evebr) => {
                                  let selectedVal = evebr?.target?.value;
                                  const getLocationSelected =
                                    zoneTimeList?.find(
                                      (zone) => zone.value === selectedVal
                                    );
                                  setCurrentUserTimezone(getLocationSelected);
                                  setFieldValue(
                                    'timezone',
                                    getLocationSelected?.value
                                  );
                                  setVisibleLocHelperText(false);
                                }}
                              />
                            </div>

                            {visibleLocHelperText ? (
                              <div className="info-timezone">
                                <PiWarningCircleBold
                                  color={getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue('--greyscale_56')}
                                  size={16}
                                />
                                <div className="message-info">{locHelperText}</div>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <TextField
                          beforeinputicon={<BiWorld size={12} />}
                          removebottommargin="true"
                          removetopmargin="true"
                          label=""
                          placeholder={constants.WEBSITE_NAME_FIELD}
                          name="orgWebsite"
                          type="text"
                        />

                        <TextField
                          beforeinputicon={<AiOutlineLinkedin size={12} />}
                          removebottommargin="true"
                          removetopmargin="true"
                          label=""
                          placeholder={constants.LINKEDIN_PROFILE_NAME_FIELD}
                          name="orgLinkedInProfile"
                          type="text"
                          value={values?.orgLinkedInProfile}
                        />
                        {(getLoggedInUserData()?.orgType === 'INSTALLER_ORG' && getLoggedInUserPolicies().update_partner_org) ||
                          (getLoggedInUserData()?.orgType === 'CUSTOMER_ORG' && getLoggedInUserPolicies().update_cust_org_settings) ?
                          (
                            <ToggleInput
                              name="mfa"
                              label={
                                constants.ORGANIZATION_TWO_FACTOR_AUTHENTICATION
                              }
                              value={values.mfa}
                              changeHandler={() =>
                                setFieldValue('mfa', !values.mfa)
                              }
                            />
                          ) : null}
                        {getLoggedInUserData()?.orgType === 'CUSTOMER_ORG' && getLoggedInUserPolicies().update_cust_org_settings ? (
                          <SelectField
                            name="contactId"
                            key="contactId"
                            options={orgContact}
                            defaultOptionLabel={
                              constants.ORGANIZATION_CONTACT_DROPDOWN_LABEL
                            }
                          />
                        ) : null}

                        {getLoggedInUserData()?.orgType === 'CUSTOMER_ORG' && getLoggedInUserPolicies().update_cust_org_settings ? (
                          <RangeInputField
                            label={constants.NOTIFICATION_RETENTION_PERIOD}
                            maxValue={14}
                            minValue={1}
                            fieldName="retainNotification"
                            currentValue={values.retainNotification}
                            changeHandler={(value) => {
                              setFieldValue('retainNotification', value);
                            }}
                          />
                        ) : null}
                      </Col>
                      <Col md={6} className="form-submit-main ">
                        <Form.Group as={Row}>
                          <Col>
                            <PrimaryButton
                              className="btn btn-primary org-btn"
                              type="submit"
                              disabled={
                                !(
                                  (dirty) &&
                                  (isValid) &&
                                  (getLoggedInUserData()?.orgType === 'INSTALLER_ORG'
                                    ? getLoggedInUserPolicies()
                                        .update_partner_org
                                    : getLoggedInUserPolicies().update_cust_org)
                                )
                              }
                              loader={isSubmitting}
                              height="44px"
                              fontSize="0.875rem"
                            >
                              {constants.EDIT_CUSTOMER_ORG_CHANGES}
                            </PrimaryButton>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>

      <Footer />
    </div>
  );
}

export default OrganizationSettings;
