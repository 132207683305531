import store from '../../store/Store';
import {
  setLoadingStream,
  setRemoteStream,
  setRemoteStreamAudio,
  setStremBitmapData,
  setOfferrecived
} from '../../store/reducers/StreamingReducer';
import * as mqtt from '../connection/mqttConnection';
import Store from '../../store/Store';

let mqttPeerConnection;
let topic;
let server;
let updatedDeviceId;
let updateAccountId;
let updatedUUID;
let updatedStreamId;

export const getLocalStreamLive = (
  publishTopic,
  serverDetails,
  accountId,
  deviceId,
  hubId,
  uuid,
  streamId
) => {
  setRemoteStream(null);
  setStremBitmapData(null);
  setOfferrecived(false)
  server = serverDetails;
  topic = publishTopic;
  updateAccountId = accountId;
  updatedDeviceId = deviceId;
  updatedUUID = uuid;
  updatedStreamId = streamId;
  createMQTTPeerConnection(
    publishTopic,
    serverDetails,
    accountId,
    deviceId,
    hubId,
    uuid,
    updatedStreamId
  );
};

const createMQTTPeerConnection = (
  publishTopic,
  { stun, turn },
  accountId,
  deviceId,
  hubId,
  uuid,
  updatedStreamId
) => {
  const iceConfiguration = {
    iceServers: [
      {
        urls: `${stun.protocol}:${stun.host}:${stun.port}`,
      },
      {
        urls: `${turn.protocol}:${turn.host}:${turn.port}`,
        username: `${turn.userName}`,
        credential: `${turn.password}`,
      },
    ],
    // Todo: Delete later - keep it for now as connection is not finalyzed yet
    iceTransportPolicy: 'all',
    rtcpMuxPolicy: 'require',
    bundlePolicy: 'balanced',
  };
  mqttPeerConnection = new RTCPeerConnection(iceConfiguration);

  mqttPeerConnection.addTransceiver('video', {
    direction: 'recvonly',
  });

  console.log(mqttPeerConnection,'mqttPeerConnection')

  // Will test once update on live streaming from firmware
  mqttPeerConnection.addTransceiver('audio', {
    direction: 'recvonly',
  });

  sendOffer(publishTopic, accountId, deviceId, hubId, uuid, updatedStreamId);

  mqttPeerConnection.ontrack = ({ streams: [stream] }) => {
    if (stream.getAudioTracks().length) {
      store.dispatch(setRemoteStreamAudio(stream));
    }
    if (stream.getVideoTracks().length) {
      const track = stream.getVideoTracks()[0];   
      // setTimeout(() => {
      //   new ImageCapture(track)?.grabFrame()?.then((imageBitmap) => {
      //     const data = {
      //       'StreamHeight': imageBitmap?.height,
      //       'StreamWidth': imageBitmap?.width,
      //     };
      //     //TODO DELETE LATER
      //     console.log('imageBitmap',data)
      //     store.dispatch(setStremBitmapData(data)) 
      //   })
      //   .catch((error) => {
      //     //TODO DELETE LATER
      //     console.error('grabFrame() error: ', error);
      //   });
      // }, 2000);
           store.dispatch(setRemoteStream(stream));
    }
  };

  mqttPeerConnection.onicecandidate = (event) => {
    if (event.candidate) {
      let candidate_data = JSON.parse(JSON.stringify(event.candidate));
      candidate_data.type = 'candidate';
      const time = Math.floor(new Date().getTime() / 1000.0);
      candidate_data.id = `${updatedStreamId}`;
      const payload = {
        tid: `${time}`,
        to: hubId,
        from: accountId,
        msg: {
          resource: `ch/${deviceId}/camera/streaming`,
          properties: { ...candidate_data },
        },
        publish: `d/notify/${accountId}/${uuid}`,
      };
      mqtt.sendWebRTCCandidate(publishTopic, payload);
    }
  };

  mqttPeerConnection.onicecandidateerror = (event) => {
    console.log('ICE Candidate Error:', event.errorText);
  };

  mqttPeerConnection.onconnectionstatechange = (event) => {
    // TODO: delete later
    console.log(event,'connection state');
    console.log('connection state', mqttPeerConnection.connectionState);
    if (mqttPeerConnection.connectionState === 'connected') {
      // TODO: delete later
      console.log('succesfully connected with other peer');
      setTimeout(() => {
        Store.dispatch(setLoadingStream(false));
      }, 2000);
    }
    if (mqttPeerConnection.connectionState === 'failed') {
      getLocalStreamLive(
        topic,
        server,
        updateAccountId,
        updatedDeviceId,
        hubId,
        updatedUUID,
        updatedStreamId
      );
    }
  };
};

export const sendOffer = async (
  publishTopic,
  accountId,
  deviceId,
  hubId,
  uuid,
  updatedStreamId
) => {
  const offer = await mqttPeerConnection.createOffer({
    offerToReceiveVideo: true,
    offerToReceiveAudio: true,
  });
  await mqttPeerConnection.setLocalDescription(offer);
  const time = Math.floor(new Date().getTime() / 1000.0);
  const payload = {
    tid: `${time}`,
    to: hubId,
    from: accountId,
    msg: {
      resource: `ch/${deviceId}/camera/streaming`,
      properties: {
        id: `${updatedStreamId}`,
        type: 'offer',
        sdp: offer.sdp,
      },
    },
    publish: `d/notify/${accountId}/${uuid}`,
  };
  mqtt.sendWebRTCOffer(publishTopic, payload);
};

export const handleOffer = async (data) => {
  await mqttPeerConnection.setRemoteDescription(data.offer);
};

export const handleAnswer = async (data) => {
  store.dispatch(setOfferrecived(true));
  await mqttPeerConnection
    ?.setRemoteDescription(data)
    ?.then(() => null)
    .catch(() => null);
};

export const handleCandidate = async (candidate) => {
  try {
    if (!candidate) return;

    if (mqttPeerConnection) {
      if (!candidate.candidate) {
        await mqttPeerConnection.addIceCandidate(null);
      } else {
        await mqttPeerConnection.addIceCandidate(candidate);
      }
    }
  } catch (err) {
    // TODO: Delete later
    console.error(
      'error occurred when trying to add received ice candidate',
      err
    );
  }
};

export const handleLeaveCall = async () => {
  store.dispatch(setRemoteStream(null));
  store.dispatch(setStremBitmapData(null));
  if (mqttPeerConnection) {
    mqttPeerConnection.close();
    mqttPeerConnection.onicecandidate = null;
    mqttPeerConnection.ontrack = null;
    mqttPeerConnection = null;
  }
};
