import React from 'react';
import { Stack, useMediaQuery, useTheme } from '@mui/material';

const ResponsiveStack = ({
  children,
  direction = 'row',
  spacing = 2,
  smallScreenSpacing = 1,
  ...props
}) => {
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Stack
      direction={isSmUp ? direction : 'column'}
      spacing={isSmUp ? spacing : smallScreenSpacing}
      {...props}
    >
      {children}
    </Stack>
  );
};

export default ResponsiveStack;
