import { Utils } from '../../../helpers';
import axiosClient from '../axiosClient';

const fetchAllCardHolderGroups = (route) =>
  axiosClient.get(route, Utils.requestHeader());
const fetchCardHolderGroupById = (route) =>
  axiosClient.get(route, Utils.requestHeader());

const addCardHolderGroup = (route, body) =>
  axiosClient.post(route, body, Utils.requestHeader());
const updateCardHolderGroup = (route, body) =>
  axiosClient.put(route, body, Utils.requestHeader());
const detachCardholderFromGroup = (route) =>
  axiosClient.put(route, Utils.requestHeader());
const detachAccessPolicyFromGroup = (route) =>
  axiosClient.put(route, Utils.requestHeader());

export {
  fetchAllCardHolderGroups,
  addCardHolderGroup,
  fetchCardHolderGroupById,
  updateCardHolderGroup,
  detachCardholderFromGroup,
  detachAccessPolicyFromGroup,
};
