import { lazy } from 'react';
import withSuspense from '../withSuspense';
import EditCardholderGroup from '../../pages/cardholderGroups/Edit/EditCardholderGroup';

const ManageListing = withSuspense(
  lazy(() => import('../../pages/cardholderGroups/Listing/ManageListing')),
);

export const cardHolderGroupsRoutes = [
  { path: '', element: <ManageListing /> },
  { path: 'listing', element: <ManageListing /> },
  { path: 'edit', element: <EditCardholderGroup /> },
];
