import React, { useEffect, useState } from 'react';
import ResponsiveStack from '../../../components/common/SiderBarDrawer/ResponsiveStack';
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  Typography,
} from '@mui/material';

const PolicyListing = ({ policies, setPolicyIds, policyIds = [], loading }) => {
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const policyStatus = [
    { status: 'allow', style: { color: '#127320', bgColor: '#D6F0DA' } },
    { status: 'deny', style: { color: '#8B2E2E', bgColor: '#F0D6D6' } },
    { status: 'inactive', style: { color: '#141414', bgColor: '#D3D3D3' } },
  ];

  const StatusChip = ({ permission }) => {
    const statusStyle =
      policyStatus.find((item) => item.status === permission)?.style || {};
    return (
      <Chip
        label={permission?.charAt(0).toUpperCase() + permission?.slice(1)}
        sx={{
          color: statusStyle.color,
          backgroundColor: statusStyle.bgColor,
        }}
      />
    );
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allPolicyIds = policies?.map((policy) => policy?.policyId);
      setSelectedPolicies(allPolicyIds);
    } else {
      setSelectedPolicies([]);
    }
  };

  const handlePolicy = (policyId) => {
    setSelectedPolicies((prevSelected) =>
      prevSelected.includes(policyId)
        ? prevSelected.filter((id) => id !== policyId)
        : [...prevSelected, policyId],
    );
    setPolicyIds((prevSelected) =>
      prevSelected.includes(policyId)
        ? prevSelected.filter((id) => id !== policyId)
        : [...prevSelected, policyId],
    );
  };
  const isPolicySelected = (policyId) => selectedPolicies?.includes(policyId);
  const isAllSelected =
    selectedPolicies?.length > 0 &&
    selectedPolicies?.length === policies?.length;

  useEffect(() => {
    if (JSON.stringify(selectedPolicies) !== JSON.stringify(policyIds)) {
      setSelectedPolicies(policyIds);
    }
  }, [loading]);

  return (
    <>
      <ResponsiveStack direction="row" spacing={0} alignItems="center">
        <Checkbox checked={isAllSelected} onChange={handleSelectAll}></Checkbox>
        <Typography variant="customSubTitleBold">
          {selectedPolicies?.length} of {policies?.length} Selected
        </Typography>
      </ResponsiveStack>
      <Box
        sx={{
          borderRadius: '8px',
          border: '1px solid',
          p: '6px 4px',
          width: '100%',
        }}
        height="350px"
        overflow="scroll"
      >
        {loading ? (
          <Box>Loading Polices</Box>
        ) : (
          <ResponsiveStack sx={{ width: '100%' }}>
            {policies.length > 0 ? (
              <ResponsiveStack
                direction="column"
                spacing={1.5}
                sx={{ p: '8px', width: '100%' }}
              >
                {policies &&
                  policies?.length > 0 &&
                  policies?.map((policy) => (
                    <>
                      <ResponsiveStack
                        direction="row"
                        spacing={0}
                        key={policy?.policyId}
                        alignItems="center"
                      >
                        <Checkbox
                          size="small"
                          checked={isPolicySelected(policy?.policyId)}
                          onChange={() => handlePolicy(policy?.policyId)}
                        ></Checkbox>
                        <FormControl fullWidth>
                          <ResponsiveStack direction="column" spacing={0}>
                            <Typography variant="customSubTitleBold">
                              {policy?.policyName}
                            </Typography>
                            {policy?.description && (
                              <Typography variant="customSubTitle">
                                {policy?.description}
                              </Typography>
                            )}
                          </ResponsiveStack>
                        </FormControl>
                        <FormControl fullWidth>
                          <ResponsiveStack
                            direction="row"
                            justifyContent="space-between"
                          >
                            {policy?.permission && (
                              <StatusChip permission={policy?.permission} />
                            )}
                            {policy?.type && (
                              <Chip
                                sx={{
                                  backgroundColor: 'transparent',
                                  borderRadius: '8px',
                                  border: `1px solid var(--greyscale_64)`,
                                  textTransform: 'capitalize',
                                  fontFamily: 'Noto Sans',
                                }}
                                label={policy?.type}
                              ></Chip>
                            )}
                          </ResponsiveStack>
                        </FormControl>
                      </ResponsiveStack>
                      <Divider
                        variant="middle"
                        sx={{ backgroundColor: '#000' }}
                      />
                    </>
                  ))}
              </ResponsiveStack>
            ) : (
              <Box>No Policies Found</Box>
            )}
          </ResponsiveStack>
        )}
      </Box>
    </>
  );
};

export default PolicyListing;
