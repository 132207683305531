import { createTheme } from '@mui/material/styles';
const theme = createTheme({
  typography: {
    customTitle: {
      fontFamily: 'Noto Sans',
      fontSize: '20px',
      fontWeight: 700,
      color: '#000',
      lineHeight: '20px',
    },
    customTitle600: {
      fontFamily: 'Noto Sans',
      fontSize: '18px',
      fontWeight: 500,
      color: '#000',
      lineHeight: '20px',
    },
    customTitleLarge: {
      fontFamily: 'Noto Sans',
      fontSize: '24px',
      fontWeight: 700,
      color: '#000',
      lineHeight: '20px',
    },
    customTitleMedium: {
      fontFamily: 'Noto Sans',
      fontSize: '15px',
      fontWeight: 500,
      color: '#000',
      lineHeight: '20px',
    },
    customSubTitle: {
      fontFamily: 'Noto Sans',
      fontSize: '14px',
      fontWeight: 500,
      color: `var(--brand_black)`,
      lineHeight: '20px',
    },
    customSubTitleDisable: {
      fontFamily: 'Noto Sans',
      fontSize: '12px',
      color: `var(--greyscale_80)`,
      lineHeight: '20px',
    },
    customSubTitleDisable48: {
      fontFamily: 'Noto Sans',
      fontSize: '14px',
      color: `var(--greyscale_48)`,
      lineHeight: '20px',
      fontWeight: 500,
    },
    customSubTitlePrimary: {
      fontFamily: 'Noto Sans',
      fontSize: '12px',
      color: `#4B70F5`,
      lineHeight: '20px',
      fontWeight: 700,
    },
    customSubTitleLow: {
      fontFamily: 'Noto Sans',
      fontSize: '10px',
      fontWeight: 500,
      color: `var(--brand_black)`,
    },
    customSubTitleBold: {
      fontFamily: 'Noto Sans',
      fontSize: '14px',
      fontWeight: 700,
      color: `var(--brand_black)`,
    },
    customSubTitleMedium: {
      fontFamily: 'Noto Sans',
      fontSize: '14px',
      fontWeight: 400,
      color: `var(--brand_black)`,
      lineHeight: '19.07px',
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          '@media (max-width:600px)': {
            margin: 0,
            borderRadius: 0,
            width: '100%',
            padding: 0,
          },
        },
      },
    },
  },
});

export default theme;
