import { combineReducers, configureStore } from '@reduxjs/toolkit';
import PoliciesReducer from './reducers/PoliciesReducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import AccountReducer from './reducers/AccountReducer';
import StreamingReducer from './reducers/StreamingReducer';
import EventsReducer from './reducers/EventsReducer';
import NVRDeviceReducer from './reducers/NVRDeviceReducer';
import ViewsReducer from './reducers/ViewsReducer';

const persistConfig = {
  key: 'root',
  storage,
};

const mainReducer = combineReducers({
  loggedInUserPolicies: PoliciesReducer,
  accounts: AccountReducer,
  streaming: StreamingReducer,
  events: EventsReducer,
  nvrDevice: NVRDeviceReducer,
  views: ViewsReducer,
});

const persistedReducer = persistReducer(persistConfig, mainReducer);

const Store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools:
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__(),
});

export default Store;
