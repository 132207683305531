import { useState, useRef } from 'react';
import './SignUp.scss';
import { Alert, Container, Col, Form, Row } from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import 'bootstrap/dist/css/bootstrap.css';
import { Link, useNavigate } from 'react-router-dom';
import { HiOutlineMail } from 'react-icons/hi';
import { SlCallEnd } from 'react-icons/sl';
import { BiWorld } from 'react-icons/bi';
import { CiLinkedin } from 'react-icons/ci';

import Autocomplete from 'react-google-autocomplete';
import ReCAPTCHA from 'react-google-recaptcha';
import { Header, PrimaryButton, TextBlock } from '../../components/common';
import timezones from '../../data/support/timezone.json';
import { TextField, SelectField } from '../../components/forms';
import { PiWarningCircleBold } from 'react-icons/pi';
import { GoSearch } from 'react-icons/go';
import { Utils, constants, AppDefaults } from '../../helpers';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { IoClose } from 'react-icons/io5';

// Schema for yup
const validationSchema = Yup.object().shape({
  orgName: Yup.string()
    .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
    .matches(/^[a-zA-Z0-9 ]+$/, constants.ONLY_ALPHA_NUMBERIC_ERROR_MESSAGE)
    .required(constants.NAME_REQUIRED_ERROR_MESSAGE),
  email: Yup.string()
    .email(constants.EMAIL_REQUIRED_ERROR_MESSAGE)
    .max(100, constants.EMAIL_REQUIRED_CANT_LESS_ERROR_MESSAGE)
    .required(constants.EMAIL_REQUIRED_ERROR_MESSAGE),
  firstName: Yup.string()
    .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
    .matches(/^[a-zA-Z ]+$/, constants.ONLY_ALPHABETS_ERROR_MESSAGE)
    .required(constants.FIRST_NAME_REQUIRED_ERROR_MESSAGE),
  lastName: Yup.string()
    .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
    .matches(/^[a-zA-Z ]+$/, constants.ONLY_ALPHABETS_ERROR_MESSAGE)
    .required(constants.LAST_NAME_REQUIRED_ERROR_MESSAGE),
  phoneNumber: Yup.string().matches(
    /^[0-9]{10}$/,
    constants.VALID_PHONE_NUMBER_ERROR_MESSAGE
  ),
  stepPartnerId: Yup.string()
    .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
    .matches(/^[a-zA-Z0-9 ]+$/, constants.ONLY_ALPHA_NUMBERIC_ERROR_MESSAGE)
    .required(constants.STEP_PARTNER_REQUIRED_ERROR_MESSAGE),
  salesPersonName: Yup.string()
    .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
    .matches(/^[a-zA-Z ]+$/, constants.ONLY_ALPHABETS_ERROR_MESSAGE),
  addressLine1: Yup.string().required(constants.ADDRESS_REQUIRED_ERROR_MESSAGE),
  orgWebsite: Yup.string().matches(
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/i,
    constants.WEBSITE_CORRECT_URL_MESSAGE
  ),
  orgLinkedInProfile: Yup.string().matches(
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/i,
    constants.WEBSITE_CORRECT_URL_MESSAGE
  ),
});

function BecameApartner() {
  const { t } = useTranslation();
  const [showLoader, setShowLoader] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptBetaAgreement, setAcceptBetaAgreement] = useState(false);
  const recaptchaRef = useRef();
  const [userMsg, setUserMsg] = useState('');
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const zoneTimeList = timezones?.data;
  const [currentUserTimezone, setCurrentUserTimezone] = useState({});
  const [visibleLocHelperText, setVisibleLocHelperText] = useState(false);
  const [locHelperText, setLocHelperText] = useState('');

  const changeTimezoneValue = (evebr, setFieldValue) => {
    let selectedVal = evebr?.target?.value;
    const getLocationSelected = zoneTimeList?.find(
      (zone) => zone.value === selectedVal
    );
    setCurrentUserTimezone(getLocationSelected);
    setFieldValue('timezone', getLocationSelected?.value);
    setVisibleLocHelperText(false);
  };

  const handleTermsChange = (event) => {
    setAcceptTerms(event.target.checked);
  };

  const tenantID =
    !process.env.REACT_APP_PROJECT && !AppDefaults.PROJECT_MEGATRON
      ? 'dc'
      : process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON
      ? 'hva'
      : 'dc';
  const appID = 'acs';

  const appId = 'acs';

  const disableInput = (event, values) => {
    if (values.addressLine1) {
      event.preventDefault();
      return false;
    }
    return true;
  };
  const checkBetaAgreement = () => {
    if (process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON) {
      if (acceptBetaAgreement) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  return (
    <>
      <div className="marketing-pages">
        <Header
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_96')}
          isAccountScreen={true}
          showNavigation={false}
        />
        <section className="main-page">
          <Container>
            <Row className="justify-content-md-center">
              <Col xs lg="8">
                <Container className="main-page-content mt-5 text-center">
                  <Row>
                    <Col className="remove-padding-responsive">
                      <div className="card main-card remove-padding-responsive">
                        <div className="card-body text-left">
                          <div className="text-center mb-1">
                            <TextBlock
                              fontWeight="500"
                              fontSize="1.25rem"
                              lineHeight="28px"
                            >
                              {process.env.REACT_APP_PROJECT ===
                              AppDefaults.PROJECT_MEGATRON
                                ? constants.ARE_YOU_EXISTING_STEP_PARTNER
                                : constants.PARTNER_REGISTER_HEADING}
                            </TextBlock>
                          </div>

                          <div className="text-center mb-2">
                            <TextBlock
                              fontWeight="700"
                              fontSize="1.5rem"
                              lineHeight="32px"
                              color="#111418"
                            >
                              {constants.PARTNER_REGISTER_SUB_HEADING}
                            </TextBlock>
                          </div>

                          <div className="text-center mb-4">
                            <TextBlock
                              fontWeight="500"
                              fontSize="0.875rem"
                              lineHeight="20px"
                            >
                              {t('PARTNER_REGISTER_FORM_INFO_HEADING')}
                            </TextBlock>
                          </div>

                          <Formik
                            initialValues={{
                              orgName: '',
                              phoneNumber: '',
                              email: '',
                              firstName: '',
                              lastName: '',
                              displayDetails: '',
                              addressLine1: '',
                              addressLine2: '',
                              city: '',
                              state: '',
                              country: '',
                              zipcode: '',
                              orgWebsite: '',
                              orgLinkedInProfile: '',
                              EIN: '00-0000000',
                              TIN: '00-0000000',
                              timezone: currentUserTimezone?.value,
                              stepPartnerId: '',
                              salesPersonName: '',
                            }}
                            // Hooks up our validationSchema to Formik
                            validationSchema={validationSchema}
                            onSubmit={async (values, { setSubmitting }) => {
                              // When button submits form and form is in the process of submitting, submit button is disabled
                              setSubmitting(true);
                              setShowLoader(true);
                              const recaptchaValue =
                                await recaptchaRef.current.executeAsync();

                              if (recaptchaValue) {
                                const emailData = values.email;
                                let yourFormattedEmail =
                                  emailData.toLocaleLowerCase();
                                const updatedValues = {
                                  ...values,
                                  email: yourFormattedEmail,
                                };
                                // delete updatedValues.phoneNumber; //=== remove phone number from request body as we don't have this field in the DB
                                delete updatedValues.displayDetails;
                                // Simulate submitting to database, shows us values submitted, resets form
                                axios
                                  .post(
                                    `${process.env.REACT_APP_API_BASE_URL}partner/onboard/v2`,
                                    updatedValues,
                                    {
                                      headers: {
                                        'X-Correlation-ID':
                                          Utils.generateUUID(),
                                        'X-tenant-id': tenantID,
                                        'X-app-Id': appID,
                                      },
                                    }
                                  )
                                  .then((res) => {
                                    let response = res.data;
                                    if (response?.meta?.code === 200) {
                                      navigate('/successpartner', {
                                        state: response.data,
                                      });
                                      setShowLoader(false);
                                    } else {
                                      setUserMsg(response?.meta?.userMsg);
                                      setShow(true);
                                      setShowLoader(false);
                                    }
                                    setSubmitting(false);
                                  });
                              } else {
                                setUserMsg(constants.CAPTCHA_ERROR_MESSAGE);
                                setShow(true);
                                setSubmitting(false);
                                setShowLoader(false);
                              }
                            }}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              setFieldValue,
                              setFieldTouched,
                              isValid,
                              dirty,
                              isSubmitting,
                            }) => (
                              <Form onSubmit={handleSubmit}>
                                <Row>
                                  <Col lg={6} xs={12}>
                                    <TextField
                                      required={true}
                                      removebottommargin="true"
                                      label=""
                                      placeholder={
                                        constants.FIRST_NAME_FIELD_LABEL
                                      }
                                      name="firstName"
                                      type="text"
                                    />
                                  </Col>
                                  <Col lg={6} xs={12}>
                                    <TextField
                                      required={true}
                                      removebottommargin="true"
                                      label=""
                                      placeholder={
                                        constants.LAST_NAME_FIELD_LABEL
                                      }
                                      name="lastName"
                                      type="text"
                                    />
                                  </Col>
                                </Row>

                                <TextField
                                  required={true}
                                  label=""
                                  placeholder={
                                    constants.BUSSINESS_NAME_FIELD_LABEL
                                  }
                                  name="orgName"
                                  type="text"
                                />
                                <div className="row">
                                  <div className="col position-relative">
                                    <TextField
                                      required={true}
                                      label=""
                                      placeholder={constants.STEP_PARTNER_CODE}
                                      name="stepPartnerId"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col position-relative">
                                    <TextField
                                      required={false}
                                      label=""
                                      placeholder={constants.SALES_PERSON_NAME}
                                      name="salesPersonName"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <TextField
                                  required={true}
                                  beforeinputicon={
                                    <HiOutlineMail size={15} stroke="#566376" />
                                  }
                                  label=""
                                  placeholder={
                                    constants.BUSSINESS_EMAIL_ADDRESS_FIELD_LABEL
                                  }
                                  name="email"
                                  type="text"
                                />

                                <TextField
                                  beforeinputicon={
                                    <SlCallEnd
                                      fill="#566376"
                                      size={15}
                                      className="call-icon"
                                    />
                                  }
                                  label=""
                                  placeholder={
                                    constants.BUSSINESS_PHONE_FIELD_LABEL
                                  }
                                  name="phoneNumber"
                                  type="text"
                                />

                                <div className="mt-3 mb-3 row">
                                  <div className="col position-relative input-with-icons address-with-reset">
                                    <div>
                                      <div className="icon-before-input">
                                        <GoSearch fill="#566376" size={15} />
                                      </div>
                                      <Autocomplete
                                        name="displayDetails"
                                        placeholder=""
                                        apiKey={
                                          process.env.REACT_APP_GOOGLE_PLACE_URL
                                        }
                                        onChange={(evt) => {
                                          if (!evt.target.value) {
                                            setFieldValue('zipcode', '');
                                            setFieldValue('country', '');
                                            setFieldValue('city', '');
                                            setFieldValue('state', '');
                                            setFieldValue('addressLine1', '');
                                          }
                                        }}
                                        onBlur={handleBlur}
                                        onKeyDown={(e) =>
                                          disableInput(e, values)
                                        }
                                        // onFocus={
                                        //   (e) => e.target.setAttribute("autoComplete", "none")
                                        // }
                                        id="displayDetails"
                                        options={{
                                          fields: [
                                            'formatted_address',
                                            'geometry',
                                            'name',
                                            'address_components',
                                          ],
                                          strictBounds: false,
                                          types: ['address'],
                                        }}
                                        onPlaceSelected={(place) => {
                                          if (
                                            place?.address_components?.length
                                          ) {
                                            setFieldValue(
                                              'lat',
                                              place?.geometry?.location?.lat()
                                            );
                                            setFieldValue(
                                              'lng',
                                              place?.geometry?.location?.lng()
                                            );

                                            let addressComponent,
                                              addressComponentType,
                                              rawzipcode,
                                              rawcountry,
                                              rawcity,
                                              rawstate,
                                              rawaddline;
                                            for (
                                              let i = 0;
                                              i <
                                              place?.address_components?.length;
                                              i++
                                            ) {
                                              addressComponent =
                                                place.address_components[i];
                                              let j = 0;
                                              addressComponentType =
                                                addressComponent.types[j];
                                              if (
                                                addressComponentType ===
                                                'postal_code'
                                              ) {
                                                rawzipcode =
                                                  addressComponent.long_name
                                                    ? addressComponent.long_name
                                                    : '';
                                              }
                                              if (
                                                addressComponentType ===
                                                'country'
                                              ) {
                                                rawcountry =
                                                  addressComponent.long_name
                                                    ? addressComponent.short_name
                                                    : '';
                                              }
                                              if (
                                                addressComponentType ===
                                                'administrative_area_level_1'
                                              ) {
                                                rawstate =
                                                  addressComponent.long_name
                                                    ? addressComponent.short_name
                                                    : '';
                                              }
                                              if (
                                                addressComponentType ===
                                                'locality'
                                              ) {
                                                rawcity =
                                                  addressComponent.long_name
                                                    ? addressComponent.long_name
                                                    : '';
                                              }

                                              if (
                                                addressComponentType ===
                                                  'street_number' ||
                                                addressComponentType ===
                                                  'route' ||
                                                addressComponentType ===
                                                  'neighborhood' ||
                                                addressComponentType.includes(
                                                  'sublocality'
                                                )
                                              ) {
                                                rawaddline = `${
                                                  rawaddline ? rawaddline : ''
                                                }${
                                                  addressComponent.long_name
                                                }, `;
                                              }
                                            }
                                            setFieldValue(
                                              'zipcode',
                                              rawzipcode
                                            );
                                            setFieldValue(
                                              'country',
                                              rawcountry
                                            );
                                            setFieldValue('city', rawcity);
                                            setFieldValue('state', rawstate);
                                            setFieldValue(
                                              'addressLine1',
                                              rawaddline
                                            );
                                            setFieldValue(
                                              'displayDetails',
                                              (rawaddline ? rawaddline : '') +
                                                (rawcity
                                                  ? rawcity + ', '
                                                  : '') +
                                                (rawstate
                                                  ? rawstate + ', '
                                                  : '') +
                                                (rawcountry
                                                  ? rawcountry + ', '
                                                  : '') +
                                                (rawzipcode ? rawzipcode : '')
                                            );
                                            if (
                                              place?.geometry?.location?.lat() &&
                                              place?.geometry?.location?.lng()
                                            ) {
                                              var config = {
                                                method: 'get',
                                                url: `https://maps.googleapis.com/maps/api/timezone/json?location=${place?.geometry?.location?.lat()},${place?.geometry?.location?.lng()}&timestamp=${
                                                  new Date().getTime() / 1000
                                                }&language=es&key=${
                                                  process.env
                                                    .REACT_APP_GOOGLE_TIMEZONE_API_KEY
                                                }`,
                                                headers: {},
                                              };

                                              fetch(config?.url)
                                                .then(function (response) {
                                                  return response.json();
                                                })
                                                .then(function (json) {
                                                  if (json?.status === 'OK') {
                                                    const data =
                                                      zoneTimeList?.find(
                                                        (zone) =>
                                                          zone?.location ===
                                                          json?.timeZoneId
                                                      );
                                                    setCurrentUserTimezone(
                                                      data
                                                    );
                                                    if (data) {
                                                      setFieldValue(
                                                        'timezone',
                                                        data?.value
                                                      );
                                                      setLocHelperText(
                                                        constants.LOCATION_TIMEZONE_ORGANIZATION_MESSAGE
                                                      );
                                                    } else {
                                                      setLocHelperText(
                                                        constants.AUTO_TIMEZONE_FAIL_MESSAGE
                                                      );
                                                      setFieldValue(
                                                        'timezone',
                                                        ''
                                                      );
                                                    }
                                                    setVisibleLocHelperText(
                                                      true
                                                    );
                                                  } else {
                                                    setVisibleLocHelperText(
                                                      true
                                                    );
                                                    setLocHelperText(
                                                      constants.AUTO_TIMEZONE_FAIL_MESSAGE
                                                    );
                                                  }
                                                });
                                            }
                                          }
                                        }}
                                        className={`form-control shadow-none autoaddress ${
                                          touched.displayDetails &&
                                          (errors.addressLine1 ||
                                            !values.displayDetails) &&
                                          'is-invalid'
                                        } ${
                                          values.addressLine1 && 'has-value'
                                        }`}
                                      />
                                      {!document.getElementById(
                                        'displayDetails'
                                      )?.value ? (
                                        <span
                                          className={`placeholder-wrapper required`}
                                        >
                                          {
                                            constants.BUSSINESS_ADDRESS_FIELD_LABEL
                                          }
                                        </span>
                                      ) : null}
                                      {values.addressLine1 ? (
                                        <span
                                          className={`reset-address-wrapper`}
                                        >
                                          <IoClose
                                            size={15}
                                            onClick={() => {
                                              setFieldValue('zipcode', '');
                                              setFieldValue('country', '');
                                              setFieldValue('city', '');
                                              setFieldValue('state', '');
                                              setFieldValue('addressLine1', '');
                                              setFieldValue(
                                                'displayDetails',
                                                ''
                                              );
                                              setFieldValue('timezone', '');
                                              document.getElementById(
                                                'displayDetails'
                                              ).value = '';
                                              setFieldTouched(
                                                'displayDetails',
                                                true,
                                                false
                                              );
                                            }}
                                          />
                                        </span>
                                      ) : null}
                                    </div>
                                    <ErrorMessage
                                      component="div"
                                      name="addressLine1"
                                      className="error"
                                    />
                                    {touched.displayDetails &&
                                      (!values.addressLine1 ||
                                        !values.city ||
                                        !values.state ||
                                        !values.country ||
                                        !values.zipcode) && (
                                        <div className="error">
                                          {
                                            constants.ADDRESS_FIELD_ERROR_MESSAGE
                                          }
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col position-relative">
                                    <div>
                                      <SelectField
                                        isFromOrg={true}
                                        name="timezone"
                                        options={zoneTimeList}
                                        label=""
                                        defaultOptionLabel="Time Zone"
                                        value={values.timezone}
                                        onChange={(evebr) =>
                                          changeTimezoneValue(
                                            evebr,
                                            setFieldValue
                                          )
                                        }
                                        beforeinputicon={
                                          <GoSearch fill="#566376" size={15} />
                                        }
                                        placeholderClass={
                                          currentUserTimezone?.value
                                            ? ''
                                            : 'timezone-placeholder'
                                        }
                                      />
                                    </div>
                                    <ErrorMessage
                                      component="div"
                                      name="timezone"
                                      className="info-timezone"
                                    />
                                    {visibleLocHelperText ? (
                                      <div className="info-timezone">
                                        <PiWarningCircleBold
                                          color={getComputedStyle(
                                            document.documentElement
                                          ).getPropertyValue('--greyscale_56')}
                                          size={16}
                                        />
                                        <div className="message-info">
                                          {locHelperText}
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <Row>
                                  <Col lg={6} xs={12}>
                                    <TextField
                                      removetopmargin="true"
                                      beforeinputicon={
                                        <BiWorld size={15} fill="#566376" />
                                      }
                                      label=""
                                      placeholder={
                                        constants.BUSSINESS_WEBSITE_NAME_FIELD_LABEL
                                      }
                                      name="orgWebsite"
                                      type="text"
                                    />
                                  </Col>
                                  <Col lg={6} xs={12}>
                                    <TextField
                                      removetopmargin="true"
                                      beforeinputicon={
                                        <CiLinkedin size={15} fill="#566376" />
                                      }
                                      label=""
                                      placeholder={
                                        constants.BUSSINESS_LINKEDIN_PROFILE_NAME_FIELD_LABEL
                                      }
                                      name="orgLinkedInProfile"
                                      type="text"
                                    />
                                  </Col>
                                </Row>

                                <Form.Group className="" controlId="reCaptcha">
                                  <ReCAPTCHA
                                    name="recaptcha"
                                    ref={recaptchaRef}
                                    size="invisible"
                                    sitekey={process.env.REACT_APP_CAPATCHA_ID}
                                  />
                                </Form.Group>

                                <Form.Group as={Row} className="">
                                  <Col>
                                    {show && (
                                      <Alert
                                        variant="danger"
                                        className="text-start"
                                        onClose={() => setShow(false)}
                                        dismissible
                                      >
                                        {userMsg}
                                      </Alert>
                                    )}
                                  </Col>
                                </Form.Group>
                                <Form.Group
                                  className={`remember-me pt-3 ${
                                    process.env.REACT_APP_PROJECT ===
                                    AppDefaults.PROJECT_MEGATRON
                                      ? 'pb-1'
                                      : 'pb-3'
                                  }`}
                                  controlId="formHorizontalCheck"
                                >
                                  <Form.Check
                                    id={`check-api`}
                                    className="d-flex"
                                  >
                                    <Form.Check.Input
                                      checked={acceptTerms}
                                      onChange={handleTermsChange}
                                      role="button"
                                      className="custom-form-check-input"
                                    />
                                    <Form.Check.Label
                                      className="ms-2 ms-xxl-3 text-start"
                                      role="button"
                                    >
                                      {constants.FORM_CHECK_TERMS_CONTENT}
                                      <a
                                        href={`${process.env.REACT_APP_API_BASE_URL}user/apps/${tenantID}/${appId}/tos`}
                                        target="_blank"
                                        class="primary_anchor pe-1"
                                        rel="noreferrer"
                                      >
                                        {
                                          constants.FOOTER_TERMS_CONDITION_LINK_TEXT
                                        }
                                      </a>
                                      {`${constants.AND_TEXT_POLICY_SERVICE}`}
                                      <a
                                        href={`${process.env.REACT_APP_API_BASE_URL}user/apps/${tenantID}/${appId}/privacy`}
                                        target="_blank"
                                        className="form-privacy-link"
                                        rel="noreferrer"
                                      >
                                        {
                                          constants.FOOTER_PRIVACY_POLICY_LINK_TEXT
                                        }
                                      </a>
                                      .
                                    </Form.Check.Label>
                                  </Form.Check>
                                </Form.Group>
                                {process.env.REACT_APP_PROJECT ===
                                  AppDefaults.PROJECT_MEGATRON && (
                                  <Form.Group
                                    className="remember-me pb-3"
                                    controlId="formHorizontalCheck"
                                  >
                                    <Form.Check
                                      id={`check-beta-api`}
                                      className="d-flex"
                                    >
                                      <Form.Check.Input
                                        checked={acceptBetaAgreement}
                                        onChange={(event) =>
                                          setAcceptBetaAgreement(
                                            event.target.checked
                                          )
                                        }
                                        role="button"
                                        className="custom-form-check-input"
                                      />
                                      <Form.Check.Label
                                        className="ms-2 ms-xxl-3 text-start"
                                        role="button"
                                      >
                                        {constants.FORM_CHECK_TERMS_CONTENT}
                                        <a
                                          href={`${process.env.REACT_APP_API_BASE_URL}user/apps/${tenantID}/${appId}/agreement`}
                                          target="_blank"
                                          class="primary_anchor"
                                          rel="noreferrer"
                                        >
                                          {constants.BETA_AGREEMENT_TITLE}
                                        </a>
                                        .
                                      </Form.Check.Label>
                                    </Form.Check>
                                  </Form.Group>
                                )}
                                <Form.Group as={Row} className="mb-3 mt-3">
                                  <Col>
                                    <PrimaryButton
                                      className="btn btn-primary w-100"
                                      type="submit"
                                      disabled={
                                        !(
                                          dirty &&
                                          isValid &&
                                          acceptTerms &&
                                          checkBetaAgreement()
                                        ) ||
                                        currentUserTimezone === undefined ||
                                        !values.city ||
                                        !values.state ||
                                        !values.zipcode ||
                                        !values.country
                                      }
                                      loader={showLoader}
                                    >
                                      {constants.SUBMIT_BUTTON_LABEL}
                                    </PrimaryButton>
                                  </Col>
                                </Form.Group>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
}

export default BecameApartner;
