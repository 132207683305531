import React, { useEffect, useState } from 'react';
import PolicyListing from '../Create/PolicyListing';
import ReusableDialog from '../../../components/common/ResuableDialog';
import useGetOrgPolicies from '../../../hooks/accessPolicies/useGetOrgPolicies';
import { useCardHolderGroupStore } from '../../../store/cardHolderGroupStore';
import useGetCardHolder from '../../../hooks/cardholder/useGetCardHolder';
import CardHolderListing from './CardHolderListing';

const AddCardHolders = ({ showModal, hideModal, setFieldValue }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [orgPolicies, setOrgPolicies] = useState([]);
  const [orgCardHolders, setOrgCardHolders] = useState([]);
  const { fetchOrgPolicies } = useGetOrgPolicies();
  const { fetchCardHolders } = useGetCardHolder();
  const [policyIds, setPolicyIds] = useState([]);
  const [cardHolderIds, setCardHolderIds] = useState([]);
  const { setCardHoldersToGroup, getAttachedCardHolders } =
    useCardHolderGroupStore();
  const getAndSetCardHolders = async () => {
    if (!showModal) return;
    const res = await fetchCardHolders();
    setOrgCardHolders(res?.result);
  };

  const handleSubmitForm = async () => {
    const selectedCardHolders = !cardHolderIds.length
      ? undefined
      : orgCardHolders?.filter((cardHolder) =>
          cardHolderIds.includes(cardHolder?.cardHolderId),
        );

    setCardHoldersToGroup(selectedCardHolders);
    hideModal();
  };

  const setCardHoldersOnLoad = () => {
    if (getAttachedCardHolders()?.length > 0) {
      const cardHolderIds = getAttachedCardHolders()?.map(
        (cardHolder) => cardHolder.cardHolderId,
      );

      setCardHolderIds(cardHolderIds);
    }
  };

  useEffect(() => {
    getAndSetCardHolders();
  }, [showModal]);

  useEffect(() => {
    setCardHoldersOnLoad();
  }, []);

  useEffect(() => {
    setFieldValue(
      'selectedCardHolders',
      getAttachedCardHolders()?.filter((cardHolder) =>
        cardHolderIds.includes(cardHolder?.cardHolderId),
      ),
    );
  }, [cardHolderIds]);

  if (!showModal) return;

  return (
    <ReusableDialog
      title={'Assign Cardholders'}
      open={showModal}
      handleClose={hideModal}
      isForm={true}
      onFormSubmit={() => handleSubmitForm()}
      isValid={cardHolderIds.length}
      isLoading={isLoading}
      actionButtons={{
        cancelButtonText: 'Cancel',
        submitButtonText: 'Assign Cardholders',
      }}
      maxWidth="md"
    >
      <CardHolderListing
        cardHolders={orgCardHolders}
        setCardHolderIds={setCardHolderIds}
        cardHolderIds={cardHolderIds}
      />
    </ReusableDialog>
  );
};

export default AddCardHolders;
