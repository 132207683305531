import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    fontFamily: 'Noto Sans, sans-serif',
  },
  '& .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
    },
  },
}));
export default CustomTextField;
