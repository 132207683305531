import { Utils } from '../../../helpers';
import axiosClient from '../axiosClient';

const getCardHolders = (route) => axiosClient.get(route, Utils.requestHeader());
const getCardHolder = (route) => axiosClient.get(route, Utils.requestHeader());
const deleteCardHolder = (route) =>
  axiosClient.delete(route, Utils.requestHeader());
const addCardHolder = (route, body) =>
  axiosClient.post(route, body, Utils.requestHeader());
const createAccountHolder = (route, body) =>
  axiosClient.post(route, body, Utils.requestHeader());
const updateCardHolder = (route, body) =>
  axiosClient.put(route, body, Utils.requestHeader());
const attachPolicyToCardHolder = (route) =>
  axiosClient.post(route, Utils.requestHeader());
const attachCardHolderGroupToCardHolder = (route) =>
  axiosClient.post(route, Utils.requestHeader());
const detachPolicyToCardHolder = (route) =>
  axiosClient.put(route, Utils.requestHeader());
const detachGroupToCardHolder = (route) =>
  axiosClient.put(route, Utils.requestHeader());
const manageCredentials = (route, body) =>
  axiosClient.post(route, body, Utils.requestHeader());
const updateCredentials = (route, body) =>
  axiosClient.put(route, body, Utils.requestHeader());
const deleteCredential = (route) =>
  axiosClient.delete(route, Utils.requestHeader());
const fetchSignedUrl = (route) => axiosClient.get(route, Utils.requestHeader());
const fetchActivatingTodayCardholders = (route) =>
  axiosClient.get(route, Utils.requestHeader());
const fetchExpiredTodayCardHolders = (route) =>
  axiosClient.get(route, Utils.requestHeader());
const fetchInactiveCardHolders = (route) =>
  axiosClient.get(route, Utils.requestHeader());
const fetchCardHolderMetaData = (route) =>
  axiosClient.get(route, Utils.requestHeader());

export {
  updateCardHolder,
  getCardHolders,
  addCardHolder,
  createAccountHolder,
  deleteCardHolder,
  getCardHolder,
  attachPolicyToCardHolder,
  detachPolicyToCardHolder,
  manageCredentials,
  updateCredentials,
  deleteCredential,
  fetchSignedUrl,
  fetchActivatingTodayCardholders,
  fetchExpiredTodayCardHolders,
  fetchInactiveCardHolders,
  fetchCardHolderMetaData,
  attachCardHolderGroupToCardHolder,
  detachGroupToCardHolder,
};
