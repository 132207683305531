export const apiMessages = {
  CARDHOLDER_CREATED: 'Card holder created successfully!',
  CARDHOLDER_UPDATED: 'Updated card holder successfully!',
  DELETE_CARDHOLDER: 'Card holder deleted successfully!',
  DOOR_TEMPLATE_CREATED: 'Door template created successfully!',
  DOOR_TEMPLATE_DELETED: 'Door template deleted successfully!',
  IO_CONFIG_SAVED: 'IO Config settings saved!',
  SOMETHING_WENT_WRONG: 'Something went wrong',
  CONTROLLER_DEVICE_ADD: 'Device added successfully!',
  DISCOVERY_DEVICE_REMOVE: 'Device removed successfully!',
  CONTROLLER_DEVICE_REMOVE: 'Controller removed successfully!',
  DOOR_DELETED: (name) =>
    `You have deleted the Door '${name}' from the system.`,
  CREATE_NEW_POLICY: 'New Access Policy created!',
  UPDATE_NEW_POLICY: 'Access Policy has been updated!',
  DELETE_POLICY: 'Policy has been Deleted!',
  ATTACH_POLICY_TO_GROUP: 'Policy successfully attached to group!',
  DETACH_POLICY_TO_GROUP: 'Policy successfully detached from group!',
  ATTACH_DOOR_SCHEDULE_TO_POLICY: 'Doors successfully attached to policy!',
  UPDATE_DOOR_SCHEDULE_TO_POLICY: 'Doors updated successfully to policy!',
  CARDHOLDER_ATTACH_POLICY: 'Policy attached successfully!',
  CARDHOLDER_DETACH_POLICY: 'Policy detached successfully!',
  CARDHOLDER_ATTACH_GROUP: 'Group attached successfully!',
  CARDHOLDER_DETACH_GROUP: 'Group detached successfully!',
  SCHEDULE_ADD: 'Schedule created successfully!',
  SCHEDULE_UPDATE: 'Schedule updated successfully!',
  SCHEDULE_DELETE: (name) =>
    `You have deleted the Schedule '${name}' from the system.`,
  ALARM_ADD: 'Alarm created successfully!',
  ALARM_UPDATE: 'Alarm updated successfully!',
  ALARM_DELETE: (name) =>
    `You have deleted the Alarm '${name}' from the system.`,
  ELEVATOR_ADD: 'Elevator created successfully!',
  ELEVATOR_UPDATE: 'Elevator updated successfully!',
  ELEVATOR_DELETE: (name) =>
    `You have deleted the Elevator '${name}' from the system.`,
  ELEVATOR_TEMPLATE_ADD: 'Elevator Template created successfully!',
  ELEVATOR_TEMPLATE_UPDATE: 'Elevator Template updated successfully!',
  ELEVATOR_Template_DELETE: (name) =>
    `You have deleted the Elevator Template '${name}' from the system.`,
  ZONE_ADD: 'Zone created successfully!',
  ZONE_UPDATE: 'Zone updated successfully!',
  ZONE_DELETE: (name) =>
    `You have deleted the Zone '${name}' from the system.`,
  CREATE_CARDHOLDER_CREDENTIALS: 'Credential created successfully!',
  UPDATE_CARDHOLDER_CREDENTIALS: 'Updated credentials successfully!',
  DELETE_CARDHOLDER_CREDENTIAL: 'Deleted credential successfully!',
  LOCATION_ADD: 'Location created successfully!',
  LOCATION_UPDATE: 'Location updated successfully!',
  LOCATION_DELETE: 'Location deleted successfully!',
  AREA_ADD: 'Area created successfully!',
  AREA_UPDATE: 'Area updated successfully!',
  AREA_DELETE: 'Area deleted successfully!',
  AREA_ATTACH_DOORS: 'Area Doors updated successfully!',
  AREA_ATTACH_SCHEDULES: 'Area Schedules updated successfully!',
  AREA_DOOR_REASSIGN: 'Door reassign to another area',
  CARDHOLDER_GROUP_CREATED: 'Created Cardholder group successfully!',
  CARDHOLDER_GROUP_UPDATED: 'Updated Cardholder group successfully!',
  CARDHOLDER_DETACHED_FROM_GROUP: 'Cardholder removed from group successfully!',
  POLICY_DETACHED_FROM_GROUP: 'Policy remove from group successfully',
};
