import { useGlobalApi } from '../../services/hooks/useGlobalApi';
import useRoutingApiPaths from '../useRoutingApiPaths';
import { apiMessages } from '../../helpers';
import {
  addCardHolderGroup,
  updateCardHolderGroup,
} from '../../services/api/cardholdergroups';
import { useCardHolderGroupStore } from '../../store/cardHolderGroupStore';

const useCardHolderGroups = () => {
  const { executeAsyncOperation } = useGlobalApi();
  const path = useRoutingApiPaths();
  const {
    setCardHolderGroups,
    setCardHolderGroupById,
    removeCardHolderFromGroup,
    removeAccessPolicyFromGroup,
  } = useCardHolderGroupStore();

  const listAllCardHolderGroups = async () => {
    const res = await executeAsyncOperation(
      setCardHolderGroups,
      null,
      path.CARDHOLDER_GROUPS.getAllCardHolderGroups,
    );
    return res?.data;
  };

  const detailsOfCardHolderGroupById = async (cardHolderGroupId) => {
    const res = await executeAsyncOperation(
      setCardHolderGroupById,
      null,
      path.CARDHOLDER_GROUPS.getCardHolderGroupById(cardHolderGroupId),
    );
    return res?.data;
  };
  const saveCardHolderGroup = async (data) => {
    const res = await executeAsyncOperation(
      addCardHolderGroup,
      apiMessages.CARDHOLDER_GROUP_CREATED,
      path.CARDHOLDER_GROUPS.createCardHolderGroup,
      data,
    );
    return res?.data;
  };
  const modifyCardHolderGroup = async (data, groupId) => {
    const res = await executeAsyncOperation(
      updateCardHolderGroup,
      apiMessages.CARDHOLDER_GROUP_UPDATED,
      path.CARDHOLDER_GROUPS.updateCardHolderGroup(groupId),
      data,
    );
    return res?.data;
  };
  const deleteCardHolderFromGroup = async (groupId, cardHolderId) => {
    const res = await executeAsyncOperation(
      removeCardHolderFromGroup,
      apiMessages.CARDHOLDER_DETACHED_FROM_GROUP,
      path.CARDHOLDER_GROUPS.deleteCardHolderFromGroup(groupId, cardHolderId),
      cardHolderId,
    );
    return res;
  };
  const deleteAccessPolicyFromGroup = async (policyId, groupId) => {
    const res = await executeAsyncOperation(
      removeAccessPolicyFromGroup,
      apiMessages.POLICY_DETACHED_FROM_GROUP,
      path.CARDHOLDER_GROUPS.deleteAccessPolicyFromGroup(policyId, groupId),
      policyId,
    );
    return res;
  };
  return {
    listAllCardHolderGroups,
    saveCardHolderGroup,
    detailsOfCardHolderGroupById,
    modifyCardHolderGroup,
    deleteCardHolderFromGroup,
    deleteAccessPolicyFromGroup,
  };
};

export default useCardHolderGroups;
