import {
  Box,
  FormControl,
  Grid,
  Paper,
  TableContainer,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ResponsiveStack from '../../../components/common/SiderBarDrawer/ResponsiveStack';
import CustomFormLabel from '../../../components/common/CustomFormLabel';
import { CustomTextField } from '../../../components/forms';
import useCardHolderGroups from '../../../hooks/cardholderGroups/useCardHolderGroups';
import { useSearchParams } from 'react-router-dom';
import CustomButton from '../../../components/forms/CustomButton';
import { useCardHolderGroupStore } from '../../../store/cardHolderGroupStore';

import PolicyListTable from './PolicyListTable';
import AddPolicies from './AddPolicies';

const GeneralInformation = ({
  handleChange,
  handleBlur,
  values,
  touched,
  errors,
  setFieldValue,
}) => {
  const [showModal, setShowModal] = useState(false);

  const [searchParams] = useSearchParams();
  const groupId = searchParams.get('groupId');
  const { detailsOfCardHolderGroupById } = useCardHolderGroups();
  const { getCardHolderGroupAccessPolicies, getCardHolderGroupByIdDetails } =
    useCardHolderGroupStore();

  const hideModal = () => {
    setShowModal(false);
  };

  const getCardHolderGroupDetails = async () => {
    await detailsOfCardHolderGroupById(groupId);
  };
  useEffect(() => {
    if (!Object.keys(getCardHolderGroupByIdDetails()).length)
      getCardHolderGroupDetails();
  }, []);

  return (
    <Box className="App">
      <ResponsiveStack direction="column" spacing={0} smallScreenSpacing={0}>
        <ResponsiveStack direction="row" justifyContent="space-between">
          <Typography variant="customTitle">General Information</Typography>
        </ResponsiveStack>
        <ResponsiveStack sx={{ width: '50%' }}>
          <Grid container rowSpacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <ResponsiveStack direction="row" justifyContent="space-between">
                  <CustomFormLabel>
                    Group Name
                    <span style={{ color: 'red' }}>*</span>
                  </CustomFormLabel>
                  <Typography variant="customSubTitleDisable">
                    Up to 25 characters
                  </Typography>
                </ResponsiveStack>
                <CustomTextField
                  size="small"
                  name="groupName"
                  value={values.groupName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.groupName && Boolean(errors.groupName)}
                  helperText={touched.groupName && errors.groupName}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <ResponsiveStack direction="row" justifyContent="space-between">
                  <CustomFormLabel>Description</CustomFormLabel>
                  <Typography variant="customSubTitleDisable">
                    Up to 25 characters
                  </Typography>
                </ResponsiveStack>
                <CustomTextField
                  name="description"
                  multiline
                  minRows={4}
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.description && Boolean(errors.description)}
                  helperText={touched.description && errors.description}
                />
              </FormControl>
            </Grid>
          </Grid>
        </ResponsiveStack>
        <ResponsiveStack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: '44.5px', pt: 8 }}
        >
          <ResponsiveStack direction="column" spacing={0.5}>
            <Typography variant="customTitle">Access Policies</Typography>
            <Typography variant="customSubTitle">
              Identify the zones accessible to a cardholder, with the option to
              restrict access based on a schedule.
            </Typography>
          </ResponsiveStack>

          <CustomButton variant="contained" onClick={() => setShowModal(true)}>
            Add Access Policies
          </CustomButton>
        </ResponsiveStack>
        <ResponsiveStack alignItems="center" sx={{ pt: 8 }}>
          <TableContainer
            component={Paper}
            sx={{ overflowX: 'auto', pl: 2, pr: 2, borderRadius: '20px' }}
          >
            {!getCardHolderGroupAccessPolicies() ||
            getCardHolderGroupAccessPolicies().length <= 0 ? (
              <Box
                height="100vh"
                alignItems="center"
                justifyContent="center"
                display="flex"
              >
                <ResponsiveStack direction="column" spacing={1}>
                  <Typography variant="">
                    You haven't added any Access Policies yet
                  </Typography>
                  <CustomButton
                    variant="text"
                    sx={{ textDecoration: 'underline' }}
                    onClick={() => setShowModal(true)}
                    disableRipple
                  >
                    <Typography variant="customSubTitleBold">
                      Add Access Policies
                    </Typography>
                  </CustomButton>
                </ResponsiveStack>
              </Box>
            ) : (
              <PolicyListTable {...{ getCardHolderGroupAccessPolicies }} />
            )}
          </TableContainer>
        </ResponsiveStack>
        {showModal && (
          <AddPolicies
            {...{ showModal, hideModal, setFieldValue }}
          ></AddPolicies>
        )}
      </ResponsiveStack>
    </Box>
  );
};

export default GeneralInformation;
