import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  Box,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

const ReusableTable = ({
  columns,
  data,
  order,
  orderBy,
  onRequestSort,
  onTableRowClick,
}) => {
  const createSortHandler = (event, property) => {
    onRequestSort(event, property);
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
      : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {columns?.map((column) => (
              <TableCell
                key={column?.id}
                align={column?.align || 'left'}
                sortDirection={orderBy === column?.id ? order : false}
              >
                {column.sortable ? (
                  <TableSortLabel
                    active={orderBy === column?.id}
                    direction={orderBy === column?.id ? order : 'asc'}
                    onClick={(event) => createSortHandler(event, column?.id)}
                  >
                    {column?.label}
                    {orderBy === column?.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  column?.label
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(data, getComparator(order, orderBy))?.map(
            (row, index) => (
              <TableRow
                sx={{ cursor: 'pointer' }}
                key={row?.id}
                onClick={
                  onTableRowClick ? () => onTableRowClick(row, index) : null
                }
              >
                {columns?.map((column) => (
                  <TableCell
                    key={`${row?.id}-${column?.id}`}
                    align={column?.align || 'left'}
                  >
                    {column?.render
                      ? column.render(row, index)
                      : row[column?.id]}
                  </TableCell>
                ))}
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default ReusableTable;
