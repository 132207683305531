import { Header } from '../../components/common';
import { constants } from '../../helpers';
import { Box, Container, Grid, Typography } from '@mui/material';

const PageNotFound = () => {
  return (
    <Box>
      <Header />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid xs={6}>
              <Typography variant="h1">404</Typography>
              <Typography variant="h6">
                {constants.PAGE_NOT_FOUND_TITLE}
              </Typography>
            </Grid>
            <Grid xs={6}>
              <img
                src="https://cdn.pixabay.com/photo/2017/03/09/12/31/error-2129569__340.jpg"
                alt=""
                width={500}
                height={250}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default PageNotFound;
