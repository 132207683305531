import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';

const CustomButton = styled(Button)(({ theme, variant }) => ({
  backgroundColor:
    variant === 'contained' ? `var(--brand_black)` : 'transparent', // Black background
  color: variant === 'contained' ? `var(--brand_white)` : `var(--brand_black)`, // White text
  borderRadius: '8px', // Rounded corners
  padding: theme.spacing(1, 3), // Padding
  '&:hover': {
    backgroundColor:
      variant === 'contained' ? `var(--brand_black)` : 'transparent',
    borderColor: variant === 'outlined' ? `var(--brand_black)` : 'none',
    boxShadow: 'none',
    color:
      variant === 'contained' ? `var(--brand_white)` : `var(--brand_black)`,
  },
  fontFamily: 'Noto Sans',
  textTransform: 'capitalize',
  fontSize: '14px',
  fontWeight: 500,
  border: variant === 'outlined' ? `1px solid  var(--brand_black)` : 'none',
  boxShadow: 'none',
}));

export default CustomButton;
