import { Select } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomSelectField = styled(Select)(({ theme }) => ({
  '&.MuiInputBase-input': {
    fontFamily: 'Noto Sans, sans-serif',
  },
  '&.MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
}));

export default CustomSelectField;
