import React, { useEffect, useState } from 'react';
import PolicyListing from '../Create/PolicyListing';
import ReusableDialog from '../../../components/common/ResuableDialog';
import useGetOrgPolicies from '../../../hooks/accessPolicies/useGetOrgPolicies';
import { useCardHolderGroupStore } from '../../../store/cardHolderGroupStore';

const AddPolicies = ({ showModal, hideModal, setFieldValue }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [orgPolicies, setOrgPolicies] = useState([]);
  const { fetchOrgPolicies } = useGetOrgPolicies();
  const [policyIds, setPolicyIds] = useState([]);
  const { setCardHolderGroupIdPolicies, getCardHolderGroupAccessPolicies } =
    useCardHolderGroupStore();
  const setAccessPolicies = async () => {
    setIsLoading(true);
    if (!showModal) return;
    const res = await fetchOrgPolicies();
    setOrgPolicies(res);
    setPoliciesIdsOnLoad();
    setIsLoading(false);
  };

  const handleSubmitForm = async () => {
    const selectedPolicies = !policyIds.length
      ? undefined
      : orgPolicies?.filter((policy) => policyIds.includes(policy?.policyId));

    setCardHolderGroupIdPolicies(selectedPolicies);
    hideModal();
  };

  const setPoliciesIdsOnLoad = () => {
    if (getCardHolderGroupAccessPolicies()?.length > 0) {
      const policyIds = getCardHolderGroupAccessPolicies()?.map(
        (policy) => policy.policyId,
      );

      setPolicyIds(policyIds);
    }
  };

  useEffect(() => {
    setAccessPolicies();
  }, [showModal]);

  useEffect(() => {
    setFieldValue(
      'selectedPolicies',
      getCardHolderGroupAccessPolicies()?.filter((policy) =>
        policyIds.includes(policy?.policyId),
      ),
    );
  }, [policyIds]);

  if (!showModal) return;

  return (
    <ReusableDialog
      title={'Add Access Policies'}
      open={showModal}
      handleClose={hideModal}
      isForm={true}
      onFormSubmit={() => handleSubmitForm()}
      isValid={policyIds.length}
      isLoading={isLoading}
      actionButtons={{
        cancelButtonText: 'Cancel',
        submitButtonText: 'Add Access Policies',
      }}
      maxWidth="md"
    >
      <PolicyListing
        policies={orgPolicies}
        setPolicyIds={setPolicyIds}
        policyIds={policyIds}
        loading={isLoading}
      />
    </ReusableDialog>
  );
};

export default AddPolicies;
