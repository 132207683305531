import React, { lazy } from 'react';
import withSuspense from '../withSuspense';

const ManageCustomers = withSuspense(
  lazy(() =>
    import('../../pages/organizations').then((module) => ({
      default: module.ManageOrganizations,
    }))
  )
);

export const customerRoutes = [
  { path: 'manage.html', element: <ManageCustomers /> },
];
