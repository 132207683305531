import { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSearchParams } from 'react-router-dom';
import {
  PrimaryButton,
  SiteToast,
  TextBlock,
  SiteSpinner,
  SiteModal,
} from '../../components/common';
import {
  AssignLocations,
  SelectField,
  TextField,
  ToggleInput,
} from '../../components/forms';
import { constants, Utils, roles } from '../../helpers';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { useOrganizations } from '../../store/OrganizationsStore';
import { usePoliciesStore } from '../../store/policiesStore';
import { HiArrowLeft, HiCheckCircle } from 'react-icons/hi2';
import { FaCircle } from 'react-icons/fa';
import axios from 'axios';
import './EditUsers.scss';
import { IoShareSocialOutline } from 'react-icons/io5';
import CustomButton from '../../components/forms/CustomButton';
import axiosClient from '../../services/api/axiosClient';
// Schema for yup
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
    .matches(/^[a-zA-Z ]+$/, constants.ONLY_ALPHABETS_ERROR_MESSAGE)
    .required(constants.FIRST_NAME_REQUIRED_ERROR_MESSAGE),
  lastName: Yup.string()
    .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
    .matches(/^[a-zA-Z ]+$/, constants.ONLY_ALPHABETS_ERROR_MESSAGE)
    .required(constants.LAST_NAME_REQUIRED_ERROR_MESSAGE),
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      constants.EMAIL_VALID_ERROR_MESSAGE,
    )
    .required(constants.EMAIL_REQUIRED_ERROR_MESSAGE),
  phoneNumber: Yup.string().matches(
    /^[0-9]{10}$/,
    constants.VALID_PHONE_NUMBER_ERROR_MESSAGE,
  ),
});

function EditUser(props) {
  const accountData = props.accountData;
  const policyEditStatus = props?.policyEditStatus;
  const customerOrgUsers = props?.customerOrgUsersData;
  const [variant, setVariant] = useState('error');
  const [userMsg, setUserMsg] = useState('');
  const [searchParams] = useSearchParams();
  const [roleChange, setRolechange] = useState(
    accountData?.role === roles.ROLE2VMS ? true : false,
  );
  const [accountStatus, setAccountStatus] = useState(
    accountData?.accountStatus === 'ACTIVE' ? true : false,
  );
  const [fetchLocationsStatus, setFetchLocationsStatus] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [invitationStatus, setInvitationStatus] = useState(false);
  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
  const [showDeactivateUserModal, setShowDeactivateUserModal] = useState(false);
  const [disableResetButton, setDisableResetButton] = useState(false);
  const [resetPwdStatus, setResetPwdStatus] = useState(false);
  const [disableAccountStatus, setDisableAccountStatus] = useState(false);
  const orgId = searchParams.get('orgId');

  const getCustomerOrgLocations = useCustomerOrgLocations(
    (state) => state.getCustomerOrgLocations,
  );
  const customerOrgLocationsData = useCustomerOrgLocations(
    (state) => state.customerOrgLocationsData,
  );
  const getCustomerOrgLocationsData = useCustomerOrgLocations(
    (state) => state.getCustomerOrgLocationsData,
  );
  const getAllFilteredLocations = useCustomerOrgLocations(
    (state) => state.getAllFilteredLocations,
  );
  const getAreasCount = useCustomerOrgLocations((state) => state.getAreasCount);
  const getEditCustomerOrgLocations = useCustomerOrgLocations(
    (state) => state.getEditCustomerOrgLocations,
  );
  const getEditCustomerOrgLocationsData = useCustomerOrgLocations(
    (state) => state.getEditCustomerOrgLocationsData,
  );
  const [locationsData, setLocationsData] = useState(
    getEditCustomerOrgLocationsData() ? getEditCustomerOrgLocationsData() : [],
  );
  const [locationsDataChangeFlag, setLocationsDataChangeFlag] = useState(false);
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies,
  );
  const customerOrgData = useOrganizations((state) => state.customerOrgData);

  const orgDetails = customerOrgData.find((org) => org.orgId === orgId);
  const fetchLocations = async () => {
    try {
      await getCustomerOrgLocations(`partner/orgs/${orgId}/locations`);
      await getEditCustomerOrgLocations(
        `partner/orgs/${orgId}/accounts/${accountData.accountId}/locationAreas`,
      );
    } catch (error) {
      setVariant('error');
      setUserMsg(error.message);
    }
  };

  useEffect(() => {
    setLocationsData(getEditCustomerOrgLocationsData());
  }, [getEditCustomerOrgLocationsData()]);

  useEffect(() => {
    orgId && fetchLocations();
  }, [orgId]);

  useEffect(() => {
    if (accountData?.isLocked) {
      setDisableResetButton(true);
    } else {
      setDisableResetButton(false);
    }

    if (accountData?.accountStatus === 'PENDING_ACTIVATION') {
      setDisableAccountStatus(true);
    } else {
      setDisableAccountStatus(false);
    }
  }, [accountData]);

  useEffect(() => {
    if (fetchLocationsStatus) {
      let modalTitleHtml = (
        <>
          <PrimaryButton
            className="btn btn-primary-outline p-0"
            type="button"
            width="auto"
            height="20px"
            borderWidth="0"
            hoverBorderWidth="0"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--primary_40',
            )}
            hoverColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--primary_40')}
            hoverBackgroundColor="transparent"
            backgroundColor="transparent"
            onClick={() => {
              handleBackButtonClick();
            }}
          >
            <HiArrowLeft size={15} /> {constants.BACK_BUTTON_TITLE}
          </PrimaryButton>
          {constants.CUSTOMER_NEW_USER_ASSIGN_LOCATIONS_POPUP_TITLE}
        </>
      );
      props.handleModalTitle(modalTitleHtml);
    }
  }, [fetchLocationsStatus]);

  const handleBackButtonClick = () => {
    setFetchLocationsStatus(!fetchLocationsStatus);
    props.handleModalTitle(props.modalTitle);
  };

  // === Get Location and Areas HTML
  const getLocationAndAreasFields = (locations) => {
    if (locations?.length >= 1) {
      let locationsHtml = locations.map((location) => {
        let areas = location?.areas;
        let areaHtml = '';
        if (areas) {
          areaHtml = areas.map((area) => {
            return (
              <div className="location-areas-item" key={area.areaId}>
                <label
                  className="area-form-check-label"
                  htmlFor={`area-${area.areaId}`}
                >
                  {area.areaName}
                </label>
                <input
                  className="area-form-check-input"
                  type="checkbox"
                  checked={getAreaExists(area.areaId)?.toString() === 'true'}
                  value=""
                  id={`area-${area.areaId}`}
                  onChange={(event) => {
                    handleAddRemoveAreas(
                      event,
                      location.locationId,
                      area.areaId,
                    );
                  }}
                />
              </div>
            );
          });
        }

        return (
          <div className="locations-list-wrap" key={location.locationId}>
            <div className="location-label">{location.locationName}</div>
            <div className="location-areas-list">{areaHtml}</div>
          </div>
        );
      });
      return locationsHtml;
    }
  };

  //=== Check if Area is checked or not
  const getAreaExists = (areaId) => {
    let newLocation = [...locationsData];
    let location = false;

    if (newLocation?.length > 0) {
      const ifDataMatched = newLocation?.find((location) => {
        const area = location?.areas.find((area) => {
          return area.areaId === areaId && area?.checked?.toString() === 'true';
        });
        if (area) {
          return !!location;
        }
      });
      if (ifDataMatched) {
        location = true;
      }
    }
    return location;
  };

  //=== Check/uncheck areas
  const handleAddRemoveAreas = (event, locationId, areaId) => {
    let chkdValue = event.target.checked;
    let newLocation = [...locationsData];

    if (chkdValue) {
      //=== If checked
      if (newLocation.length === 0) {
        //=== If blank
        const userAreas = [];
        if (areaId) {
          userAreas.push({ areaId: areaId, checked: true });
        }
        newLocation.push({ locationId: locationId, areas: userAreas });
        setLocationsData(newLocation);
      } else {
        //=== If not blank
        const foundLoc = newLocation.some((el) => el.locationId === locationId);
        if (foundLoc) {
          //=== if location already exits - add area into it
          newLocation = newLocation.filter(function (obj) {
            let objArea = obj.areas;
            if (obj.locationId === locationId) {
              const foundArea = objArea.some((el) => el.areaId === areaId);
              if (!foundArea) {
                objArea.push({ areaId: areaId, checked: true });
                obj.areas = objArea;
              }
            }
            return obj;
          });
          setLocationsData(newLocation);
        } else {
          //=== if location not exits - add locaton & area both
          const userAreas = [];
          if (areaId) {
            userAreas.push({ areaId: areaId, checked: true });
          }
          newLocation.push({ locationId: locationId, areas: userAreas });
          setLocationsData(newLocation);
        }
      }
    } else {
      //=== If checked off - remove value from array
      const foundLoc = newLocation.some((el) => el.locationId === locationId);
      if (foundLoc) {
        newLocation = newLocation.filter((obj) => {
          if (obj?.areas.length > 0) {
            obj.areas = obj.areas.filter((area) => area.areaId !== areaId);
          }
          return obj;
        });
        setLocationsData(newLocation);
      }
    }
    setLocationsDataChangeFlag(true);
  };

  //=== Check all location areas
  const checkAllLocationAreas = () => {
    let newData = getAllFilteredLocations();

    try {
      if (newData?.length >= 1) {
        const allCheckedLocationData = newData.map((location) => {
          if (location?.areas) {
            location.areas = location?.areas.map((area) => {
              area.checked = true;
              return area;
            });
          }
          return location;
        });
        setLocationsData([...allCheckedLocationData]); //=== Update state to load data
        setLocationsDataChangeFlag(true);
      }
    } catch (error) {
      //TODO : to print the error will change this later
      console.error(error);
    }
  };

  //=== On click handle resend invitation button send invitation
  const handleResendInvitationClick = () => {
    setShowLoader(true);
    setUserMsg('');

    axios
      .post(`partner/resendAccountActivation`, {
        accountId: accountData.accountId,
      })
      .then((res) => {
        let response = res.data;
        if (response.meta.code === 200) {
          setInvitationStatus(true);
        } else {
          setVariant('error');
          setUserMsg(response?.meta?.userMsg);
        }
        setShowLoader(false);
      })
      .catch(function (error) {
        setVariant('error');
        setUserMsg(error?.message);
        setShowLoader(false);
      });
  };

  //=== On click remove this account button remove user and update user data
  const handleRemoveUserClick = () => {
    setShowLoader(true);
    setUserMsg('');
    axios
      .delete(
        `partner/orgs/${orgId}/accounts/${accountData.accountId}`,
        Utils.requestHeader(),
      )
      .then((res) => {
        let response = res.data;
        if (response.meta.code === 200) {
          props.reloadData();
          props.hideModal();
          setShowRemoveUserModal(false);
        } else {
          setVariant('error');
          setUserMsg(response?.meta?.userMsg);
        }
        setShowLoader(false);
      })
      .catch(function (error) {
        setVariant('error');
        setUserMsg(error?.message);
        setShowLoader(false);
      });
  };

  //=== Deactivate user status and show save button
  const handleDeactivateUserClick = (setFieldValue) => {
    setAccountStatus(false);
    setFieldValue('accountStatus', 'INACTIVE');
    setShowDeactivateUserModal(false);
  };

  const handleActivateUserClick = (setFieldValue) => {
    setAccountStatus(true);
    setFieldValue('accountStatus', 'ACTIVE');
    setShowDeactivateUserModal(false);
  };

  //=== On click remove this account button remove user and update user data
  const handleResetPasswordUserClick = () => {
    setUserMsg('');
    axios
      .post(`partner/orgs/${orgId}/accounts/${accountData.accountId}/resetPwd`)
      .then((res) => {
        let response = res.data;
        if (response.meta.code === 200) {
          setResetPwdStatus(true);
        } else {
          setVariant('error');
          setUserMsg(response?.meta?.userMsg);
        }
      })
      .catch(function (error) {
        setVariant('error');
        setUserMsg(error?.message);
      });
  };

  //=== On click show deactivate status popup
  const handleChangeAccountStatus = (event) => {
    setShowDeactivateUserModal(true);
    let statusValue = event.target.checked;
    setAccountStatus(statusValue);
  };

  const accountStatusHide = () => {
    setAccountStatus(true);
    setShowDeactivateUserModal(false);
  };

  const handleUnlockAccount = async () => {
    setShowLoader(true);
    axios
      .put(
        `/partner/orgs/${orgId}/accounts/${accountData?.accountId}/unlock`,
        {},
        Utils.requestHeader(),
      )
      .then(async (response) => {
        setShowLoader(false);
        await props.reloadData();
        props.hideModal();
        props.showUnlockModal();
      })
      .catch((error) => {
        setShowLoader(false);
        setVariant('error');
        setUserMsg(error?.message);
      });
  };

  const showAssignLocationStatus = (values) => {
    if (values?.role === '' || values.role === roles.ROLE2VMS) {
      return false;
    } else if (customerOrgLocationsData?.length > 1) {
      return true;
    } else if (
      customerOrgLocationsData?.length === 1 &&
      customerOrgLocationsData?.[0]?.areas?.length > 1
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getDisableStatus = () => {
    let isDisable = false;
    if (accountData?.role === roles.ROLE2VMS) {
      const custOrgAdmins = customerOrgUsers?.filter(
        (item) => item?.role === roles.ROLE2VMS,
      );
      if (custOrgAdmins?.length === 1) {
        isDisable = true;
      }
    }
    return isDisable;
  };

  return (
    <section
      style={{ pointerEvents: !policyEditStatus ? 'none' : 'auto' }}
      className="modal-main-content"
    >
      <div
        className={`parent-edit-modal ${
          resetPwdStatus ||
          showRemoveUserModal ||
          invitationStatus ||
          showDeactivateUserModal
            ? 'd-none'
            : ''
        }`}
      >
        <Row
          className={`${
            accountData?.accountStatus !== 'ACTIVE' ? 'mt-2' : ''
          } mb-4 status-info`}
        >
          {accountData?.accountStatus === 'ACTIVE' && accountData?.isLocked ? (
            <>
              <Col className="text-start">
                <span className="activeIcon">
                  <FaCircle size={10} className="status-icon-offline" />
                  {constants.LOCKED_ACCOUNT_TEST}
                </span>
              </Col>
              {policyEditStatus ? (
                <Col className="text-end">
                  <PrimaryButton
                    className={`btn btn-primary-outline p-0 ${
                      showLoader ? 'd-inline-flex' : ''
                    }`}
                    type="button"
                    height="20px"
                    width="auto"
                    borderWidth="0"
                    hoverBorderWidth="0"
                    fontWeight={600}
                    color={getComputedStyle(
                      document.documentElement,
                    ).getPropertyValue('--primary_40')}
                    hoverColor={getComputedStyle(
                      document.documentElement,
                    ).getPropertyValue('--primary_40')}
                    hoverBackgroundColor="transparent"
                    backgroundColor="transparent"
                    onClick={() => handleUnlockAccount()}
                  >
                    {constants.UNLOCK_ACCOUNT_BUTTON_TEXT}
                    {showLoader && (
                      <div className="spinner-wrapper">
                        <SiteSpinner width="15px" height="15px" />
                      </div>
                    )}
                  </PrimaryButton>
                </Col>
              ) : null}
            </>
          ) : accountData?.accountStatus === 'ACTIVE' ? (
            <Col className="text-start">
              <span className="activeIcon">
                <FaCircle size={10} className="status-icon-active" />{' '}
                {constants.EDIT_CUSTOMER_ACTIVE_STATUS_TITLE}
              </span>
            </Col>
          ) : accountData?.accountStatus === 'PENDING_ACTIVATION' ? (
            <>
              <Col className="text-start">
                <span className="activeIcon">
                  <FaCircle size={10} className="status-icon-invited" />{' '}
                  {constants.EDIT_CUSTOMER_INACTIVE_STATUS_TITLE}
                </span>
              </Col>
              <Col className="text-end">
                <PrimaryButton
                  className="btn btn-primary-outline p-0"
                  type="button"
                  height="20px"
                  width="auto"
                  borderWidth="0"
                  hoverBorderWidth="0"
                  color={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--primary_40')}
                  hoverColor={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--primary_40')}
                  hoverBackgroundColor="transparent"
                  backgroundColor="transparent"
                  onClick={() => handleResendInvitationClick()}
                >
                  {constants.EDIT_CUSTOMER_RESEND_INVITATION_BUTTON_TITLE}
                  {showLoader && (
                    <>
                      &nbsp;
                      <SiteSpinner width="15px" height="15px" />
                    </>
                  )}
                </PrimaryButton>
              </Col>
            </>
          ) : (
            <Col className="text-start">
              <span className="activeIcon">
                <FaCircle size={10} className="status-icon-disabled" />{' '}
                {constants.EDIT_CUSTOMER_INACTIVE_STATUS_TITLE}
              </span>
            </Col>
          )}
          <Col className="d-flex justify-content-end">
            <span className="activeIcon">
              <CustomButton variant="outlined" onClick={props.hideModal}>
                Cancel
              </CustomButton>
            </span>
          </Col>
        </Row>
        <Formik
          enableReinitialize={true}
          initialValues={{
            accountId: accountData.accountId,
            accountStatus: accountData.accountStatus,
            firstName: accountData.firstName,
            lastName: accountData.lastName,
            phoneNumber: accountData.phoneNumber,
            email: accountData.email,
            role: accountData.role,
            accountLanguage: accountData.accountLanguage,
            canShareAndDownload: accountData.canShareAndDownload,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            setUserMsg('');
            const filteredAllLocations = getAllFilteredLocations();
            // Simulate submitting to database
            if (orgId !== null && filteredAllLocations.length > 0) {
              // values.role = roleChange ? roles.ROLE2VMS : roles.ROLE3VMS;

              if (filteredAllLocations.length > 0) {
                if (
                  filteredAllLocations.length === 1 &&
                  getAreasCount() === 1
                ) {
                  values.locations = filteredAllLocations;
                } else {
                  if (values.role === roles.ROLE2VMS) {
                    values.locations = filteredAllLocations;
                  } else {
                    let checkedLocationsData = [];
                    if (locationsData?.length >= 1) {
                      let areas,
                        userAreas = [];
                      locationsData.forEach((location) => {
                        let ifExists = checkedLocationsData.filter(
                          (item, index) =>
                            item.locationId === location?.locationId,
                        );
                        if (ifExists.length === 0) {
                          areas = location?.areas;
                          if (areas?.length == 0) {
                            return;
                          }
                          userAreas = [];
                          if (areas) {
                            areas.forEach((area) => {
                              if (area?.checked?.toString() === 'true') {
                                userAreas.push({ areaId: area.areaId });
                              }
                            });
                            checkedLocationsData.push({
                              locationId: location.locationId,
                              areas: userAreas,
                            });
                          }
                        }
                      });
                    }
                    if (checkedLocationsData.length > 0) {
                      values.locations = checkedLocationsData;
                    }
                  }
                }
              }

              axiosClient
                .put(
                  `partner/orgs/${orgId}/accounts/${accountData.accountId}`,
                  values,
                  Utils.requestHeader(),
                )
                .then((res) => {
                  let response = res.data;
                  if (response.meta.code === 200) {
                    setSubmitting(false);
                    props.reloadData();
                    props.hideModal();
                  } else {
                    setVariant('error');
                    setUserMsg(response?.meta?.userMsg);
                    setSubmitting(false);
                  }
                })
                .catch(function (error) {
                  setVariant('error');
                  setUserMsg(error?.message);
                  setSubmitting(false);
                });
            } else setSubmitting(false);
          }}
        >
          {({
            values,
            setFieldValue,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
          }) => (
            <>
              <Form className="modal-form" onSubmit={handleSubmit}>
                {/* TODO: We can put the placehomder contents in the en.js file when we change the form structure */}
                {!fetchLocationsStatus ? (
                  <>
                    <TextField
                      required={true}
                      removebottommargin="true"
                      removetopmargin="true"
                      label=""
                      placeholder={constants.FIRST_NAME_FIELD}
                      name="firstName"
                      type="text"
                      disabled={!policyEditStatus || orgDetails?.ldapEnabled}
                    />
                    <TextField
                      required={true}
                      removebottommargin="true"
                      removetopmargin="true"
                      label=""
                      placeholder={constants.LAST_NAME_FIELD}
                      name="lastName"
                      type="text"
                      disabled={!policyEditStatus || orgDetails?.ldapEnabled}
                    />
                    <TextField
                      removebottommargin="true"
                      removetopmargin="true"
                      label=""
                      placeholder={constants.PHONE_NUMBER_FIELD}
                      name="phoneNumber"
                      type="text"
                      disabled={!policyEditStatus || orgDetails?.ldapEnabled}
                    />
                    <TextField
                      required={true}
                      removebottommargin="true"
                      removetopmargin="true"
                      label=""
                      placeholder={constants.EMAIL_ADDRESS_FIELD}
                      name="email"
                      type="text"
                      disabled={true}
                    />

                    <SelectField
                      disabled={getDisableStatus()}
                      isFromOrg={true}
                      name="role"
                      key="role"
                      options={props.roleOption ?? []}
                      onChange={(event) => {
                        setFieldValue('role', event.target.value);
                        if (event.target.value === roles.ROLE2VMS) {
                          setFieldValue('canShareAndDownload', true);
                        } else {
                          setFieldValue('canShareAndDownload', false);
                        }
                      }}
                      defaultOptionLabel={
                        constants.NEW_USER_SELECT_ROLE_DROPDOWN_DEFAULT_LABEL
                      }
                    />
                    {!disableAccountStatus ? (
                      <ToggleInput
                        classes="mt-2"
                        label={constants.ACCOUNT_STATUS_FIELD}
                        disabled={disableAccountStatus}
                        name="accountStatus"
                        value={accountStatus}
                        changeHandler={(evebr) =>
                          handleChangeAccountStatus(evebr)
                        }
                      />
                    ) : null}
                    <div
                      className={`settings-block-share_recipients ${
                        values.role === roles.ROLE2VMS ? 'toggledisable' : ''
                      }`}
                    >
                      <IoShareSocialOutline className="add-share-icon"></IoShareSocialOutline>
                      <Col>
                        <ToggleInput
                          label={constants.ADD_NEW_USERS_SHARE_VIDEO_RECIPIENTS}
                          name="canShareAndDownload"
                          changeHandler={() =>
                            setFieldValue(
                              'canShareAndDownload',
                              !values.canShareAndDownload,
                            )
                          }
                          disabled={
                            values.role === roles.ROLE2VMS
                              ? true
                              : getCustomerOrgPolicies()
                                    ?.update_video_clip_share_option
                                ? false
                                : true
                          }
                          value={values.canShareAndDownload}
                          className="toggle-field-wrap"
                        />
                      </Col>
                    </div>
                    {showAssignLocationStatus(values) && (
                      <AssignLocations
                        label={constants.ASSIGN_LOCATIONS_AREAS_FIELD}
                        clickHandler={(evebr) => {
                          setFetchLocationsStatus(!fetchLocationsStatus);
                        }}
                      />
                    )}
                  </>
                ) : (
                  <div className="locations-area-list-wrap">
                    {getCustomerOrgLocationsData()?.length >= 1 &&
                      getLocationAndAreasFields(getCustomerOrgLocationsData())}
                  </div>
                )}

                <Form.Group
                  as={Row}
                  className="mt-3"
                  style={{ display: fetchLocationsStatus ? 'block' : 'none' }}
                >
                  <Col>
                    <PrimaryButton
                      className="btn btn-primary w-100"
                      type="button"
                      height="44px"
                      fontSize="14px"
                      onClick={() => checkAllLocationAreas()}
                    >
                      {
                        constants.CUSTOMER_NEW_USER_ASSIGN_LOCATIONS_BUTTON_TITLE
                      }
                    </PrimaryButton>
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mt-4"
                  style={{ display: fetchLocationsStatus ? 'none' : 'flex' }}
                >
                  <Col md={4} className="text-start">
                    <PrimaryButton
                      className="btn btn-primary-outline"
                      type="button"
                      width="auto"
                      borderWidth="0"
                      hoverBorderWidth="0"
                      color={getComputedStyle(
                        document.documentElement,
                      ).getPropertyValue('--error_48')}
                      hoverColor={getComputedStyle(
                        document.documentElement,
                      ).getPropertyValue('--error_48')}
                      hoverBackgroundColor="transparent"
                      backgroundColor="transparent"
                      onClick={() => setShowRemoveUserModal(true)}
                      // disabled={ // require for futire references
                      //   roleChange
                      //     ? !getCustomerOrgPolicies()
                      //         ?.custorg_remove_customer_admin
                      //     : !getCustomerOrgPolicies()
                      //         ?.custorg_remove_customer_user
                      // }
                      disabled={
                        getCustomerOrgPolicies().delete_user_account
                          ? !getCustomerOrgPolicies().delete_user_account
                          : true
                      }
                    >
                      {constants.EDIT_CUSTOMER_REMOVE_USER_BUTTON_TITLE}
                    </PrimaryButton>
                  </Col>
                  <Col md={8} className="text-end">
                    {accountData?.accountStatus === 'ACTIVE' && (
                      <PrimaryButton
                        className="btn btn-primary-outline"
                        type="button"
                        width="auto"
                        borderWidth="1.5px"
                        hoverBorderWidth="1.5px"
                        hoverBorderColor={getComputedStyle(
                          document.documentElement,
                        ).getPropertyValue('--primary_40')}
                        color={getComputedStyle(
                          document.documentElement,
                        ).getPropertyValue('--primary_40')}
                        hoverColor={getComputedStyle(
                          document.documentElement,
                        ).getPropertyValue('--brand_white')}
                        hoverBackgroundColor={getComputedStyle(
                          document.documentElement,
                        ).getPropertyValue('--primary_40')}
                        backgroundColor="transparent"
                        onClick={() => handleResetPasswordUserClick()}
                        disabled={
                          disableResetButton
                            ? true
                            : roleChange
                              ? !getCustomerOrgPolicies()
                                  ?.custorg_reset_password_customer_admin
                              : !getCustomerOrgPolicies()
                                  ?.custorg_reset_password_customer_user
                        }
                      >
                        {constants.EDIT_CUSTOMER_RESET_PASSWORD_BUTTON_TITLE}
                      </PrimaryButton>
                    )}

                    <PrimaryButton
                      className="ms-2 btn btn-primary"
                      type="submit"
                      disabled={
                        !(
                          ((dirty && isValid) || locationsDataChangeFlag) &&
                          // getCustomerOrgPolicies()?.update_account
                          getCustomerOrgPolicies()?.acs_update_users
                        )
                      }
                      loader={isSubmitting}
                      height="44px"
                      fontSize="14px"
                      width="auto"
                    >
                      {constants.EDIT_USER_SUBMIT_BUTTON_TITLE}
                    </PrimaryButton>
                  </Col>
                </Form.Group>
              </Form>

              {/* Show error messages */}
              <SiteToast
                title={
                  variant === 'error'
                    ? constants.ERROR_TOAST_TITLE
                    : constants.SUCCESS_TOAST_TITLE
                }
                show={!!userMsg}
                body={userMsg}
                variant={variant}
                position="top-center"
              />

              {/* Deactivate user - confirmation modal */}
              <SiteModal
                modalTitle={
                  accountStatus
                    ? Utils.replaceStringValues(
                        constants.EDIT_CUSTOMER_ACTIVATE_POPUP_CONTENT,
                        '$firstName $lastName',
                        `${accountData?.firstName} ${accountData?.lastName}`,
                      )
                    : Utils.replaceStringValues(
                        constants.EDIT_CUSTOMER_DEACTIVATE_POPUP_CONTENT,
                        '$firstName $lastName',
                        `${accountData?.firstName} ${accountData?.lastName}`,
                      )
                }
                showModal={showDeactivateUserModal}
                hideModal={() => accountStatusHide()}
                classes="deactivate-customer-user-modal"
              >
                <TextBlock
                  className="mt-1 mb-4"
                  fontWeight="400"
                  fontSize="16px"
                  lineHeight="24px"
                  color={getComputedStyle(
                    document.documentElement,
                  ).getPropertyValue('--greyscale_56')}
                >
                  {accountStatus
                    ? constants.EDIT_CUSTOMER_ACTIVATE_USER_ACCESS_CONTENT
                    : constants.EDIT_CUSTOMER_DEACTIVATE_USER_ACCESS_CONTENT}
                </TextBlock>

                <Row>
                  <Col className="text-end">
                    {accountStatus ? (
                      <PrimaryButton
                        className="btn btn-primary-outline"
                        type="button"
                        width="auto"
                        borderWidth="1.5px"
                        hoverBorderWidth="1.5px"
                        onClick={() => handleActivateUserClick(setFieldValue)}
                      >
                        {
                          constants.EDIT_CUSTOMER_ACTIVATE_USER_CONFIRM_BUTTON_TITLE
                        }
                      </PrimaryButton>
                    ) : (
                      <PrimaryButton
                        className="btn btn-primary-outline"
                        type="button"
                        width="auto"
                        borderWidth="1.5px"
                        hoverBorderWidth="1.5px"
                        borderColor={getComputedStyle(
                          document.documentElement,
                        ).getPropertyValue('--error_64')}
                        hoverBorderColor={getComputedStyle(
                          document.documentElement,
                        ).getPropertyValue('--error_64')}
                        color={getComputedStyle(
                          document.documentElement,
                        ).getPropertyValue('--error_64')}
                        hoverColor={getComputedStyle(
                          document.documentElement,
                        ).getPropertyValue('--brand_white')}
                        hoverBackgroundColor={getComputedStyle(
                          document.documentElement,
                        ).getPropertyValue('--error_64')}
                        backgroundColor="transparent"
                        onClick={() => handleDeactivateUserClick(setFieldValue)}
                      >
                        {
                          constants.EDIT_CUSTOMER_DEACTIVATE_USER_CONFIRM_BUTTON_TITLE
                        }
                      </PrimaryButton>
                    )}

                    <PrimaryButton
                      className="ms-2 btn btn-primary"
                      type="button"
                      height="44px"
                      fontSize="14px"
                      width="auto"
                      onClick={() => {
                        setShowDeactivateUserModal(false);
                        setAccountStatus(true);
                      }}
                    >
                      {
                        constants.EDIT_CUSTOMER_REMOVE_USER_CONFIRM_CANCEL_BUTTON_TITLE
                      }
                    </PrimaryButton>
                  </Col>
                </Row>
              </SiteModal>
            </>
          )}
        </Formik>
      </div>

      {/* Send invitation - confirmation modal */}
      <SiteModal
        modalTitle={constants.EDIT_CUSTOMER_INVITATION_TITLE}
        showModal={invitationStatus}
        hideModal={() => setInvitationStatus(false)}
        classes="send-invitation-customer-user-modal"
      >
        <div className="text-center">
          <TextBlock
            fontWeight="400"
            fontSize="16px"
            lineHeight="24px"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--brand_primary',
            )}
          >
            {Utils.replaceStringValues(
              constants.EDIT_CUSTOMER_RESEND_INVITATION_POPUP_CONTENT,
              '$email',
              accountData?.email,
            )}
          </TextBlock>

          <HiCheckCircle className="mt-4 mb-4 icon-succuess-color" size={96} />

          <PrimaryButton
            className="btn btn-primary w-100 mb-2"
            type="submit"
            height="44px"
            fontSize="14px"
            width="auto"
            onClick={() => props.hideModal()}
          >
            {constants.EDIT_CUSTOMER_BACK_TO_USERS_LIST_BUTTON_TITLE}
          </PrimaryButton>
          <PrimaryButton
            className="btn btn-primary-outline w-100"
            type="button"
            width="auto"
            borderWidth="1.5px"
            hoverBorderWidth="1.5px"
            hoverBorderColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--primary_40')}
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--primary_40',
            )}
            hoverColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--brand_white')}
            hoverBackgroundColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--primary_40')}
            backgroundColor="transparent"
            onClick={() => {
              setInvitationStatus(false);
            }}
          >
            {Utils.replaceStringValues(
              constants.EDIT_CUSTOMER_RESET_PASSWORD_EDIT_PROFILE_BUTTON,
              '$firstName',
              accountData?.firstName,
            )}
          </PrimaryButton>
        </div>
        <SiteToast
          title={
            variant === 'error'
              ? constants.ERROR_TOAST_TITLE
              : constants.SUCCESS_TOAST_TITLE
          }
          show={!!userMsg}
          body={userMsg}
          variant={variant}
          position="top-center"
        />
      </SiteModal>

      {/* Remove user - confirmation modal */}
      <SiteModal
        modalTitle={Utils.replaceStringValues(
          constants.EDIT_CUSTOMER_REMOVE_USER_POPUP_CONTENT,
          '$firstName $lastName',
          `${accountData?.firstName} ${accountData?.lastName}`,
        )}
        showModal={showRemoveUserModal}
        hideModal={() => setShowRemoveUserModal(false)}
        classes="remove-customer-user-modal"
      >
        <TextBlock
          className="mt-1 mb-4"
          fontWeight="400"
          fontSize="16px"
          lineHeight="24px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--brand_primary',
          )}
        >
          {constants.EDIT_CUSTOMER_REMOVE_USER_MODAL_REMOVE_CONTENT}
        </TextBlock>

        <Row>
          <Col className="text-end">
            <PrimaryButton
              className="btn btn-primary-outline"
              type="button"
              width="auto"
              borderWidth="1.5px"
              hoverBorderWidth="1.5px"
              borderColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--error_64')}
              hoverBorderColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--error_64')}
              color={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--error_64')}
              hoverColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--brand_white')}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--error_64')}
              backgroundColor="transparent"
              onClick={() => handleRemoveUserClick()}
            >
              {constants.EDIT_CUSTOMER_REMOVE_USER_CONFIRM_BUTTON_TITLE}
            </PrimaryButton>
            <PrimaryButton
              className="ms-2 btn btn-primary"
              type="button"
              height="44px"
              fontSize="14px"
              width="auto"
              onClick={() => setShowRemoveUserModal(false)}
            >
              {constants.EDIT_CUSTOMER_REMOVE_USER_CONFIRM_CANCEL_BUTTON_TITLE}
            </PrimaryButton>
          </Col>
        </Row>
        <SiteToast
          title={
            variant === 'error'
              ? constants.ERROR_TOAST_TITLE
              : constants.SUCCESS_TOAST_TITLE
          }
          show={!!userMsg}
          body={userMsg}
          variant={variant}
          position="top-center"
        />
      </SiteModal>

      {/* Reset password - confirmation modal */}
      <SiteModal
        modalTitle="Reset Password Email Sent!"
        showModal={resetPwdStatus}
        hideModal={() => setResetPwdStatus(false)}
        classes="reset-password-customer-user-modal"
      >
        <div className="text-center">
          <TextBlock
            fontWeight="400"
            fontSize="16px"
            lineHeight="24px"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--brand_primary',
            )}
          >
            {Utils.replaceStringValues(
              constants.EDIT_CUSTOMER_RESET_PASSWORD_POPUP_CONTENT,
              '$email',
              accountData?.email,
            )}
          </TextBlock>

          <HiCheckCircle className="mt-4 mb-4 icon-succuess-color" size={96} />

          <PrimaryButton
            className="btn btn-primary w-100 mb-2"
            type="submit"
            height="44px"
            fontSize="14px"
            width="auto"
            onClick={() => props.hideModal()}
          >
            {constants.EDIT_CUSTOMER_BACK_TO_USERS_LIST_BUTTON_TITLE}
          </PrimaryButton>

          <PrimaryButton
            className="btn btn-primary-outline w-100"
            type="button"
            width="auto"
            borderWidth="1.5px"
            hoverBorderWidth="1.5px"
            hoverBorderColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--primary_40')}
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--primary_40',
            )}
            hoverColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--brand_white')}
            hoverBackgroundColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--primary_40')}
            backgroundColor="transparent"
            onClick={() => {
              setResetPwdStatus(false);
            }}
          >
            {Utils.replaceStringValues(
              constants.EDIT_CUSTOMER_RESET_PASSWORD_EDIT_PROFILE_BUTTON,
              '$firstName',
              accountData?.firstName,
            )}
          </PrimaryButton>
        </div>
        <SiteToast
          title={
            variant === 'error'
              ? constants.ERROR_TOAST_TITLE
              : constants.SUCCESS_TOAST_TITLE
          }
          show={!!userMsg}
          body={userMsg}
          variant={variant}
          position="top-center"
        />
      </SiteModal>
    </section>
  );
}

export default EditUser;
