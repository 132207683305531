import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Container } from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  RangeInputField,
  SelectField,
  TextField,
  ToggleInput,
  AssignLocations,
} from '../../components/forms';
import { Utils, constants, roles } from '../../helpers';
import Autocomplete from 'react-google-autocomplete';
import { BiWorld } from 'react-icons/bi';
import { AiOutlineLinkedin } from 'react-icons/ai';
import { usePoliciesStore } from '../../store/policiesStore';
import axios from 'axios';
import timezones from '../../data/support/timezone.json';
import { PiWarningCircleBold } from 'react-icons/pi';
import { useOrganizations } from '../../store/OrganizationsStore';
import { useCustomerOrgUsers } from '../../store/CustomerOrgUsersStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import LdapAuthentication from './LdapAuthentication';
import { useSearchParams } from 'react-router-dom';
import { setSelectedOrganization } from '../../store/reducers/AccountReducer';
import { useDispatch } from 'react-redux';
import { moveArrayPosition } from '../../helpers/commonUtils';
import {
  StorageDashboard,
  storageLabel,
} from '../../components/common/StorageCommon';

import {
  Header,
  PrimaryButton,
  SiteToast,
  TextBlock,
  SiteModal,
  SiteSpinner,
  BreadcrumbList,
} from '../../components/common/';
import LdapSettings from './LdapSettings';
import './LdapAuthentication.scss';
import RemoveOrganization from './RemoveOrganization';
import { IoClose } from 'react-icons/io5';

// Schema for yup
const validationSchema = Yup.object().shape({
  orgName: Yup.string()
    .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
    .required(constants.CUSTOMER_NAME_REQUIRED_ERROR_MESSAGE),
  addressLine1: Yup.string().required(constants.ADDRESS_REQUIRED_ERROR_MESSAGE),
  orgWebsite: Yup.string().matches(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//.=]*)/g,
    constants.WEBSITE_CORRECT_URL_MESSAGE,
  ),
  orgLinkedInProfile: Yup.string().matches(
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/i,
    constants.WEBSITE_CORRECT_URL_MESSAGE,
  ),
});

function EditOrganization() {
  const zoneTimeList = timezones?.data;
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const dispatch = useDispatch();

  const [variant, setVariant] = useState('error');
  const [userMsg, setUserMsg] = useState('');
  const [locHelperText, setLocHelperText] = useState('');
  const [visibleLocHelperText, setVisibleLocHelperText] = useState(false);
  const [disableRemoveOrg, setDisableRemoveOrg] = useState(false);
  const [ldapEnabled, setLdapEnabled] = useState(false);
  const [editUser, setEditUser] = useState({});
  const [orgContact, setOrgContact] = useState([]);
  const [authenticationSucess, setAuthenticationSucess] = useState(false);
  const [showLdapSettings, setShowLdapSettings] = useState(false);
  const [showRemoveOrgModal, setShowRemoveOrgModal] = useState(false);
  const [custPartnerOrgData, setCustPartnerOrgData] = useState({});
  const [isChangeLdap, setIsChangeLdap] = useState(false);
  const [storageData, setStorageData] = useState(null);
  //=== Store get/set actions
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies,
  );
  const [currentUserTimezone, setCurrentUserTimezone] = useState(null);
  const loggedInUserRole = useOrganizations((state) => state.loggedInUserRole);
  const getCustomerOrgUsersData = useCustomerOrgUsers(
    (state) => state.getCustomerOrgUsersData,
  );
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData,
  );
  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData,
  );
  const getOrganizationContact = useOrganizations(
    (state) => state.getOrganizationContact,
  );
  const loggedInUser = getLoggedInUserData();

  const breadList = [
    {
      url: '/customers/manage.html',
      title:
        loggedInUser.role !== roles.ROLE2VMS
          ? constants.MANAGE_ORG_PAGE_TITLE
          : constants.MANAGE_ORG_PAGE_ORG_TITLE,
    },
    {
      url: `/customers/dashboard.html?orgId=${orgId}`,
      title: editUser?.orgName,
    },
    {
      url: `/customers/editOrganization.html?orgId=${orgId}`,
      title: 'Edit Organization',
    },
  ];

  const disableInput = (event, values) => {
    if (values.addressLine1) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (Array.isArray(getCustomerOrgData())) {
      const userIndex = getCustomerOrgData().find(
        (user) => user.orgId === orgId,
      );
      dispatch(setSelectedOrganization(userIndex));
      setEditUser({
        ...userIndex,
      });
      setLdapEnabled(userIndex.ldapEnabled);
      if (userIndex.ldapEnabled) {
        setAuthenticationSucess(true);
      }
    }
    axios
      .get(`partner/orgs/${orgId}/partnerOrg`, Utils.requestHeader())
      .then((response) => {
        let resultData = response.data;
        if (resultData) {
          let responseMeta = resultData.meta;
          let responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            setCustPartnerOrgData(responseData);
          } else {
            setCustPartnerOrgData({});
          }
        }
      });
  }, [getCustomerOrgData()]);

  useEffect(() => {
    // get data for organization contact dropdown
    const roleParam = encodeURIComponent(roles.ROLE2VMS);
    getOrganizationContact(
      `/partner/orgs/${orgId}/accounts/v2?roleIds=${roleParam}`,
    )
      .then((res) => {
        let updatedContacts = res ?? [];
        if (editUser?.contactId) {
          const selectedObjIndex = res?.findIndex(
            (data) => data?.value === editUser?.contactId,
          );
          updatedContacts = moveArrayPosition(res, selectedObjIndex, 0);
        }
        setOrgContact(updatedContacts);
      })
      .catch((err) => {
        console.log(err);
      });
    const timezone = zoneTimeList?.find(
      (zone) => zone.value === editUser?.timezone,
    );
    setCurrentUserTimezone(timezone);
  }, [editUser]);

  useEffect(() => {
    const activeDevices = editUser?.devices?.filter(
      (device) =>
        device.deviceStatus !== constants.DEVICES_DEACTIVATED_DEVICE_STATUS,
    );
    const activeUsers = getCustomerOrgUsersData()?.filter(
      (user) =>
        (user.role === roles.ROLE2VMS || user.role === roles.ROLE3VMS) &&
        loggedInUser?.accountId !== user?.accountId,
    );
    if (activeDevices?.length > 0) {
      setDisableRemoveOrg(true);
    } else {
      if (activeUsers?.length <= 1) {
        if (
          activeUsers?.length === 1 &&
          activeUsers?.[0]?.role === roles.ROLE2VMS &&
          activeUsers?.[0]?.accountStatus === 'ACTIVE'
        ) {
          setDisableRemoveOrg(false);
        } else {
          setDisableRemoveOrg(false);
        }
      } else {
        setDisableRemoveOrg(true);
      }
    }
  }, [editUser, getCustomerOrgUsersData()]);

  useEffect(() => {
    if (editUser?.orgId) fetchStorageDetail();
  }, [editUser?.orgId]);

  const fetchStorageDetail = async () => {
    try {
      const res = await axios.get(
        `partner/orgs/${editUser?.orgId}/storageData`,
        Utils.requestHeader().headers,
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        setStorageData(responseData?.data);
      } else {
        if (res?.code) {
          console.error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          console.error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onLdapChange = (ldapValue) => {
    setIsChangeLdap(ldapValue);
    setLdapEnabled(ldapValue);
    if (!ldapValue) {
      setAuthenticationSucess(false);
    }
  };

  const onCloseLdap = (status) => {
    setIsChangeLdap(status);
    if (status) {
      setLdapEnabled(true);
      setAuthenticationSucess(true);
    } else {
      setLdapEnabled(false);
      setAuthenticationSucess(false);
    }
  };

  return (
    <div className="org-detail-page">
      <Container className="mw-100">
        <Row className="justify-content-md-left">
          <div className="page-header">
            <Container className="mw-100">
              <section className="modal-main-content mt-3">
                {editUser.orgId && currentUserTimezone ? (
                  <Formik
                    initialValues={{
                      //orgId: editUser?.orgId,
                      orgName: editUser?.orgName,
                      shortname: editUser?.shortName,
                      displayDetails: `${editUser.addressLine1}${editUser.city}, ${editUser.stateName}, ${editUser.country}, ${editUser?.zipcode}`,
                      addressLine1: editUser?.addressLine1,
                      addressLine2: editUser?.addressLine2,
                      city: editUser?.city,
                      state: editUser?.stateName,
                      country: editUser?.country,
                      zipcode: editUser?.zipcode,
                      orgWebsite: editUser?.orgWebsite,
                      orgLinkedInProfile: editUser?.orgLinkedInProfile,
                      //  createdDate: editUser?.createdDate,
                      orgStatus: editUser?.orgStatus,
                      lat:
                        editUser?.locations?.find(
                          (location) => location.isDefault === 'true',
                        )?.lat ?? '',
                      lng:
                        editUser?.locations?.find(
                          (location) => location.isDefault === 'true',
                        )?.lng ?? '',
                      timezone: currentUserTimezone?.value,
                      mfa: editUser?.mfa,
                      contactId: editUser?.contactId ?? '',
                      retainNotification:
                        loggedInUserRole === roles.ROLE2VMS
                          ? editUser?.retainNotification
                          : null,
                    }}
                    enableReinitialze={true}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      setSubmitting(true);
                      setUserMsg('');
                      if (values) {
                        axios
                          .put(
                            `partner/orgs/${editUser?.orgId}`,
                            values,
                            Utils.requestHeader(),
                          )
                          .then((res) => {
                            let response = res.data;

                            if (response?.meta?.code === 200) {
                              // TODO : delete later
                              // dispatch(setuserData(response.data));
                              setEditUser(response.data);
                              setSubmitting(false);
                            } else {
                              setVariant('error');
                              setUserMsg(response?.meta?.userMsg);
                              setSubmitting(false);
                            }
                          })
                          .catch(function (error) {
                            setVariant('error');
                            setUserMsg(error?.message);
                            setSubmitting(false);
                          });
                      } else setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      isValid,
                      dirty,
                    }) => (
                      <>
                        <Form className="modal-form" onSubmit={handleSubmit}>
                          <div className="head-container">
                            <div className="header-title">
                              {constants.ORGANIZATION_EDIT}
                            </div>
                            <div className="d-flex gap-3">
                              {getCustomerOrgPolicies()?.remove_cust_org ? (
                                <>
                                  <Form.Group as={Row}>
                                    <Col>
                                      <PrimaryButton
                                        className="btn btn-outline-danger"
                                        type="button"
                                        height="44px"
                                        width="212px"
                                        fontSize="0.875rem"
                                        backgroundColor={getComputedStyle(
                                          document.documentElement,
                                        ).getPropertyValue('--brand_white')}
                                        borderColor={getComputedStyle(
                                          document.documentElement,
                                        ).getPropertyValue('--error_64')}
                                        color={getComputedStyle(
                                          document.documentElement,
                                        ).getPropertyValue('--error_64')}
                                        borderWidth="1.5px"
                                        hoverBorderWidth="1.5px"
                                        hoverColor={getComputedStyle(
                                          document.documentElement,
                                        ).getPropertyValue('--error_64')}
                                        hoverBackgroundColor={getComputedStyle(
                                          document.documentElement,
                                        ).getPropertyValue('--brand_white')}
                                        disabled={disableRemoveOrg}
                                        hoverBorderColor={getComputedStyle(
                                          document.documentElement,
                                        ).getPropertyValue('--error_64')}
                                        onClick={() => {
                                          setShowRemoveOrgModal(true);
                                        }}
                                      >
                                        {constants.REMOVE_ORGANIZATION}
                                      </PrimaryButton>
                                    </Col>
                                  </Form.Group>
                                </>
                              ) : null}
                              <Form.Group>
                                <PrimaryButton
                                  className="btn btn-primary"
                                  type="submit"
                                  disabled={
                                    (!(dirty && isValid) &&
                                      getCustomerOrgPolicies()
                                        ?.update_cust_org) ||
                                    currentUserTimezone == undefined ||
                                    !values.city ||
                                    !values.state ||
                                    !values.zipcode ||
                                    !values.country
                                  }
                                  loader={isSubmitting}
                                  height="44px"
                                  width="96px"
                                  fontSize="0.875rem"
                                >
                                  {constants.EDIT_CUSTOMER_ORG}
                                </PrimaryButton>
                              </Form.Group>
                            </div>
                          </div>
                          <TextBlock
                            fontWeight="400"
                            fontSize="1rem"
                            lineHeight="1.5rem"
                            color={getComputedStyle(
                              document.documentElement,
                            ).getPropertyValue('--greyscale_56')}
                          >
                            {`Organization ID: ${editUser?.orgId}`}
                          </TextBlock>
                          {storageData !== null &&
                            storageLabel(
                              storageData?.usedStorage,
                              storageData?.allocatedStorage,
                              constants.LIGHT_THEME_40,
                            )}

                          {storageData !== null &&
                            StorageDashboard(
                              storageData?.usedStorage,
                              storageData?.allocatedStorage,
                            )}

                          <Col xs lg="6" className="m-t-20">
                            <TextField
                              required={true}
                              removebottommargin="true"
                              removetopmargin="true"
                              label=""
                              placeholder={constants.CUSTOMER_NAME_FIELD}
                              name="orgName"
                              type="text"
                            />
                            <TextField
                              label=""
                              name="shortName"
                              value={editUser?.shortName}
                              type="text"
                              disabled={true}
                            />

                            <div className="row">
                              <div className="col position-relative address-with-reset">
                                <div>
                                  <Autocomplete
                                    name="displayDetails"
                                    placeholder=""
                                    required={true}
                                    apiKey={
                                      process.env.REACT_APP_GOOGLE_PLACE_URL
                                    }
                                    onChange={(evt) => {
                                      setFieldValue(
                                        'displayDetails',
                                        evt?.target?.value,
                                      );
                                    }}
                                    onBlur={handleBlur}
                                    onKeyDown={(e) => disableInput(e, values)}
                                    id="displayDetails"
                                    // onFocus={
                                    //   (e) => e.target.setAttribute("autoComplete", "none")
                                    // }
                                    options={{
                                      fields: [
                                        'formatted_address',
                                        'geometry',
                                        'name',
                                        'address_components',
                                      ],
                                      strictBounds: false,
                                      types: ['address'],
                                    }}
                                    onPlaceSelected={(place) => {
                                      if (place?.address_components?.length) {
                                        setFieldValue(
                                          'lat',
                                          place?.geometry?.location?.lat(),
                                        );
                                        setFieldValue(
                                          'lng',
                                          place?.geometry?.location?.lng(),
                                        );

                                        let addressComponent,
                                          addressComponentType,
                                          rawzipcode,
                                          rawcountry,
                                          rawcity,
                                          rawstate,
                                          rawaddline;
                                        for (
                                          let i = 0;
                                          i < place?.address_components?.length;
                                          i++
                                        ) {
                                          addressComponent =
                                            place.address_components[i];
                                          let j = 0;
                                          addressComponentType =
                                            addressComponent.types[j];
                                          if (
                                            addressComponentType ===
                                            'postal_code'
                                          ) {
                                            rawzipcode =
                                              addressComponent.long_name
                                                ? addressComponent.long_name
                                                : '';
                                          }
                                          if (
                                            addressComponentType === 'country'
                                          ) {
                                            rawcountry =
                                              addressComponent.long_name
                                                ? addressComponent.short_name
                                                : '';
                                          }
                                          if (
                                            addressComponentType ===
                                            'administrative_area_level_1'
                                          ) {
                                            rawstate =
                                              addressComponent.long_name
                                                ? addressComponent.short_name
                                                : '';
                                          }
                                          if (
                                            addressComponentType === 'locality'
                                          ) {
                                            rawcity = addressComponent.long_name
                                              ? addressComponent.long_name
                                              : '';
                                          }

                                          if (
                                            addressComponentType ===
                                              'street_number' ||
                                            addressComponentType === 'route' ||
                                            addressComponentType ===
                                              'neighborhood' ||
                                            addressComponentType.includes(
                                              'sublocality',
                                            )
                                          ) {
                                            rawaddline = `${rawaddline ? rawaddline : ''}${addressComponent.long_name}, `;
                                          }
                                        }
                                        setFieldValue('zipcode', rawzipcode);
                                        setFieldValue('country', rawcountry);
                                        setFieldValue('city', rawcity);
                                        setFieldValue('state', rawstate);
                                        setFieldValue(
                                          'addressLine1',
                                          rawaddline,
                                        );
                                        setFieldValue(
                                          'displayDetails',
                                          (rawaddline ? rawaddline : '') +
                                            (rawcity ? rawcity + ', ' : '') +
                                            (rawstate ? rawstate + ', ' : '') +
                                            (rawcountry
                                              ? rawcountry + ', '
                                              : '') +
                                            (rawzipcode ? rawzipcode : ''),
                                        );

                                        if (
                                          place?.geometry?.location?.lat() &&
                                          place?.geometry?.location?.lng()
                                        ) {
                                          var config = {
                                            method: 'get',
                                            url: `https://maps.googleapis.com/maps/api/timezone/json?location=${place?.geometry?.location?.lat()},${place?.geometry?.location?.lng()}&timestamp=${
                                              new Date().getTime() / 1000
                                            }&language=es&key=${
                                              process.env
                                                .REACT_APP_GOOGLE_TIMEZONE_API_KEY
                                            }`,
                                            headers: {},
                                          };

                                          fetch(config?.url)
                                            .then(function (response) {
                                              return response.json();
                                            })
                                            .then(function (json) {
                                              if (json?.status === 'OK') {
                                                const data = zoneTimeList?.find(
                                                  (zone) =>
                                                    zone?.location ===
                                                    json?.timeZoneId,
                                                );
                                                if (data) {
                                                  setCurrentUserTimezone(data);
                                                  setFieldValue(
                                                    'timezone',
                                                    data?.value,
                                                  );
                                                  setLocHelperText(
                                                    constants.LOCATION_TIMEZONE_ORGANIZATION_MESSAGE,
                                                  );
                                                } else {
                                                  setLocHelperText(
                                                    constants.AUTO_TIMEZONE_FAIL_MESSAGE,
                                                  );
                                                  setFieldValue('timezone', '');
                                                }
                                                setVisibleLocHelperText(true);
                                              } else {
                                                setVisibleLocHelperText(true);
                                                setLocHelperText(
                                                  constants.AUTO_TIMEZONE_FAIL_MESSAGE,
                                                );
                                              }
                                            });
                                        }
                                      }
                                    }}
                                    value={values.displayDetails}
                                    className={`form-control shadow-none autoaddress ${
                                      touched.displayDetails &&
                                      (errors.addressLine1 ||
                                        !values.displayDetails) &&
                                      'is-invalid'
                                    } ${values.addressLine1 && 'has-value'}`}
                                  />
                                  {!document.getElementById('displayDetails')
                                    ?.value ? (
                                    <span className="placeholder-wrapper required">
                                      {constants.ADDRESS_FIELD}
                                    </span>
                                  ) : null}
                                  {values.addressLine1 ? (
                                    <span className={`reset-address-wrapper`}>
                                      <IoClose
                                        size={15}
                                        onClick={() => {
                                          setFieldValue('zipcode', '');
                                          setFieldValue('country', '');
                                          setFieldValue('city', '');
                                          setFieldValue('state', '');
                                          setFieldValue('addressLine1', '');
                                          setFieldValue('displayDetails', '');
                                          document.getElementById(
                                            'displayDetails',
                                          ).value = '';
                                          setFieldTouched(
                                            'displayDetails',
                                            true,
                                            false,
                                          );
                                        }}
                                      />
                                    </span>
                                  ) : null}
                                </div>
                                <ErrorMessage
                                  component="div"
                                  name="addressLine1"
                                  className="error"
                                />
                                {touched.displayDetails &&
                                  (!values.addressLine1 ||
                                    !values.city ||
                                    !values.state ||
                                    !values.country ||
                                    !values.zipcode) && (
                                    <div className="error">
                                      {constants.ADDRESS_FIELD_ERROR_MESSAGE}
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col position-relative">
                                <div>
                                  <SelectField
                                    isFromOrg={true}
                                    name="timezone"
                                    options={zoneTimeList}
                                    label=""
                                    defaultOptionLabel="Time Zone"
                                    value={values.timezone}
                                    onChange={(evebr) => {
                                      let selectedVal = evebr?.target?.value;
                                      const getLocationSelected =
                                        zoneTimeList?.find(
                                          (zone) => zone.value === selectedVal,
                                        );
                                      setCurrentUserTimezone(
                                        getLocationSelected,
                                      );
                                      setFieldValue(
                                        'timezone',
                                        getLocationSelected?.value,
                                      );
                                      setVisibleLocHelperText(false);
                                    }}
                                  />
                                </div>

                                <ErrorMessage
                                  component="div"
                                  name="timezone"
                                  className="info-timezone"
                                />
                                {visibleLocHelperText ? (
                                  <div className="info-timezone">
                                    <PiWarningCircleBold
                                      color={getComputedStyle(
                                        document.documentElement,
                                      ).getPropertyValue('--greyscale_56')}
                                      size={16}
                                    />
                                    <div className="message-info">
                                      {locHelperText}
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <TextField
                              beforeinputicon={<BiWorld size={12} />}
                              removebottommargin="true"
                              removetopmargin="true"
                              label=""
                              placeholder={constants.WEBSITE_NAME_FIELD}
                              name="orgWebsite"
                              type="text"
                            />

                            <TextField
                              beforeinputicon={<AiOutlineLinkedin size={12} />}
                              removebottommargin="true"
                              removetopmargin="true"
                              label=""
                              placeholder={
                                constants.LINKEDIN_PROFILE_NAME_FIELD
                              }
                              name="orgLinkedInProfile"
                              type="text"
                            />

                            {getCustomerOrgPolicies()
                              ?.update_cust_org_settings ? (
                              <ToggleInput
                                name="mfa"
                                label={
                                  constants.ORGANIZATION_TWO_FACTOR_AUTHENTICATION
                                }
                                value={values.mfa}
                                changeHandler={() =>
                                  setFieldValue('mfa', !values.mfa)
                                }
                              />
                            ) : null}

                            {getCustomerOrgPolicies()?.manage_ldap ? (
                              <div className="grey-background">
                                <ToggleInput
                                  name="ldapenabled"
                                  label={
                                    constants.ORGANIZATION_LDAP_AUTHENTICATION
                                  }
                                  value={ldapEnabled}
                                  changeHandler={() =>
                                    onLdapChange(!values.ldap)
                                  }
                                  classes={`mt-3 ${authenticationSucess ? 'ldapEnabledClass' : ''}`}
                                  disabled={authenticationSucess}
                                />

                                {authenticationSucess ? (
                                  <AssignLocations
                                    label={'Settings'}
                                    hideIcon={true}
                                    classes={'mt-0'}
                                    clickHandler={(evebr) => {
                                      setShowLdapSettings(true);
                                    }}
                                  />
                                ) : null}
                              </div>
                            ) : null}

                            <SelectField
                              name="contactId"
                              key="contactId"
                              options={orgContact}
                              defaultOptionLabel={
                                constants.ORGANIZATION_CONTACT_DROPDOWN_LABEL
                              }
                            />

                            {loggedInUserRole === roles.ROLE2VMS ? (
                              <RangeInputField
                                label={constants.NOTIFICATION_RETENTION_PERIOD}
                                maxValue={14}
                                minValue={1}
                                fieldName="retainNotification"
                                currentValue={values.retainNotification}
                                changeHandler={(value) => {
                                  setFieldValue('retainNotification', value);
                                }}
                              />
                            ) : null}
                            {disableRemoveOrg ? (
                              <div className="d-flex mt-3 gap-2">
                                <div>
                                  <PiWarningCircleBold
                                    color={getComputedStyle(
                                      document.documentElement,
                                    ).getPropertyValue('--greyscale_56')}
                                    size={20}
                                  />
                                </div>
                                <TextBlock
                                  fontWeight="400"
                                  fontSize="0.875rem"
                                  lineHeight="20px"
                                  color={getComputedStyle(
                                    document.documentElement,
                                  ).getPropertyValue('--greyscale_56')}
                                >
                                  {constants.REMOVE_ORG_HELPER_TEXT}
                                </TextBlock>
                              </div>
                            ) : null}
                          </Col>
                          {/* Error message */}
                          <SiteToast
                            title={
                              variant === 'error'
                                ? constants.ERROR_TOAST_TITLE
                                : constants.SUCCESS_TOAST_TITLE
                            }
                            show={!!userMsg}
                            body={userMsg}
                            variant={variant}
                            position="top-center"
                          />
                        </Form>
                      </>
                    )}
                  </Formik>
                ) : (
                  <div className="w-100 text-center">
                    <SiteSpinner height="100px" width="100px"></SiteSpinner>
                  </div>
                )}
                <LdapAuthentication
                  isChangeLdap={isChangeLdap}
                  onCloseLdap={onCloseLdap}
                  orgId={orgId}
                />
                <SiteModal
                  modalHeader={true}
                  showModal={showLdapSettings}
                  hideModal={() => setShowLdapSettings(false)}
                >
                  <LdapSettings
                    handleClose={() => setShowLdapSettings(false)}
                    orgId={orgId}
                  />
                </SiteModal>
                <SiteModal
                  modalTitle={`Remove ${editUser.orgName}`}
                  showModal={showRemoveOrgModal}
                  hideModal={() => setShowRemoveOrgModal(false)}
                  classes="remove-org-modal"
                >
                  <RemoveOrganization
                    editUser={editUser}
                    timezone={custPartnerOrgData?.timezone}
                    hideRemoveOrgModal={() => setShowRemoveOrgModal(false)}
                  />
                </SiteModal>
              </section>
            </Container>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default EditOrganization;
